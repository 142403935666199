@media only screen and (min-width: 1600px) {
  .view-diaporama-accueil::before {
    left: -100%;
  }
  .ecran {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  #content.col-lg-7 {
    max-width: 616px;
  }
  .carousel-control {
    .icon-prev {
      margin-left: -66px;
    }
    .icon-next {
      margin-right: 15px;
    }
  }
}
