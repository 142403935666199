@media only screen and (min-width: 1900px) {
  .view-diaporama-accueil::before {
    left: -89%;
  }
  .ecran {
    margin-left: 36px;
  }
  .front #views-acces_directs-acces_direct {
    margin-right: 15%;
  }
  .carousel-control {
    .icon-prev {
      margin-left: -30px;
    }
    .icon-next {
      margin-right: -22px;
    }
  }
}
