@media only screen and (min-width: 992px) {
    .fixed-header.logged-in.admin-menu {
        #top-box {
            margin-top: 0;
            top: 29px;
        }
        .page-box {
            padding-top: 22px;
        }
        #diaporama {
            //            margin-top: 0px;
        }
    }
    .fixed-header .page-box {
        margin-top: 0 !important;
        padding-top: 0 !important;
        -webkit-transition: none !important;
        transition: none !important;
    }
    .fixed-header .header-wrapper {
        transition: none;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
        padding: 0;
    }
    .header {
        .logo {
            img {
                margin-left: 20px;
                max-height: 115px;
                max-width: 300px;
            }
        }
        .slogan {
            &:before {
                width: 208px;
                right: 208px;
                top: -27px;
                background-position: 20px -80px;
            }
            &:after {
                background: linear-gradient(to right, #ffffff 40%, transparent 60%);
            }
            .bulle {
                width: 260px;
                left: -210px;
                bottom: 40px;
            }
            h1 {
                font-size: 1.5rem;
            }
        }
        .primary {
            clear: both;
            margin-bottom: 0;
            .navbar {
                .nav>li>a,
                .nav>li>a:visited {
                    color: white;
                    padding: 8px 12px;
                    font-size: 1.4rem;
                }
            }
        }
    }
    .header-icons {
        margin-right: 20px;
    }
    .page-header {
        margin: 30px 0 10px 0;
    }
    #diaporama {
        height: 100%;
        background: linear-gradient(0deg, white 30%, $bleu-fonce 30%);
        padding-top: 40px;
        margin-bottom: 20px;
        display: block;
    }
    .ecran {
        background: url("../images/ecran.png") no-repeat;
        width: 501px;
        height: 405px;
        position: absolute;
    }
    .carousel-control {
        .icon-next,
        .icon-prev {
            margin-top: 125px;
        }
        .icon-prev {
            margin-left: -65px;
        }
        .icon-next {
            margin-right: 20px;
        }
    }
    .carousel-inner {
        margin: 28px 0 0 30px;
        width: 441px;
        height: 277px;
    }
    .acces-directs {
        margin: 60px auto 0;
        padding: 0;
        box-shadow: 0 2px 10px rgba(0, 0, 1, .5);
        border-radius: 0 0 15px 15px;
        border-bottom: 4px solid #939591;
        background: #e0e2df;
        background: linear-gradient(to bottom, #949591 0, #939591 263px, #e0e2df 263px, #c4cac6 100%);
        width: 158px;
        height: 330px;
        &:before {
            background: url("../images/ondes.png") 30px no-repeat;
            margin-top: 30px;
            position: absolute;
            content: "";
            margin-top: -60px;
            width: 158px;
            height: 49px;
        }
        li,
        li.active {
            font-size: 1.4rem;
            height: 61px;
            width: 74px;
            margin: 3px -1px 1px 2px;
            padding-top: 7px;
            &:hover {}
            &:focus {}
            &:after {}
            div {}
            a {
                &:before {
                    font-size: 26px;
                    line-height: 33px;
                }
            }
        }
        li:nth-child(1),
        li:nth-child(1).active,
        li:nth-child(2),
        li:nth-child(2).active {
            margin-top: 3px;
        }
        .outils {
            top: 180px;
        }
        li:nth-child(7) {
            max-width: 153px;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 1199px) {
    /* CSS Statements that only apply on webkit-based browsers (Chrome, Safari, etc.) */
    #diaporama {
        margin-top: 0;
    }
}
