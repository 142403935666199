@import '_colors';

/******************************************************************************
General
*******************************************************************************/

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

htm {
    font-size: 62.5%;
    /* Ré-équilibrage du Rem face au Pixel pour des calculs simples */
}

.full-height,
body,
html {
    height: 100%;
}

body {
    font-family: "Arimo", sans-serif;
    height: 100%;
    overflow-x: hidden;
    &.loaded {
        overflow-x: inherit;
    }
}

.scroll-width {
    height: 150px;
    left: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    top: 0;
    width: 200px;
    div {
        height: 200px;
        width: 100%;
    }
}

button,
input,
select,
textarea {
    font-family: "Arimo", sans-serif;
}

.page-box {
    min-height: 100%;
    height: auto !important;
    height: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

svg image {
    display: none;
}


/* Text */

a {
    color: #2e5481;
    &:visited {
        color: #7996b7;
    }
    &:active {
        color: #000;
    }
    &.no-border {
        color: #7f7f7f;
        text-decoration: none;
        transition: color .2s linear;
    }
}

.no-border a {
    color: #7f7f7f;
    text-decoration: none;
    transition: color .2s linear;
}

.no-border a:hover,
a.no-border:hover {
    color: #1e1e1e;
    text-decoration: none;
    transition: color .2s linear;
}

.white {
    .no-border a,
    a.no-border {
        color: white;
        color: rgba(255, 255, 255, 0.8);
    }
    .no-border a:hover,
    a.no-border:hover {
        color: white;
    }
}

.light {
    font-weight: normal;
}

p:last-child {
    margin-bottom: 0;
}

address {
    font-size: 13px;
    margin-top: 15px;
}

.icon.white,
.white {
    color: white;
}

.icon.muted,
.muted {
    color: #555;
}

.text-warning {
    color: #f89406;
    &:visited {
        color: #f89406;
    }
}

.icon.text-warning,
a.text-warning:hover {
    color: #f89406;
}

.text-error {
    color: #c10841;
    &:visited {
        color: #c10841;
    }
}

.icon.text-error,
a.text-error:hover {
    color: #c10841;
}

.text-info {
    color: #0098ca;
    &:visited {
        color: #0098ca;
    }
}

.icon.text-info,
a.text-info:hover {
    color: #0098ca;
}

.text-success {
    color: #738d00;
    &:visited {
        color: #738d00;
    }
}

.icon.text-success,
a.text-success:hover {
    color: #738d00;
}

.bg-white {
    background: white !important;
}

.bg-muted {
    background: #555 !important;
}

.bg-primary {
    background: $vert !important;
}

.bg-warning {
    background: #f89406 !important;
}

.bg-danger,
.bg-error {
    background: #c10841 !important;
}

.bg-info {
    background: #0098ca !important;
}

.bg-success {
    background: #738d00 !important;
}

.border-muted {
    border-color: #555 !important;
}

.border-warning {
    border-color: #f89406 !important;
}

.border-error {
    border-color: #c10841 !important;
}

.border-info {
    border-color: #0098ca !important;
}

.border-success {
    border-color: #738d00 !important;
}

.border-grey {
    border-color: #777 !important;
}

hr {
    border: 0;
    border-top: 1px solid #e1e1e1;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0 none;
    margin: 40px 0;
    &.dotted {
        border-style: dotted;
        border-top-width: 1px;
    }
    &.dashed {
        border-style: dashed;
        border-top-width: 1px;
    }
    &.double {
        border-style: double;
        border-top-width: 3px;
    }
    &.shadow {
        border: 0 none;
        margin-bottom: 30px;
        overflow: hidden;
        padding-top: 10px;
        position: relative;
        &:after {
            bottom: 10px;
            border-radius: 100%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
            content: "";
            height: 20px;
            left: 5%;
            position: absolute;
            right: 5%;
            z-index: -1;
        }
    }
    &.no-line {
        border: 0 none;
        height: 1px;
    }
    &.margin-bottom {
        margin-top: 0;
    }
    &.margin-top {
        margin-bottom: 0;
    }
}

ol,
ul {
    padding: 0 0 0 60px;
    margin: 0 0 22px;
}

.text-small {
    font-size: 12px;
    line-height: 18px;
}

.lead {
    margin-bottom: 26px;
    font-size: 16px;
    line-height: 24px;
}

.badge-success,
.label-success {
    background-color: #8aa902;
}

.badge-primary,
.label-primary {
    background-color: #048ce2;
}

.badge-info,
.label-info {
    background-color: #02a9e0;
}

.badge-warning,
.label-warning {
    background-color: #f89406;
}

.badge-danger,
.label-danger {
    background-color: #c10841;
}

.help-block {
    margin-top: -10px;
    margin-bottom: 15px;
}

.caret {
    color: #777;
    border: 0 none;
    height: 6px;
    width: 9px;
    &:before {
        content: "\f107";
        display: block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 6px;
        height: 6px;
        position: absolute;
        width: 9px;
    }
}

.open .caret {
    color: #777;
}

.btn .caret {
    margin: -2px 0 0;
}

.btn-group .caret {
    color: white;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border: 0 none;
}

.dropup .caret:before,
.navbar-fixed-bottom .dropdown .caret:before {
    content: "\f106";
}

.btn.dropdown-toggle {
    outline: none;
    &:focus,
    &:hover {
        outline: none;
    }
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.rating-box {
    background-image: url("../img/svg/rating.svg"), none;
    background-repeat: no-repeat;
    background-position: 0 0;
    height: 12px;
    width: 73px;
    .rating {
        height: 12px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 73px;
        * {
            vertical-align: top;
        }
    }
}

.prising-info .rating-box .rating svg polygon {
    fill: #01a5db;
}

.pricing-success .rating-box .rating svg polygon {
    fill: #84a200;
}

.pricing-error .rating-box .rating svg polygon {
    fill: #d40746;
}

.switches a {
    background: #d8dee3;
    border-radius: 5px;
    display: inline-block;
    display: block;
    float: left;
    height: 10px;
    margin: 0 5px;
    text-indent: -9999px;
    vertical-align: top;
    width: 10px;
    &.selected {
        background-color: #41474c;
        background-image: -o-linear-gradient(bottom, #41474c, #6a7279);
        background-image: -ms-linear-gradient(bottom, #41474c, #6a7279);
        background-image: linear-gradient(to top, #41474c, #6a7279);
    }
    span {
        display: none;
    }
}

.close {
    float: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    color: #7f7f7f;
    position: relative;
    text-shadow: none;
    opacity: 1;
    filter: alpha(opacity=100);
    vertical-align: top;
    transition: color .2s linear;
    &:visited {
        float: none;
        font-size: 16px;
        font-weight: normal;
        line-height: 16px;
        color: #7f7f7f;
        position: relative;
        text-shadow: none;
        opacity: 1;
        filter: alpha(opacity=100);
        vertical-align: top;
        transition: color .2s linear;
    }
    &:focus,
    &:hover {
        color: #1e1e1e;
        opacity: 1;
        filter: alpha(opacity=100);
        transition: color .2s linear;
    }
    span {
        display: inline-block;
        font-size: 12px;
        font-family: Arial;
        line-height: 12px;
        margin-top: 2px;
        vertical-align: top;
    }
}

.product-remove {
    color: #7f7f7f;
    height: 16px;
    text-decoration: none;
    width: 16px;
    transition: color .2s linear;
    &:visited {
        color: #7f7f7f;
        height: 16px;
        text-decoration: none;
        width: 16px;
        transition: color .2s linear;
    }
    &:hover {
        color: #1e1e1e;
        transition: color .2s linear;
    }
    path {
        fill: currentColor;
        transition: all .2s linear;
    }
    i {
        font-size: 16px;
    }
}

a.edit {
    color: #707070;
    font-size: 12px;
    text-decoration: none;
    transition: opacity .2s linear;
    &:hover {
        color: #707070;
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
    }
    i:before {
        font-style: italic;
        line-height: 9px;
        margin: 0;
    }
}

.map-box {
    iframe {
        border: 0 none;
        border-radius: 5px;
        width: 100%;
    }
    img {
        max-width: inherit;
    }
}

.map-canvas {
    height: 400px;
    * {
        border: 0 none;
    }
}

.map-content {
    width: 200px;
    .title {
        font-size: 16px;
        margin-bottom: 10px;
    }
}


/* Alert */

.alert {
    background-color: #555;
    border: 0 none;
    border-radius: 0;
    color: white;
    min-height: 34px;
    margin-bottom: 22px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-shadow: none;
    transition: opacity .3s linear;
}

.alert-black {
    background-color: #1e1e1e;
}

.alert-success {
    background-color: #738d00;
}

.alert-danger {
    background-color: #c10841;
}

.alert-info {
    background-color: #0098ca;
}

.alert-warning {
    background-color: #f89406;
}

.alert {
    .alert-link {
        color: white;
        color: rgba(255, 255, 255, 0.7);
        &:hover {
            color: white;
        }
    }
    .close {
        color: white;
        line-height: 22px;
        position: absolute;
        right: 10px;
        top: 9px;
    }
    .alert-icon {
        display: block;
        float: left;
        margin: 0 20px 0 0;
        &:before {
            font-size: 34px;
        }
    }
    &.alert-border {
        background: none;
        border: 1px solid #e1e1e1;
        color: #1e1e1e;
        .close {
            color: #1e1e1e;
        }
        &.alert-black {
            background: none;
            border: 1px solid #1e1e1e;
        }
        &.alert-success {
            background: none;
            border: 1px solid #738d00;
        }
        &.alert-danger {
            background: none;
            border: 1px solid #c10841;
        }
        &.alert-warning {
            background: none;
            border: 1px solid #f89406;
        }
        &.alert-info {
            background: none;
            border: 1px solid #0098ca;
        }
    }
}


/* Tooltip */

.tooltip.left {
    margin-left: -12px;
}

#tooltip {
    border: 1px solid #e1e1e1;
    background-color: #f7f7f7;
    padding: 5px;
    position: absolute;
    z-index: 3000;
    div,
    h3 {
        margin: 0;
    }
}

.text-warning+.tooltip {
    .tooltip-inner {
        background-color: #c09853;
    }
    &.top .tooltip-arrow {
        border-top-color: #c09853;
    }
    &.right .tooltip-arrow {
        border-right-color: #c09853;
    }
    &.left .tooltip-arrow {
        border-left-color: #c09853;
    }
    &.bottom .tooltip-arrow {
        border-bottom-color: #c09853;
    }
}

.text-error+.tooltip {
    .tooltip-inner {
        background-color: #c10841;
    }
    &.top .tooltip-arrow {
        border-top-color: #c10841;
    }
    &.right .tooltip-arrow {
        border-right-color: #c10841;
    }
    &.left .tooltip-arrow {
        border-left-color: #c10841;
    }
    &.bottom .tooltip-arrow {
        border-bottom-color: #c10841;
    }
}

.text-info+.tooltip {
    .tooltip-inner {
        background-color: #0098ca;
    }
    &.top .tooltip-arrow {
        border-top-color: #0098ca;
    }
    &.right .tooltip-arrow {
        border-right-color: #0098ca;
    }
    &.left .tooltip-arrow {
        border-left-color: #0098ca;
    }
    &.bottom .tooltip-arrow {
        border-bottom-color: #0098ca;
    }
}

.text-success+.tooltip {
    .tooltip-inner {
        background-color: #738d00;
    }
    &.top .tooltip-arrow {
        border-top-color: #738d00;
    }
    &.right .tooltip-arrow {
        border-right-color: #738d00;
    }
    &.left .tooltip-arrow {
        border-left-color: #738d00;
    }
    &.bottom .tooltip-arrow {
        border-bottom-color: #738d00;
    }
}


/* Popover */

.popover {
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}


/* List Group */

a.list-group-item {
    transition: background-color .3s linear, color .3s linear;
    &:focus,
    &:hover {
        transition: background-color .3s linear, color .3s linear;
    }
}

.list-group-item-success {
    background-color: #738d00;
    border: 0 none;
    color: white;
}

.list-group-item-info {
    background-color: #0098ca;
    border: 0 none;
    color: white;
}

.list-group-item-warning {
    background-color: #f89406;
    border: 0 none;
    color: white;
}

.list-group-item-danger {
    background-color: #c10841;
    border: 0 none;
    color: white;
}

a {
    &.list-group-item-danger,
    &.list-group-item-info,
    &.list-group-item-success,
    &.list-group-item-warning {
        color: white;
    }
    &.list-group-item-success {
        &:focus,
        &:hover {
            background-color: #819819;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    &.list-group-item-info {
        &:focus,
        &:hover {
            background-color: #19A2CF;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    &.list-group-item-warning {
        &:focus,
        &:hover {
            background-color: #F99F1F;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    &.list-group-item-danger {
        &:focus,
        &:hover {
            background-color: #C72154;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.list-group-item .badge {
    margin-top: 2px;
}


/* Forms */

form {
    margin-bottom: 22px;
}

label {
    font-weight: bold;
    margin: 0 0 3px;
}

.required {
    color: #c3293a;
    display: inline-block;
    font-size: 14px;
}

.form-control,
.selectBox {
    border: 1px solid #e1e1e1;
    box-shadow: 0 1px white, inset 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    font-size: 12px;
    height: 40px;
    line-height: 22px;
    margin-bottom: 22px;
    min-height: 40px;
    padding: 8px 12px;
    width: 100%;
}

.form-control:hover,
.selectBox:hover {
    border-color: #ccc;
    text-decoration: none;
}

.form-control:focus,
.selectBox.selectBox-menuShowing {
    box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
    border: solid 1px #839bb7;
    outline: none;
    transition: border linear .2s, box-shadow linear .2s;
}

textarea.form-control {
    height: 100px;
    max-width: 100%;
}

.selectBox {
    max-width: 100%;
    width: 100% !important;
}

.checkbox,
.radio {
    font-weight: normal;
    margin: 0 0 8px;
    min-height: 18px;
    padding-left: 20px;
}

.new-checkbox,
.new-radio {
    background: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    float: left;
    height: 15px;
    margin: 2px 0 0 -20px;
    position: absolute;
    width: 15px;
}

.new-radio {
    border-radius: 7px;
    height: 14px;
    width: 14px;
    input[type="radio"] {
        cursor: pointer;
        height: 100%;
        margin: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        vertical-align: top;
        width: 100%;
    }
}

.new-checkbox {
    input[type="checkbox"] {
        cursor: pointer;
        height: 100%;
        margin: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        vertical-align: top;
        width: 100%;
    }
    svg {
        display: none;
        height: 18px;
        margin: -3px 0 0 -2px;
        width: 18px;
        polygon {
            fill: #1e1e1e;
        }
    }
    &.checked svg {
        display: block;
    }
}

.new-radio {
    span {
        border-radius: 3px;
        display: block;
        height: 6px;
        margin: 3px 0 0 3px;
        width: 6px;
    }
    &.checked span {
        background: #1e1e1e;
    }
    &.disabled {
        opacity: .5;
        filter: alpha(opacity=50);
    }
}

.new-checkbox.disabled {
    opacity: .5;
    filter: alpha(opacity=50);
}

.form-search {
    .checkbox,
    .radio {
        padding-left: 20px;
    }
}

.form-inline {
    .checkbox,
    .radio {
        padding-left: 20px;
    }
}

.form-search {
    .checkbox input[type="checkbox"],
    .radio input[type="radio"] {
        margin-left: -20px;
    }
}

.form-inline {
    .checkbox input[type="checkbox"],
    .radio input[type="radio"] {
        margin-left: -20px;
    }
}

.selectBox {
    background: white;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    &:focus,
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

button {
    outline: none;
    text-decoration: none;
}

.selectBox-label {
    box-sizing: border-box;
    color: #555;
    display: block;
    max-width: 100%;
    padding: 0 25px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.selectBox-arrow {
    background: url("../img/svg/select.svg") 50% 50% no-repeat;
    border-radius: 0 3px 3px 0;
    -khtml-border-radius: 0 3px 3px 0;
    height: 100%;
    left: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
}

.selectBox-dropdown-menu {
    background: white;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
    border: solid 1px #839bb7;
    outline: none;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
    color: #5f5f5f;
    font-size: 12px;
    list-style: none;
    margin: 0;
    max-height: 117px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    animation: fadeIn .5s;
}

.selectBox-options li {
    display: block;
    a {
        color: #1e1e1e;
        cursor: pointer;
        display: block;
        line-height: 1.5;
        padding: 2px 2px 2px 16px;
        margin: 0;
        min-height: 23px;
        text-decoration: none;
        transition: all .2s linear;
        &:hover {
            background: #f2f2f2;
            color: #111;
            text-decoration: none;
            transition: all .2s linear;
        }
    }
    +li a {
        border-top: 1px solid #839bb7;
    }
    &.selectBox-selected a {
        background: #e9e9e9;
        color: #000;
        text-decoration: none;
    }
}

.selectBox-inline {
    overflow-y: auto;
    padding: 0;
    width: 100%;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

fieldset[disabled] {
    .checkbox label,
    .radio label {
        cursor: default;
    }
    select {
        color: #999;
    }
    .selectBox {
        color: #999;
        .selectBox-label {
            color: #999;
        }
    }
}

.selectBox.selectBox-disabled {
    color: #999;
    .selectBox-label {
        color: #999;
    }
}

fieldset[disabled] .selectBox .selectBox-arrow {
    opacity: .3;
    filter: alpha(opacity=30);
}

.selectBox.selectBox-disabled {
    .selectBox-arrow {
        opacity: .3;
        filter: alpha(opacity=30);
    }
    &:focus {
        border-color: #e1e1e1;
        box-shadow: 0 1px white, inset 0 1px 4px rgba(0, 0, 0, 0.15);
    }
}

.form-inline {
    .control-label,
    .form-control {
        margin-bottom: 0;
    }
}

.form-horizontal .form-group .form-control {
    margin-bottom: 0;
}


/* Form size */

.input-lg {
    border-radius: 4px;
    font-size: 18px;
    height: 46px;
    line-height: 1.33;
    padding-top: 10px;
    padding-bottom: 10px;
}

.input-sm {
    border-radius: 2px;
    font-size: 12px;
    height: 30px;
    line-height: 1.5;
    min-height: inherit !important;
    padding-top: 5px;
    padding-bottom: 5px;
}


/* Form color */

.has-feedback .form-control-feedback {
    height: 40px;
    line-height: 40px;
}

.has-success {
    .checkbox,
    .checkbox-inline,
    .control-label,
    .form-control-feedback,
    .help-block,
    .radio,
    .radio-inline {
        color: #738d00;
    }
}

.has-warning {
    .checkbox,
    .checkbox-inline,
    .control-label,
    .form-control-feedback,
    .help-block,
    .radio,
    .radio-inline {
        color: #f89406;
    }
}

.has-error {
    .checkbox,
    .checkbox-inline,
    .control-label,
    .form-control-feedback,
    .help-block,
    .radio,
    .radio-inline {
        color: #c10841;
    }
}

.has-success .form-control {
    border-color: #738d00;
}

.has-warning .form-control {
    border-color: #f89406;
}

.has-error .form-control {
    border-color: #c10841;
}

.has-success .form-control:focus {
    border-color: #738d00;
    box-shadow: 0 0 10px rgba(115, 141, 0, 0.3);
    transition: box-shadow linear .2s;
}

.has-warning .form-control:focus {
    border-color: #f89406;
    box-shadow: 0 0 10px rgba(248, 148, 6, 0.3);
}

.has-error .form-control:focus {
    border-color: #c10841;
    box-shadow: 0 0 10px rgba(193, 8, 65, 0.3);
}


/* IMG */

.alignnone {
    margin: 0 0 25px;
}

.aligncenter {
    clear: both;
    margin: 0 auto 25px;
    text-align: center;
}

.alignleft {
    float: left;
    margin: 0 25px 25px 0;
}

.alignright {
    float: right;
    margin: 0 0 25px 25px;
}

.caption {
    color: #7f7f7f;
    display: inline-block;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin-bottom: 22px;
    max-width: 100%;
    img {
        display: block;
        margin: 0 auto 10px;
    }
}

.img-rounded {
    border-radius: 4px;
}

.img-thumbnail {
    border-radius: 0;
    display: inline-block;
    line-height: 0;
    position: relative;
    transition: opacity .2s ease-in;
    &:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s ease-out;
    }
    &.lightbox:hover {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .bg-images {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .2s ease-out;
    }
    &:hover>.bg-images {
        background: white;
        background: rgba(255, 255, 255, 0.7);
        transition: all .2s ease-out;
    }
    .bg-images i {
        margin: 5px 0 0 5px;
        &:before {
            color: white;
            font-size: 20px;
            transition: all .2s ease-out;
        }
    }
    &:hover .bg-images i:before {
        color: #000;
        font-size: 30px;
        transition: all .2s ease-out;
    }
    &.img-rounded {
        border-radius: 4px;
        .bg-images,
        img {
            border-radius: 4px;
        }
    }
    &.img-circle {
        border-radius: 500px;
        img {
            border-radius: 500px;
        }
        .bg-images {
            border-radius: 500px;
            i {
                margin: 17% 0 0 17%;
            }
        }
    }
    .bg-images i {
        margin: 9px 0 0 9px;
    }
}

.fancybox-wrap {}


/* Video */

audio,
embed,
iframe,
object,
video {
    max-width: 100%;
}

.video-box {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    height: 0;
    margin-bottom: 22px;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    embed,
    iframe,
    object,
    video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    &.html5 {
        height: auto;
        padding: 0;
        .video-js {
            height: auto !important;
            line-height: 0;
            width: 100% !important;
            .vjs-tech {
                height: auto;
                margin: 0;
                position: relative;
                width: 100%;
            }
        }
        .vjs-default-skin {
            color: white;
            .vjs-play-progress,
            .vjs-volume-level {
                background-color: #555;
            }
            .vjs-big-play-button,
            .vjs-control-bar,
            .vjs-slider {
                background: rgba(0, 0, 0, 0.8);
            }
        }
    }
}


/* Audio */

.audio-box audio {
    width: 100%;
}


/* Nav Bar */

.navbar-inverse {
    .brand,
    .nav>li>a {
        color: #7b7b7b;
        text-shadow: none;
    }
}


/* Drop Down */

.dropdown-backdrop {
    position: static;
}

.btn-group {
    &.open .dropdown-toggle,
    .dropdown-toggle {
        box-shadow: none;
    }
}

.dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    min-width: 156px;
    padding: 0;
    z-index: 800;
}

.open>.dropdown-menu {
    animation: fadeIn .4s;
}


/* Quotes */

blockquote {
    padding: 0 0 0 20px;
    margin: 0 0 22px;
    border-left: 2px solid #e1e1e1;
    &.pull-right {
        border-left: 0;
        border-right: 2px solid #e1e1e1;
        float: right;
        padding-left: 0;
        padding-right: 20px;
    }
    p {
        font-family: Georgia, "Times New Roman", Times, serif;
        font-style: italic;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 0;
    }
    small {
        line-height: 26px;
    }
}


/* Tabs */

.nav-tabs {
    margin-bottom: 0;
    margin-left: 0;
    >li>a {
        border-radius: 2px 2px 0 0;
        color: #1e1e1e;
        font-size: 12px;
        padding: 10px 20px 11px;
        transition: background-color .2s linear;
    }
}

.nav>li>a {
    &:focus,
    &:hover {
        background-color: #f7f7f7;
        transition: background-color .2s linear;
    }
}

.nav-tabs> {
    li>a {
        &:focus,
        &:hover {
            border-color: #f7f7f7 #f7f7f7 #e1e1e1;
        }
    }
    .active {
        >a {
            border: 1px solid #e1e1e1;
            border-bottom-color: transparent;
            color: #1e1e1e;
            font-weight: bold;
            &:focus,
            &:hover {
                border: 1px solid #e1e1e1;
                border-bottom-color: transparent;
                color: #1e1e1e;
                font-weight: bold;
            }
        }
        &.dropdown>a {
            z-index: 911;
        }
    }
}

.pill-content>.pill-pane,
.tab-content>.tab-pane {
    padding: 36px 0 0;
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
    float: none;
    margin: 0;
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
    min-width: 110px;
    margin-right: 0;
    margin-bottom: 3px;
    .fa {
        margin: 0 5px;
    }
}

.tabs-left> {
    .nav-tabs {
        border: 0 none;
        border-right: 1px solid #ddd;
        float: left;
        margin: 0 -1px 0 0;
        >li>a {
            margin-right: -1px;
            border-radius: 4px 0 0 4px;
            &:focus,
            &:hover {
                border-color: #f7f7f7 #ddd #f7f7f7 #f7f7f7;
            }
        }
        .active>a {
            border-color: #ddd transparent #ddd #ddd;
            &:focus,
            &:hover {
                border-color: #ddd transparent #ddd #ddd;
            }
        }
    }
    .tab-content {
        border-left: 1px solid #ddd;
        margin: 0;
        overflow: auto;
        >.tab-pane {
            padding: 0 0 0 20px;
        }
    }
}

.tabs-right> {
    .nav-tabs {
        border: 0 none;
        border-left: 1px solid #ddd;
        float: right;
        margin: 0 0 0 -1px;
        >li>a {
            margin-left: -1px;
            border-radius: 0 4px 4px 0;
            &:focus,
            &:hover {
                border-color: #f7f7f7 #f7f7f7 #f7f7f7 #ddd;
            }
        }
        .active>a {
            border-color: #ddd #ddd #ddd transparent;
            &:focus,
            &:hover {
                border-color: #ddd #ddd #ddd transparent;
            }
        }
    }
    .tab-content {
        border-right: 1px solid #ddd;
        margin: 0;
        overflow: auto;
        >.tab-pane {
            padding: 0 20px 0 0;
        }
    }
}


/* Accordion */

.panel {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 22px;
}

.panel-heading {
    padding: 9px 20px;
    border: 0 none;
    border-radius: 0;
}

.panel-footer {
    background-color: #e1e1e1;
    border-top: 1px solid #e1e1e1;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.panel-title {
    font-size: 14px;
}

.panel-body {
    padding: 10px 20px;
}

.list-group-item {
    padding-left: 20px;
    padding-right: 20px;
}

.panel-primary {
    border-color: #037ac5;
}

.panel-success {
    border-color: #738d00;
}

.panel-info {
    border-color: #0098ca;
}

.panel-warning {
    border-color: #f89406;
}

.panel-danger {
    border-color: #c10841;
}

.panel-default>.panel-heading {
    background-color: #f2f2f2;
    color: #1e1e1e;
}

.panel-primary>.panel-heading {
    background-color: #037ac5;
    color: white;
}

.panel-success>.panel-heading {
    background-color: #738d00;
    color: white;
}

.panel-info>.panel-heading {
    background-color: #0098ca;
    color: white;
}

.panel-warning>.panel-heading {
    background-color: #f89406;
    color: white;
}

.panel-danger>.panel-heading {
    background-color: #c10841;
    color: white;
}

.panel-default {
    border-color: #e1e1e1;
    >.panel-heading {
        background-color: #e1e1e1;
    }
}

.panel-group {
    margin-bottom: 22px;
    .panel {
        background-color: inherit;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
        .panel-heading {
            background-color: inherit;
            padding: 0;
            position: relative;
            >.panel-title:before {
                background: #e1e1e1;
                background: rgba(255, 255, 255, 0.4);
                bottom: 0;
                content: "";
                display: block;
                left: 45px;
                position: absolute;
                top: 0;
                width: 1px;
                z-index: 1;
            }
        }
    }
    .active.panel>.panel-heading>.panel-title:before {
        background: #444;
        background: rgba(255, 255, 255, 0.2);
    }
    .panel .panel-heading>.panel-title>a {
        background-color: #f2f2f2;
        color: #1e1e1e;
        display: block;
        line-height: 22px;
        padding: 9px 9px 9px 60px;
        text-decoration: none;
        transition: background-color .2s linear;
        &:hover {
            background-color: #e9e9e9;
            transition: background-color .2s linear;
        }
    }
    .active.panel>.panel-heading>.panel-title>a {
        background-color: #1e1e1e;
        color: white;
        display: block;
        padding: 9px 9px 9px 60px;
        text-decoration: none;
        &:hover {
            background-color: #1e1e1e;
            color: white;
            display: block;
            padding: 9px 9px 9px 60px;
            text-decoration: none;
        }
    }
    .panel .panel-heading>.panel-title>a {
        &:before {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
        }
        &:after {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
            height: 16px;
            margin: 12px 0 0 22px;
            width: 2px;
        }
    }
    .active.panel>.panel-heading>.panel-title>a {
        &:before {
            background: white;
        }
        &:after {
            background: white;
            display: none;
        }
    }
    .panel-heading+.panel-collapse .panel-body {
        border-top: 0 none;
    }
    .panel.e-hidden {
        margin: 0;
        overflow: hidden;
    }
    > {
        .panel-default>.panel-heading>.panel-title>a {
            background-color: #f2f2f2;
            &:hover {
                background-color: #e9e9e9;
            }
        }
        .panel-primary>.panel-heading>.panel-title>a {
            background-color: #037ac5;
            color: white;
            &:hover {
                background-color: rgba(3, 122, 197, 0.9);
                color: white;
            }
        }
        .panel-success>.panel-heading>.panel-title>a {
            background-color: #738d00;
            color: white;
            &:hover {
                background-color: rgba(115, 141, 0, 0.9);
            }
        }
        .panel-info>.panel-heading>.panel-title>a {
            background-color: #0098ca;
            color: white;
            &:hover {
                background-color: rgba(0, 152, 202, 0.9);
            }
        }
        .panel-warning>.panel-heading>.panel-title>a {
            background-color: #f89406;
            color: white;
            &:hover {
                background-color: rgba(248, 148, 6, 0.9);
            }
        }
        .panel-danger>.panel-heading>.panel-title>a {
            background-color: #c10841;
            color: white;
            &:hover {
                background-color: rgba(193, 8, 65, 0.9);
            }
        }
        .panel-primary>.panel-heading>.panel-title>a {
            &:after,
            &:before {
                background-color: white;
            }
        }
        .panel-success>.panel-heading>.panel-title>a {
            &:after,
            &:before {
                background-color: white;
            }
        }
        .panel-info>.panel-heading>.panel-title>a {
            &:after,
            &:before {
                background-color: white;
            }
        }
        .panel-warning>.panel-heading>.panel-title>a {
            &:after,
            &:before {
                background-color: white;
            }
        }
        .panel-danger>.panel-heading>.panel-title>a {
            &:after,
            &:before {
                background-color: white;
            }
        }
    }
    &.one-open .panel.active .panel-heading>.panel-title>a {
        cursor: default;
    }
    .panel-body {
        padding: 10px 0 10px 1px;
    }
}

.panel.panel-bg .panel-body {
    background: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
}


/* Progress */

.progress {
    border-radius: 0;
    height: 40px;
}

.progress-bar {
    font-size: 14px;
    background: #1e1e1e;
    filter: none;
    box-shadow: none;
    line-height: 40px;
    text-align: left;
    text-indent: 10px;
    text-shadow: none;
    transition: background .2s linear;
}

.progress-bar-info {
    background-color: #0098ca;
}

.progress-bar-success {
    background-color: #738d00;
}

.progress-bar-warning {
    background-color: #f89406;
}

.progress-bar-danger {
    background-color: #c10841;
}

.progress {
    &.border-radius {
        border-radius: 5px;
    }
    &[data-appear-progress-animation] .progress-bar {
        text-indent: -100%;
    }
    &.hover {
        .progress-bar:hover {
            background: #1e1e1e;
        }
        .progress-bar-info:hover {
            background: #0098ca;
        }
        .progress-bar-success:hover {
            background: #738d00;
        }
        .progress-bar-warning:hover {
            background: #c09853;
        }
        .progress-bar-danger:hover {
            background: #c10841;
        }
    }
}

.progress-circular {
    position: relative;
    text-align: center;
    >div {
        display: inline-block;
        vertical-align: top;
    }
    input.knob {
        border: 0 none;
        box-shadow: none;
        color: #7f7f7f;
        font-size: 0;
        font-style: italic;
        font-weight: normal !important;
    }
}


/* Pagination */

.pagination {
    margin: 20px 0;
    > {
        li> {
            a,
            span {
                background-color: #ccc;
                border: 0 none;
                border-radius: 2px;
                color: white;
                float: left;
                font-size: 12px;
                font-weight: bold;
                line-height: 24px;
                margin: 0 5px 0 0;
                min-width: 6px;
                padding: 0 9px;
                text-align: center;
                transition: all .2s linear;
            }
            a:hover {
                background-color: #1e1e1e;
                color: white;
                transition: all .2s linear;
            }
        }
        .active> {
            a {
                background-color: #1e1e1e;
                background-color: rgba(30, 30, 30, 0.7);
                color: white;
            }
            span {
                background-color: #1e1e1e;
                background-color: rgba(30, 30, 30, 0.7);
                color: white;
                &:hover {
                    background-color: #1e1e1e;
                    background-color: rgba(30, 30, 30, 0.7);
                    color: white;
                }
            }
        }
        li {
            &:first-child> {
                a,
                span {
                    border-left-width: 0;
                    border-radius: 2px;
                    font-size: 15px;
                    font-weight: normal;
                }
            }
            &:last-child> {
                a,
                span {
                    border-left-width: 0;
                    border-radius: 2px;
                    font-size: 15px;
                    font-weight: normal;
                }
            }
        }
        .disabled> {
            span {
                background-color: #ccc;
                color: white;
            }
            a {
                background-color: #ccc;
                color: white;
                &:focus,
                &:hover {
                    background-color: #ccc;
                    color: white;
                }
            }
        }
    }
    +.pagination-text {
        color: #999;
        font-size: 12px;
        float: right;
        line-height: 24px;
        vertical-align: top;
    }
    &.pagination-lg>li> {
        a,
        span {
            font-size: 16px;
            line-height: 28px;
            padding: 4px 14px;
        }
    }
    &.pagination-sm>li> {
        a,
        span {
            font-size: 10px;
            line-height: 20px;
            padding: 0 7px;
        }
    }
}

.pagination-sm>li:first-child> {
    a,
    span {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
}

.pagination-lg>li:first-child> {
    a,
    span {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
}

.pagination-sm>li:last-child> {
    a,
    span {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}

.pagination-lg>li:last-child> {
    a,
    span {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}


/* Icons */

.icon {
    background: none;
    color: #1e1e1e;
    display: inline-block;
    font-size: 20px;
    height: 32px;
    line-height: 32px;
    margin: 0 0 4px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    width: 32px;
    &.pull-left {
        float: left;
        margin-right: 10px;
    }
    &.pull-right {
        float: right;
        margin-left: 10px;
    }
    &.icon-24 {
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        width: 24px;
    }
    &.icon-40 {
        font-size: 26px;
        height: 40px;
        line-height: 40px;
        width: 40px;
    }
    &.icon-60 {
        font-size: 32px;
        height: 60px;
        line-height: 60px;
        width: 60px;
    }
    &.icon-100 {
        font-size: 50px;
        height: 100px;
        line-height: 100px;
        width: 100px;
    }
    .fa {
        width: 100%;
    }
    &.rounded {
        border-radius: 3px;
    }
    &.circle {
        border-radius: 50%;
    }
    &.border {
        box-sizing: border-box;
        border-width: 1px;
    }
}

.sbtnf {
    &:after,
    &:before {
        box-sizing: content-box;
    }
}


/* Frames */

.frame {
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    >img {
        width: 100%;
    }
}

.frame-padding {
    background: white;
    padding: 4px;
}

.frame-border {
    border-radius: 5px;
}

.frame-shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

[class*="frame-shadow"] {
    background: white;
    position: relative;
}

.frame-shadow-lifted {
    &:before {
        bottom: 15px;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 20px;
        left: 10px;
        position: absolute;
        width: 50%;
        -ms-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        transform: rotate(-3deg);
        z-index: -1;
    }
    &:after {
        bottom: 15px;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 20px;
        left: 10px;
        position: absolute;
        width: 50%;
        -ms-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        transform: rotate(-3deg);
        z-index: -1;
        right: 10px;
        left: auto;
        -ms-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        transform: rotate(3deg);
    }
}

.frame-shadow-perspective:before {
    bottom: 5px;
    box-shadow: -80px 0 8px rgba(0, 0, 0, 0.4);
    content: "";
    height: 35%;
    left: 80px;
    max-width: 200px;
    max-height: 50px;
    position: absolute;
    width: 50%;
    -ms-transform: skew(50deg);
    -o-transform: skew(50deg);
    transform: skew(50deg);
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    z-index: -1;
}

.frame-shadow-curved:before {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    border-radius: 10px/100px;
    bottom: 10px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: -1;
}

.frame-shadow-raised {
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.rotated-box {
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

.rotated-right-box {
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
}


/* Modal */

.modal {
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    &.modal-center .modal-dialog {
        transform: translate(0, 0%) !important;
        -ms-transform: translate(0, 0%) !important;
    }
    .close {
        font-size: 26px;
        position: absolute;
        right: 20px;
        top: 20px;
        span {
            line-height: 18px;
            margin-right: 5px;
            margin-top: 0;
            text-transform: uppercase;
        }
    }
}

.modal-header {
    border: 0 none;
    padding: 30px 20px 0;
    .title-box {
        margin-bottom: 20px;
        .title {
            margin: 0 0 12px;
            padding: 0;
        }
    }
}

.modal-body {
    padding: 0 20px 20px;
}

.modal-footer {
    background-color: #f7f7f7;
    border-top: 1px solid #e1e1e1;
    border-radius: 0 0 3px 3px;
    box-shadow: inset 0 1px 0 white;
    padding: 20px;
}


/* Product */

.product-name a {
    color: #1e1e1e;
    text-decoration: none;
    transition: opacity .2s linear;
    &:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
    }
}

.product-image {
    background: #f2f2f2;
    display: inline-block;
    line-height: 0;
    >img {
        width: 100%;
        transition: opacity .2s linear;
    }
    &:hover>img {
        opacity: .9;
        filter: alpha(opacity=90);
        transition: opacity .2s linear;
    }
}

.rotation .product-image:hover>img {
    opacity: 1;
    filter: alpha(opacity=100);
}

.price-old {
    color: #7f7f7f;
    display: inline-block;
    margin-right: 6px;
    position: relative;
    &:before {
        background: #f00;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        margin: 0 0 0 -23px;
        position: absolute;
        top: 50%;
        transform: rotate(330deg);
        width: 46px;
    }
}

.sale {
    border-top: 56px solid #f89406;
    border-right: 56px solid transparent;
    display: block;
    height: 0;
    line-height: 22px;
    position: absolute;
    width: 0;
    z-index: 1;
    &:before {
        color: white;
        content: "Sale";
        display: block;
        font-size: 11px;
        font-weight: bold;
        margin: -46px 0 0 5px;
        position: absolute;
        text-transform: uppercase;
        transform: rotate(315deg);
    }
    &.top {
        border-top: 56px solid #738d00;
        &:before {
            content: "Top";
        }
    }
    &.new {
        border-top: 56px solid #c10841;
        &:before {
            content: "New";
        }
    }
    &.best {
        border-top: 56px solid #0098ca;
        &:before {
            content: "Best";
        }
    }
}


/* Additional Classes */

.display-t {
    display: table;
}

.display-tc {
    display: table-cell;
}

.slim {
    font-weight: normal;
}

.border-radius {
    border-radius: 5px;
}

.bg {
    background-color: #f2f2f2;
}

.border {
    border-width: 1px;
}

.width-auto {
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left;
        width: auto;
    }
}

.margin-center {
    margin: 0 auto;
}

.bottom-padding {
    margin-bottom: 80px;
}

.bottom-padding-mini {
    margin-bottom: 40px;
}

.bottom-padding.last {
    margin-bottom: 0;
}

.no-top-padding {
    padding-top: 0 !important;
}

.col2-set {
    .col-1 {
        float: left;
        width: 48.5%;
    }
    .col-2 {
        float: left;
        width: 48.5%;
        float: right;
    }
}


/******************************************************************************
Top
*******************************************************************************/

#top-box {
    background: #1e1e1e;
    min-height: 30px;
    padding: 5px 0;
    width: 100%;
}


/* Language/Currency */

.btn-group.btn-select {
    color: #7f7f7f;
    font-size: 12px;
    vertical-align: top;
}

#top-box .btn-group.btn-select {
    margin: 3px 0 0;
    &.language {
        margin-right: 6px;
    }
}

.btn-group.btn-select {
    .dropdown-toggle {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid transparent;
        box-shadow: none;
        color: #7f7f7f;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        padding: 0 7px 3px;
        z-index: 2;
    }
    &.open .btn.dropdown-toggle {
        background: white;
        border: 1px solid #ccc;
        border-bottom: 0 none;
        border-radius: 3px 3px 0 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        color: #7f7f7f;
    }
    .btn.dropdown-toggle .caret {
        color: white;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 0;
    }
    &.open .btn.dropdown-toggle {
        .caret {
            color: #777;
        }
        &:after {
            background: white;
            bottom: 0;
            content: "";
            display: block;
            height: 6px;
            left: 0;
            margin: 0 0 -6px;
            position: absolute;
            right: 0;
            top: auto;
            z-index: 3;
            animation: fadeIn .3s;
        }
    }
    .dropdown-menu {
        border-radius: 0 3px 3px 3px;
        margin: 0;
        padding-top: 8px;
        z-index: 1;
        >li>a {
            font-size: 12px;
            line-height: 22px;
            padding: 4px 6px;
            &:hover {
                background: #f2f2f2;
            }
            img {
                margin: 5px 9px 0 0;
                vertical-align: top;
            }
        }
    }
}

.dropdown-menu>li>a i {
    font-size: 18px;
    margin-right: 10px;
    min-width: 18px;
    vertical-align: middle;
}

#top-box .btn-group.btn-select {
    .dropdown-toggle {
        z-index: 911;
    }
    .dropdown-menu {
        z-index: 910;
    }
    &.open .btn.dropdown-toggle:after {
        z-index: 912;
    }
}


/* Menu */

.top-navbar {
    background: none;
    border: 0 none;
    border-radius: 0;
    height: auto;
    margin: 0;
    min-height: 30px;
    .collapse {
        padding: 0;
    }
    .nav {
        margin-top: -5px;
        margin-bottom: -5px;
        >li {
            a {
                font-size: 12px;
                line-height: 22px;
                padding: 9px 15px;
                transition: background .2s linear, color .2s linear;
                .fa {
                    font-size: 120%;
                    margin-right: 4px;
                    &.after {
                        margin-left: 4px;
                        margin-right: 0;
                    }
                }
                .count {
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 3px;
                    display: inline-block;
                    font-size: 110%;
                    line-height: 1;
                    margin-left: 4px;
                    padding: 3px 5px;
                    transition: background .2s linear;
                }
            }
            &:hover {
                a .count {
                    background: rgba(0, 0, 0, 0.1);
                    transition: background .2s linear;
                }
                >a {
                    background: #efefef;
                    color: #7b7b7b;
                    transition: background .2s linear, color .2s linear;
                    max-height: 35px;
                }
            }
            >a:hover {
                background: #efefef;
                color: #7b7b7b;
                transition: background .2s linear, color .2s linear;
            }
        }
    }
}


/* Social Icons */

.top-social {
    margin: 0;
    .icon-item {
        color: white;
        color: rgba(255, 255, 255, 0.3);
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin-left: 1px;
        margin-right: 1px;
        position: relative;
        text-align: center;
        transition: color .2s linear;
        vertical-align: top;
        width: 30px;
        i {
            bottom: 0;
            left: 0;
            line-height: 30px;
            position: absolute;
            right: 0;
            top: 0;
            &:before {
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
            }
        }
        &:hover {
            color: white;
            transition: color .2s linear;
            i:before {
                text-shadow: none;
            }
        }
    }
    &.bg-social .icon-item {
        background: white;
        border-radius: 3px;
        color: white;
        &:hover {
            opacity: 1;
            filter: alpha(opacity=100);
        }
        i {
            border-radius: 2px;
            opacity: .7;
            filter: alpha(opacity=70);
            transition: opacity .2s linear;
        }
        &:hover i {
            opacity: 1;
            filter: alpha(opacity=100);
            transition: opacity .2s linear;
        }
        i:before {
            text-shadow: none;
        }
        .fa-facebook {
            background: #3b5998;
        }
        .fa-twitter {
            background: #00bdec;
        }
        .fa-google-plus {
            background: #d94a39;
        }
        .fa-linkedin {
            background: #1b92bd;
        }
    }
}


/* Search */

.top-search {
    margin: 0 0 0 auto;
    max-width: 300px;
    position: relative;
    .form-control {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #e1e1e1;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: none;
        color: white;
        height: 30px;
        line-height: 14px;
        margin: 0;
        min-height: 30px;
        padding-top: 4px;
        padding-bottom: 4px;
        &:focus {
            border-color: rgba(255, 255, 255, 0.7);
        }
        &:-ms-input-placeholder {
            color: white;
            color: rgba(255, 255, 255, 0.3);
        }
    }
    .search-submit {
        background: none;
        height: 30px;
        opacity: .5;
        filter: alpha(opacity=50);
        padding: 6px 0 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .2s linear;
        width: 30px;
        &:hover {
            opacity: .7;
            filter: alpha(opacity=70);
            transition: opacity .2s linear;
        }
    }
}


/* Info */

.top-info {
    color: white;
    font-size: 13px;
    padding: 5px 0;
    a {
        color: white;
        color: rgba(255, 255, 255, 0.8);
        transition: color .2s linear;
        &:hover {
            color: white;
            text-decoration: none;
            transition: color .2s linear;
        }
    }
    .separator {
        color: rgba(255, 255, 255, 0.4);
        vertical-align: top;
    }
}


/******************************************************************************
Header
*******************************************************************************/

.header {
    width: 100%;
    .container {
        position: relative;
    }
    .logo-box {
        display: table;
        table-layout: fixed;
    }
    .logo {
        display: block;
        height: 70px;
        max-width: 100%;
        vertical-align: middle;
        width: 100%;
        a {
            display: inline-block;
            max-width: 100%;
        }
    }
    .logo-box.auto-height {
        display: block;
        height: 100px;
        .logo {
            display: block;
        }
        img {
            max-height: inherit;
        }
    }
    .site-description {
        color: #bbb;
        font-size: 9px;
        line-height: 1.3;
    }
}

.fixed-header {
    margin-top: 0;
    .page-box {
        padding-top: 104px;
    }
    .header {
        left: 0;
        margin: 0;
        //        position: fixed;        right: 0;
        top: 0;
        z-index: 870;
        //transition: margin-top 0.2s linear;
        width: auto;
    }
    .header-wrapper {
        background: white;
        transition: background .2s linear;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
        padding: 0;
        .container {
            .row {
                overflow: hidden;
            }
        }
    }
    &.background-opacity .header-wrapper {
        transition: background .2s linear;
    }
    &.fixed .header-wrapper,
    &.hidden-top.visible-top .header-wrapper {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        padding: 0;
    }
    &.hidden-top {
        .page-box {
            padding-top: 112px;
            transition: padding-top .2s linear;
        }
        &.fixed .page-box {
            padding-top: 104px;
            transition: padding-top .2s linear;
        }
        #top-box {
            left: 0;
            margin-top: -32px;
            position: fixed;
            top: 0;
            z-index: 910;
            //transition: margin-top 0.2s linear;
        }
        .header {
            margin-top: 8px;
            top: 0;
        }
        &.visible-top {
            #top-box {
                margin-top: 0;
                //transition: margin-top 0.2s linear;
            }
            .header {
                margin-top: 40px;
                transition: margin-top .2s linear;
            }
        }
        &.fixed #top-box,
        &.visible-top.fixed #top-box {
            margin-top: -40px;
            transition: margin-top .2s linear;
        }
        &.fixed .header,
        &.visible-top.fixed .header {
            margin-top: 0;
            transition: margin-top .2s linear;
        }
    }
}

.header {
    .right-box {
        display: table;
    }
    .right-box-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .primary {
        .navbar {
            background: none;
            border: 0 none;
            margin: 0;
            .navbar-collapse {
                padding: 0;
                position: relative;
            }
            .nav {
                margin: 0;
                position: relative;
                > {
                    li {
                        padding: 0;
                        position: relative;
                    }
                    .selected>a {
                        color: #777;
                    }
                }
                a {
                    >i {
                        margin-right: 2px;
                        opacity: .8;
                        filter: alpha(opacity=80);
                        text-align: center;
                        width: 15px;
                    }
                    .item-new {
                        background: #ff0068;
                        border-radius: 3px;
                        color: white;
                        font-size: 11px;
                        line-height: 1;
                        letter-spacing: .01em;
                        padding: 4px 10px;
                        position: absolute;
                        right: 19px;
                        top: 1px;
                        text-transform: uppercase;
                    }
                }
                .megamenu a .item-new {
                    right: 14px;
                    top: -12px;
                }
                a .item-new {
                    &:before,
                    .before {
                        border: 5px solid transparent;
                        border-right-color: #ff0068;
                        bottom: -5px;
                        content: "";
                        display: block;
                        position: absolute;
                        right: 0;
                    }
                    &.bg-info {
                        &:before,
                        .before {
                            border-right-color: #0098ca;
                        }
                    }
                    &.bg-success {
                        &:before,
                        .before {
                            border-right-color: #738d00;
                        }
                    }
                    &.bg-primary {
                        &:before,
                        .before {
                            border-right-color: #428bca;
                        }
                    }
                    &.bg-warning {
                        &:before,
                        .before {
                            border-right-color: #f89406;
                        }
                    }
                    &.bg-danger {
                        &:before,
                        .before {
                            border-right-color: #c10841;
                        }
                    }
                }
                .sub .item-new {
                    float: right;
                    left: 0;
                    margin: 2px 0 0 10px;
                    position: relative;
                    top: 0;
                }
                .megamenu .sub a .item-new {
                    float: right;
                    left: 0;
                    margin: 2px 0 0 10px;
                    position: relative;
                    top: 0;
                    float: none;
                }
                .sub .item-new {
                    &:before,
                    .before {
                        display: none;
                    }
                }
                .parent {
                    position: relative;
                }
                >li.parent {
                    >a:after {
                        background: transparent;
                        bottom: 0;
                        content: "";
                        display: none;
                        height: 12px;
                        left: 4px;
                        margin: 0 0 -6px;
                        position: absolute;
                        right: 4px;
                        transition: background .5s linear;
                        z-index: 906;
                    }
                    &:hover>a:after {
                        display: none !important;
                        transition: background .1s linear;
                        border: none !important;
                    }
                    &.megamenu:hover>a:after {
                        left: 0;
                        right: 0;
                        display: none !important;
                    }
                }
            }
        }
        .sub {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            display: none;
            font-size: 13px;
            line-height: 24px;
            margin: -1px 0 0;
            padding: 15px 20px;
            position: absolute;
            z-index: 905;
            li {
                font-size: 13px;
                line-height: 24px;
            }
        }
        .parent:hover>.sub {
            display: block;
            animation: fadeIn .4s;
        }
        .sub {
            a {
                color: #1e1e1e;
                display: block;
                text-decoration: none;
                width: 175px;
                transition: opacity .2s linear;
                &:visited {
                    color: #1e1e1e;
                    display: block;
                    text-decoration: none;
                    width: 175px;
                    transition: opacity .2s linear;
                }
                &:hover {
                    opacity: .6;
                    filter: alpha(opacity=60);
                    transition: opacity .2s linear;
                }
            }
            .sub {
                left: 100%;
                top: 0;
            }
        }
        .navbar .nav .parent {
            &.megamenu {
                overflow: hidden;
                position: static;
                >a {
                    position: relative;
                }
            }
        }
        .megamenu {
            >.sub {
                border-radius: 3px;
                left: -15px;
                padding: 0;
                width: 886px;
                z-index: 900;
                .sub-wrapper {
                    display: table;
                    width: 100%;
                }
                .promo-block {
                    border-right: 1px solid #ccc;
                    display: table-cell;
                    font-size: 12px;
                    line-height: 18px;
                    position: relative;
                    text-align: center;
                    vertical-align: middle;
                    width: 34%;
                }
                .sub-list {
                    +.promo-block {
                        border-left: 1px solid #ccc;
                        border-right: 0 none;
                    }
                    display: table-cell;
                    padding: 35px 5px;
                    vertical-align: top;
                    width: 100%;
                }
            }
            &.promo {
                >.sub .sub-list {
                    width: 66%;
                }
                >.sub .box {
                    &:nth-child(2n+1) {
                        clear: left;
                    }
                    clear: none;
                    width: 50%;
                }
            }
            >.sub .box {
                float: left;
                padding: 0 20px 28px;
                width: 33%;
                &:nth-child(3n+1) {
                    clear: left;
                }
            }
            &.four-columns>.sub .box {
                clear: none;
                &:nth-child(4n+1) {
                    clear: left;
                }
            }
            &.promo.four-columns>.sub .box {
                &:nth-child(3n+1) {
                    clear: left;
                }
                clear: none;
            }
            &.four-columns>.sub {
                .box,
                .promo-block {
                    width: 25%;
                }
                .sub-list {
                    width: 75%;
                }
            }
            &.promo.four-columns>.sub .box {
                width: 33%;
            }
            &.five-columns>.sub .box {
                clear: none;
                &:nth-child(5n+1) {
                    clear: left;
                }
            }
            &.promo.five-columns>.sub .box {
                &:nth-child(4n+1) {
                    clear: left;
                }
                clear: none;
            }
            &.five-columns>.sub {
                .box,
                .promo-block {
                    width: 20%;
                }
                .sub-list {
                    width: 80%;
                }
            }
            &.promo.five-columns>.sub .box {
                width: 25%;
            }
            .title {
                font-size: 13px;
                line-height: 23px;
                margin: 0 0 3px;
                text-transform: uppercase;
            }
            >.sub a {
                width: 100%;
            }
        }
        .sub .sub .sub {
            .sub {
                background: none;
                border: 0 none;
                box-shadow: none;
                display: block;
                left: 0;
                padding: 0;
                position: relative;
                animation: fadeIn 0;
            }
            .parent>a {
                background: none !important;
            }
        }
        .navbar .nav>li {
            &.text-primary>a {
                color: #428bca;
            }
            &.text-success>a {
                color: #738d00;
            }
            &.text-info>a {
                color: #0098ca;
            }
            &.text-warning>a {
                color: #f89406;
            }
            &.text-danger>a {
                color: #c10841;
            }
            &[class*="item-"]:hover>a {
                box-shadow: none;
            }
        }
        [class*="item-"] .sub {
            box-shadow: none;
        }
        .navbar .nav>li {
            &.parent.item-bg:hover>a:after {
                display: none;
            }
            &.item-primary:hover>a {
                border-color: #428bca;
            }
        }
        .item-primary {
            &.megamenu>.sub .promo-block,
            .sub {
                border-color: #428bca;
            }
        }
        .navbar .nav>li.item-success:hover>a {
            border-color: #738d00;
        }
        .item-success {
            &.megamenu>.sub .promo-block,
            .sub {
                border-color: #738d00;
            }
        }
        .navbar .nav>li.item-info:hover>a {
            border-color: #0098ca;
        }
        .item-info {
            &.megamenu>.sub .promo-block,
            .sub {
                border-color: #0098ca;
            }
        }
        .navbar .nav>li.item-warning:hover>a {
            border-color: #f89406;
        }
        .item-warning {
            &.megamenu>.sub .promo-block,
            .sub {
                border-color: #f89406;
            }
        }
        .navbar .nav>li.item-danger:hover>a {
            border-color: #c10841;
        }
        .item-danger {
            &.megamenu>.sub .promo-block,
            .sub {
                border-color: #c10841;
            }
        }
        .navbar .nav>li {
            &.item-bg {
                &:hover a,
                .sub a {
                    color: white;
                }
            }
            &.item-primary.item-bg:hover>a {
                background: #428bca;
            }
        }
        .item-primary.item-bg {
            .sub {
                background: #428bca;
            }
            &.parent {
                &:hover>.sub,
                .sub {
                    background: #428bca;
                }
            }
        }
        .navbar .nav>li.item-success.item-bg:hover>a {
            background: #738d00;
        }
        .item-success.item-bg {
            .sub {
                background: #738d00;
            }
            &.parent {
                &:hover>.sub,
                .sub {
                    background: #738d00;
                }
            }
        }
        .navbar .nav>li.item-info.item-bg:hover>a {
            background: #0098ca;
        }
        .item-info.item-bg {
            .sub {
                background: #0098ca;
            }
            &.parent {
                &:hover>.sub,
                .sub {
                    background: #0098ca;
                }
            }
        }
        .navbar .nav>li.item-warning.item-bg:hover>a {
            background: #f89406;
        }
        .item-warning.item-bg {
            .sub {
                background: #f89406;
            }
            &.parent {
                &:hover>.sub,
                .sub {
                    background: #f89406;
                }
            }
        }
        .navbar .nav>li.item-danger.item-bg:hover>a {
            background: #c10841;
        }
        .item-danger.item-bg {
            .sub {
                background: #c10841;
            }
            &.parent {
                &:hover>.sub,
                .sub {
                    background: #c10841;
                }
            }
        }
        .navbar .nav>li {
            &.item-inverse>a {
                color: white;
            }
            &.item-primary.item-inverse>a {
                background: #428bca;
            }
            &.item-success.item-inverse>a {
                background: #738d00;
            }
            &.item-info.item-inverse>a {
                background: #0098ca;
            }
            &.item-warning.item-inverse>a {
                background: #f89406;
            }
            &.item-danger.item-inverse>a {
                background: #c10841;
            }
            &.item-inverse:hover>a {
                background: white;
                color: #1e1e1e;
            }
        }
    }
    .header-icons {
        float: right;
        margin: 11px 0;
        text-align: right;
    }
    .cart-header {
        display: inline-block;
        font-size: 12px;
        margin: 0 -15px 0 -1px;
        white-space: normal;
        &.no-open {
            opacity: 0;
            filter: alpha(opacity=0);
        }
        .dropdown-toggle {
            border-width: 1px;
            border-color: white;
            border-color: transparent;
            border-bottom: 0 none;
            color: #1e1e1e;
            display: block;
            line-height: 22px;
            outline: none;
            padding: 11px 15px 12px;
            position: relative;
            text-decoration: none;
            text-shadow: none;
            z-index: 891;
            .icon {
                display: inline-block;
                height: 22px;
                margin: 0 8px 0 0;
                vertical-align: top;
                width: 16px;
                svg {
                    margin-top: 2px;
                    vertical-align: top;
                }
            }
        }
        &.open .dropdown-toggle {
            background: white;
            border-color: #ccc;
            border-bottom: 0 none;
            border-radius: 3px 3px 0 0;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            &:after {
                background: white;
                bottom: 0;
                content: "";
                display: block;
                height: 8px;
                left: -3px;
                margin: 0 0 -5px;
                position: absolute;
                right: 0;
            }
        }
        .dropdown-menu {
            left: auto;
            margin: -4px 0 0;
            padding: 17px 20px 20px;
            right: 0;
            text-align: left;
            width: 355px;
            animation: fadeIn .4s;
            z-index: 890;
            .close-box {
                line-height: 12px;
                margin: 0 0 13px;
                text-align: right;
            }
            .close {
                font-size: 26px;
                line-height: 12px;
                text-transform: uppercase;
                vertical-align: top;
                span {
                    line-height: 14px;
                    margin: 0 5px 0 0;
                }
            }
            > {
                strong {
                    font-size: 13px;
                    line-height: 22px;
                    text-transform: uppercase;
                }
                ul {
                    margin: 0 0 20px;
                    li {
                        border-bottom: 1px solid #e1e1e1;
                        padding: 24px 20px 24px 0;
                        position: relative;
                    }
                }
            }
            .product-image {
                display: block;
                float: left;
                margin: 0 15px 0 0;
                width: 70px;
            }
            .product-remove {
                margin: 0 0 0 5px;
                position: absolute;
                right: 0;
                top: 24px;
            }
            .product-name {
                font-size: 12px;
                font-weight: normal;
                line-height: 18px;
                margin: 0 0 10px;
                padding-left: 85px;
            }
            .product-price {
                font-size: 12px;
                line-height: 16px;
                padding-left: 85px;
                .price {
                    font-size: 16px;
                }
            }
            .cart-button {
                text-align: right;
                .checkout {
                    margin-left: 6px;
                }
            }
        }
    }
}


/* Primary Menu */


/* Four columns */


/* Five columns */


/* Menu Color */


/* Cart  */


/* Phone & Search Header */

.phone-header,
.search-header {
    color: white;
    height: 30px;
    margin: 8px 0;
    position: absolute;
    text-align: center;
    vertical-align: top;
    width: 41px;
}

.phone-header a,
.search-header a {
    display: block;
    height: 23px;
    padding-top: 7px;
    width: 100%;
}

.phone-header a svg,
.search-header a svg {
    overflow: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    transition: all .3s linear;
}

.phone-header a:hover svg,
.search-header a:hover svg {
    opacity: .5;
    filter: alpha(opacity=50);
    transition: all .3s linear;
}


/* Phone & Search Active Header */

.phone-active,
.search-active {
    bottom: 0;
    display: none;
    margin: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 920;
}

.phone-active {
    background: white;
    color: #ebebeb;
    font-size: 48px;
    line-height: 58px;
    .close {
        float: right;
        font-size: 26px;
        margin: 24px 0 0 5px;
    }
}

.search-active .close {
    float: right;
    font-size: 26px;
    margin: 24px 0 0 5px;
}

.phone-active .close span,
.search-active .close span {
    line-height: 18px;
    margin-right: 5px;
    margin-top: 0;
    text-transform: uppercase;
}

.phone-active strong {
    color: #1e1e1e;
    letter-spacing: 1px;
}

.search-active {
    .close {
        position: absolute;
        right: 79px;
        z-index: 1;
    }
    form {
        height: 100%;
        margin: 0;
        position: relative;
        width: 100%;
    }
    .search-string {
        box-sizing: border-box;
        box-shadow: none;
        color: #1e1e1e;
        font-size: 24px;
        height: 100%;
        line-height: 30px;
        margin: 0;
        padding: 14px 135px 14px 18px;
        width: 100%;
        &:hover {
            box-sizing: border-box;
            box-shadow: none;
            color: #1e1e1e;
            font-size: 24px;
            height: 100%;
            line-height: 30px;
            margin: 0;
            padding: 14px 135px 14px 18px;
            width: 100%;
        }
        &:focus {
            border-color: #ccc;
            outline: 0;
            outline: thin dotted \9;
            box-shadow: none;
        }
    }
    .search-submit {
        background: none;
        border-left-width: 1px;
        height: 30px;
        line-height: 1;
        margin: 0;
        outline: none;
        padding: 0;
        position: absolute;
        right: 1px;
        top: 17px;
        width: 46px;
        transition: all .3s linear;
        svg {
            margin-top: 2px;
            path {
                fill: #1e1e1e;
            }
        }
        &:hover {
            opacity: .5;
            filter: alpha(opacity=50);
            transition: all .3s linear;
        }
    }
}

.header {
    &.header-two .phone-header {
        border-right: 0 none;
        margin-right: -15px;
    }
    &.header-three {
        background: rgba(255, 255, 255, 0.9);
        min-height: inherit;
        .container,
        .row {
            min-height: inherit;
        }
        .logo-box {
            text-align: center;
        }
    }
}


/******************************************************************************
Top Fixed Box
*******************************************************************************/

.top-fixed-box {
    background: white;
    background: rgba(255, 255, 255, 0.97);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border: 1px solid #e1e1e1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
    margin-top: -60px;
    padding: 7px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: margin-top .3s linear;
    &.fixed {
        margin-top: 0;
        transition: margin-top .3s linear;
    }
    .social {
        display: inline-block;
        margin: 0 6px;
        vertical-align: top;
    }
    .contact-box {
        color: #7f7f7f;
        font-size: 12px;
        margin-top: 11px;
        .phone {
            margin-right: 20px;
        }
        .fa {
            font-size: 14px;
            margin-right: 3px;
        }
        .phone .fa {
            font-size: 20px;
            line-height: 17px;
            vertical-align: top;
        }
    }
}


/******************************************************************************
Breadcrumbs
*******************************************************************************/

.breadcrumb {
    background-color: transparent;
    font-size: 11px;
    line-height: 18px;
    margin: 0;
    >li {
        line-height: 18px;
        text-shadow: none;
        >a {
            color: white;
            text-decoration: none;
            transition: opacity .2s linear;
            &:hover {
                opacity: .8;
                filter: alpha(opacity=80);
                transition: opacity .2s linear;
            }
        }
        +li:before {
            padding: 0 6px;
            color: white;
            content: "»";
        }
    }
}

.breadcrumb-none {
    height: 10px;
}


/******************************************************************************
Slider
*******************************************************************************/

.slider {
    background: #f2f2f2;
    padding: 54px 0;
    >.container {
        position: relative;
    }
    &.load .sliders-box>.col-md-12+.col-md-12 {
        display: none;
    }
    .slid {
        position: relative;
        .slid-img {
            max-height: 550px;
            z-index: 1;
        }
    }
    .slid-content {
        bottom: 93px;
        color: #7b7b7b;
        font-size: 12px;
        line-height: 18px;
        overflow: hidden;
        padding: 38px 40px 0;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 3;
        .title {
            color: white;
            font-size: 20px;
            line-height: 24px;
            margin: 0 0 34px;
        }
        .descriptions {
            margin: 0 0 37px;
        }
        .btn {
            background: #f8f8f8;
            color: #1e1e1e;
            font-weight: bold;
            transition: all .3s linear;
            &:hover {
                background: white;
                transition: all .3s linear;
            }
        }
    }
    .slider-nav {
        background-color: #1e1e1e;
        background-color: rgba(30, 30, 30, 0.97);
        bottom: 0;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 2;
        .nav-box {
            bottom: 33px;
            left: 40px;
            position: absolute;
            right: 40px;
            text-align: center;
            z-index: 4;
        }
        .prev {
            background-color: white;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
            color: #838282;
            display: block;
            height: 60px;
            left: 0;
            line-height: 60px;
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            text-align: center;
            text-decoration: none;
            visibility: hidden;
            width: 60px;
            transition: all .3s linear;
        }
        .next {
            background-color: white;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
            color: #838282;
            display: block;
            height: 60px;
            left: 0;
            line-height: 60px;
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            text-align: center;
            text-decoration: none;
            visibility: hidden;
            width: 60px;
            transition: all .3s linear;
            left: auto;
            right: 0;
        }
        .next *,
        .prev * {
            vertical-align: middle;
        }
        .prev {
            margin-right: 2px;
        }
        .next {
            margin-left: 2px;
        }
    }
    .container:hover .slider-nav {
        .next,
        .prev {
            opacity: 1;
            filter: alpha(opacity=100);
            visibility: visible;
            transition: all .3s linear;
        }
    }
}

.page-slider:hover .slider-nav {
    .next,
    .prev {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
        transition: all .3s linear;
    }
}

.slider .slider-nav {
    .next:hover,
    .prev:hover {
        background-color: white;
        color: #a0a0a0;
        transition: all .3s linear;
    }
    .next:hover polygon,
    .prev:hover polygon {
        fill: #9f9f9f;
        transition: all .3s linear;
    }
    .pagination {
        padding: 0 65px;
        text-align: center;
    }
}

.switches a {
    background: white;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    height: 14px;
    margin: 0 3px;
    width: 14px;
    transition: all .3s linear;
    &:hover {
        background: white;
        transition: all .3s linear;
    }
    &.selected {
        background: white;
        background: rgba(255, 255, 255, 0.5);
        transition: all .3s linear;
    }
}

.progressive-slider .slider-nav .pagination a {
    vertical-align: middle;
}

.page-slider {
    background: none;
    padding: 0;
    position: relative;
}

.progressive-slider-two {
    .sliders-container {
        margin: 0 -15px 0 15px;
        padding: 0;
        position: relative;
    }
    &.load .sliders-box img+img {
        display: none;
    }
    .pagination {
        bottom: 0;
        left: 0;
        margin: 0 0 15px;
        position: absolute;
        right: 0;
        text-align: center;
        a {
            background: #1e1e1e;
            background: rgba(30, 30, 30, 0.5);
            &:hover {
                background: #1e1e1e;
            }
        }
        .selected {
            background: #1e1e1e;
        }
    }
    .slider-banners {
        display: table;
        height: 100%;
        table-layout: fixed;
        .banner {
            display: table-row;
            height: 33.33333333%;
            background: #f6a232;
            &:nth-child(1) {
                background: #ee485c;
            }
            &:nth-child(2) {
                background: #65a93c;
            }
        }
    }
}

.progressive-slider-three {
    .slider-wrapper {
        border: solid 6px white;
        border-radius: 6px;
    }
    &.load .slider-wrapper img+img {
        display: none;
    }
}

.progressive-slider-four {
    &.load .slider-wrapper img+img {
        display: none;
    }
    background: none;
    padding: 0;
    position: relative;
    .prev {
        color: white;
        font-size: 40px;
        left: 30px;
        line-height: 1;
        margin-top: -20px;
        position: absolute;
        top: 50%;
    }
    .next {
        color: white;
        font-size: 40px;
        left: 30px;
        line-height: 1;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        left: auto;
        right: 30px;
    }
}

.content {
    .progressive-slider .container {
        padding: 0;
        width: 100% !important;
    }
    .slider {
        &.progressive-slider {
            padding: 0;
        }
        .slider-nav {
            right: 0;
        }
        .slid-content {
            bottom: 84px;
            padding: 28px 30px 0;
            right: 8px;
            width: 47.333333%;
        }
    }
}

.slider .slider-nav .nav-box {
    left: 23px;
    right: 23px;
}

.content {
    .slider .slider-nav {
        width: 47.333333%;
        .next,
        .prev {
            height: 36px;
            line-height: 36px;
            width: 36px;
        }
    }
    .switches a {
        width: 11px;
        height: 11px;
    }
    .slider {
        .slider-nav .nav-box {
            bottom: 30px;
        }
        .pagination {
            margin: 5px 0;
        }
    }
}


/******************************************************************************
Metro Box
*******************************************************************************/

.metro-banners {
    background: #b3768e;
    background: -o-linear-gradient(left, #b3768e 0%, #54638e 100%);
    background: -ms-linear-gradient(left, #b3768e 0%, #54638e 100%);
    background: linear-gradient(to right, #b3768e 0%, #54638e 100%);
    padding: 54px 0 24px;
    .banner {
        margin-bottom: 30px;
        text-align: center;
    }
}

.banner {
    display: block;
    text-align: center;
    transition: opacity .2s linear;
    &:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
    }
}

.metro-banners .banner img {
    box-shadow: 3px 3px 0 0 rgba(50, 50, 50, 0.5);
}


/******************************************************************************
Sidebar
*******************************************************************************/

.sidebar .widget {
    margin: 0 0 80px;
    &:last-child {
        margin-bottom: 0;
    }
    ol,
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }
    .title-block,
    header {
        border-bottom: 1px solid #e1e1e1;
        margin: 0 0 40px;
        padding: 0 0 15px;
        position: relative;
    }
}

.product-bottom .related-products header {
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 40px;
    padding: 0 0 15px;
    position: relative;
}

.sidebar .widget {
    .title-block:before,
    header:before {
        background: #1e1e1e;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin: 0 0 -1px;
        position: absolute;
        width: 40px;
    }
}

.product-bottom .related-products header:before {
    background: #1e1e1e;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: 0 0 -1px;
    position: absolute;
    width: 40px;
}

.sidebar .widget {
    .title-block .title,
    header .title {
        color: #7f7f7f;
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
    }
}

.product-bottom .related-products header h3 {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
}

.sidebar {
    .widget .subtitle {
        font-size: 14px;
        line-height: 1;
        margin: 0 0 25px;
    }
    .whats-news {
        margin-bottom: 40px;
        overflow: hidden;
    }
    .carousel-box header .title {
        line-height: 40px;
    }
    .whats-news header {
        margin: 0;
    }
    .carousel-box .product {
        display: block;
        float: left;
        margin: 40px 15px;
    }
    .action {
        overflow: hidden;
        .clear-all {
            float: left;
            font-size: 14px;
            line-height: 40px;
            text-transform: none;
            &.close {
                font-size: 20px;
                line-height: 38px;
            }
            span {
                font-size: 14px;
                line-height: 39px;
                margin: 0 0 0 2px;
                vertical-align: middle;
            }
        }
        .btn {
            float: right;
        }
    }
    .more {
        color: #7f7f7f;
        font-size: 12px;
        margin: 5px 0 0;
        text-decoration: none;
        transition: opacity .2s linear;
        &:hover {
            opacity: .8;
            filter: alpha(opacity=80);
            transition: opacity .2s linear;
            text-decoration: none;
        }
    }
    .menu {
        li {
            margin: 0 0 3px;
            a {
                background-color: #f2f2f2;
                background-color: rgba(0, 0, 0, 0.05);
                color: #1e1e1e;
                display: block;
                padding: 9px 9px 9px 60px;
                position: relative;
                text-decoration: none;
                transition: all .2s linear;
                &:before {
                    background: #e1e1e1;
                    background: rgba(255, 255, 255, 0.4);
                    bottom: 0;
                    content: "";
                    display: block;
                    left: 45px;
                    position: absolute;
                    top: 0;
                    width: 1px;
                }
                &:hover {
                    background-color: #e9e9e9;
                    background-color: rgba(0, 0, 0, 0.1);
                    transition: all .2s linear;
                }
            }
            &.active>a {
                background-color: $bleu-fonce !important;
                color: white;
                transition: all .2s linear;
                &:before {
                    background: rgba(255, 255, 255, 0.2);
                }
            }
            &.parent {
                >a .open-sub {
                    bottom: 0;
                    display: block;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 45px;
                    &:before {
                        background: #1e1e1e;
                        content: "";
                        display: block;
                        height: 2px;
                        left: 0;
                        margin: 19px 0 0 15px;
                        position: absolute;
                        top: 0;
                        width: 16px;
                    }
                    &:after {
                        background: #1e1e1e;
                        content: "";
                        display: block;
                        height: 2px;
                        left: 0;
                        margin: 19px 0 0 15px;
                        position: absolute;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        margin: 12px 0 0 22px;
                        width: 2px;
                    }
                }
                &.active>a .open-sub {
                    &:before {
                        background: white !important;
                    }
                    &:after {
                        background: white;
                        display: none;
                    }
                }
            }
        }
        .sub {
            background-color: #f2f2f2;
            background-color: rgba(0, 0, 0, 0.05);
            padding: 7px 0 4px 5px;
            li {
                margin: 0;
            }
            a {
                background: none;
                color: #333;
                margin: 0;
                &.active {
                    background: $rose;
                    color: white;
                    &:hover {
                        background: $rose;
                        opacity: .7;
                    }
                }
                &:before {
                    display: none;
                }
                &:hover {
                    background-: #e9e9e9;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
            .active>a {
                background: none;
                color: white;
                &.active {
                    background: $rose !important;
                }
            }
            .sub {
                //                border-left: 1px dotted #000;
                background-color: rgba(177, 55, 112, 0.05);
                a {
                    padding-left: 70px;
                    color: #7f7f7f;
                    &:before {
                        content: "--";
                        position: absolute;
                        display: block;
                        padding: 8px 0 0 8px;
                        background: transparent;
                        color: #7f7f7f;
                        width: 45px;
                    }
                    &.active,
                    &.active:before {
                        color: white;
                    }
                }
            }
            .parent.active:first-child a {
                margin-left: 60px;
            }
            li.parent>a .open-sub {
                left: 10px;
                &:before {
                    margin: 19px 0 0 17px;
                    width: 12px;
                }
                &:after {
                    height: 12px;
                    margin: 14px 0 0 22px;
                    width: 2px;
                }
            }
        }
        li {
            >a .item-icon {
                left: 15px;
                position: absolute;
                top: 13px;
            }
            &:not(.active)>a .item-icon {
                opacity: .5;
                filter: alpha(opacity=50);
            }
        }
    }
    .section {
        +.section {
            border-top-width: 1px;
            margin: 20px 0 0;
            padding: 20px 0 0;
        }
        li {
            line-height: 18px;
            +li {
                margin: 12px 0 0;
            }
            >a {
                color: #1e1e1e;
                &:visited {
                    color: #1e1e1e;
                }
            }
            .fa {
                color: #7f7f7f;
            }
        }
        .selected li {
            background: url("../img/point.png") right 13px repeat-x white;
            margin: 0 0 8px;
            padding: 0 16px 0 0;
            position: relative;
        }
        li {
            >span {
                background: white;
                color: #7f7f7f;
            }
            span span {
                color: #1e1e1e;
            }
        }
        .selected .close {
            background: white;
            color: #7f7f7f;
            font-size: 20px;
            padding: 0 2px;
            position: absolute;
            right: 0;
            top: 3px;
            &:hover {
                color: #1e1e1e;
            }
        }
    }
    .list {
        ul {
            margin-bottom: 0;
        }
        li {
            padding: 4px 0;
            a {
                color: #1e1e1e;
            }
            span {
                color: #7f7f7f;
            }
        }
    }
    .specials li {
        padding: 24px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
        +li {
            border-top-width: 1px;
        }
        .product-image {
            background: #f7f7f7;
            border: 1px solid #f5f5f5;
            border-radius: 3px;
            float: left;
            height: 74px;
            margin: 0 15px 1px 0;
            overflow: hidden;
            width: 74px;
        }
        .product-name {
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            margin: 0 0 4px;
            padding-left: 85px;
        }
        .price-box {
            font-size: 16px;
            line-height: 22px;
            padding-left: 85px;
        }
        .rating-box {
            margin: 3px 0 14px 85px;
            overflow: hidden;
        }
    }
    .poll {
        strong {
            display: block;
            margin-bottom: 21px;
        }
        ul {
            margin: 0;
            padding: 0 0 27px;
        }
        li {
            margin-bottom: 11px;
        }
    }
    .compare-products {
        ul {
            margin: 0;
            padding: 0 0 17px;
        }
        li {
            background: url("../img/point.png") right 13px repeat-x white;
            margin: 0 0 8px;
            padding: 0 16px 0 0;
            position: relative;
            .title {
                background: white;
                color: #1e1e1e;
                padding-right: 2px;
            }
            .close {
                background: white;
                font-size: 20px;
                padding: 0 2px;
                position: absolute;
                right: 0;
                top: 3px;
            }
        }
    }
    .wishlist {
        li {
            padding: 0 46px 0 0;
        }
        .add-cart {
            background: white;
            color: #7f7f7f;
            height: 100%;
            padding: 0 3px;
            position: absolute;
            right: 26px;
            top: 1px;
            path {
                transition: all .2s linear;
            }
            &:hover path {
                fill: #1e1e1e;
                transition: all .2s linear;
            }
        }
    }
    .newsletter {
        form {
            margin: 5px 0 0;
            position: relative;
        }
        input {
            margin: 0;
            padding-right: 43px;
        }
        .submit {
            background: none;
            color: #ccc;
            height: 38px;
            outline: none;
            padding: 0;
            position: absolute;
            right: 1px;
            top: 1px;
            width: 38px;
            .glyphicon {
                line-height: 20px;
            }
        }
        input:focus+.submit {
            color: #000;
            transition: all .2s linear;
        }
    }
    .banners {
        overflow: hidden;
        .slide {
            float: left;
            max-width: 270px;
            margin-left: 15px;
            margin-right: 15px;
            padding-left: 0;
            padding-right: 0;
            position: relative;
        }
        .banner-text {
            background: rgb(30, 30, 30);
            background: rgba(30, 30, 30, 0.65);
            bottom: 0;
            color: white;
            left: 0;
            min-height: 58px;
            padding: 20px;
            position: absolute;
            right: 0;
            text-align: right;
            .title {
                font-size: 20px;
                margin: 0;
            }
            p {
                line-height: 18px;
                margin: 0;
            }
        }
    }
    .tags {
        ul {
            margin: 0;
        }
        li {
            float: left;
            margin: 0 3px 3px 0;
        }
        a {
            background: #1e1e1e;
            background: rgba(30, 30, 30, 0.7);
            border-radius: 2px;
            color: white;
            display: block;
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            padding: 0 5px;
            text-decoration: none;
            transition: all .2s linear;
            &:hover {
                background: #1e1e1e;
                transition: all .2s linear;
            }
        }
    }
}


/* Menu */


/* Shop By */


/* List */


/* Specials */


/* Community Poll */


/* Compare Products */


/* Newsletter */


/* Recommended */


/* Tags */


/* Social */

.social .sbtnf {
    border-radius: 2px;
    color: white;
    display: block;
    height: 40px;
    float: left;
    line-height: 40px;
    margin: 0 10px 0 0;
    opacity: .35;
    filter: alpha(opacity=35);
    transition: opacity .2s linear;
    text-align: center;
    width: 40px;
    &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
        transition: opacity .2s linear;
    }
}

.sidebar {
    .widget.social .sbtnf {
        margin-bottom: 10px;
    }
    .links {
        li {
            line-height: 21px;
        }
        a {
            color: #7f7f7f;
            text-decoration: none;
            transition: color .2s linear;
            &:visited {
                color: #7f7f7f;
                text-decoration: none;
                transition: color .2s linear;
            }
            &:hover {
                color: #000;
                transition: color .2s linear;
            }
        }
    }
    .calendar-wrap {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        overflow: hidden;
        table {
            margin: 0;
            td,
            th {
                padding: 3px;
            }
        }
        caption {
            background: #1e1e1e;
            color: white;
            font-size: 14px;
            padding: 5px;
        }
        tfoot {
            td {
                border: 0 none;
            }
            a {
                text-decoration: none;
                &:hover {
                    opacity: .8;
                    filter: alpha(opacity=80);
                    transition: opacity .2s linear;
                }
            }
        }
    }
    .facebook-widget .fb-like-box {
        border: 1px solid #e1e1e1;
        max-width: 270px;
        width: 100%;
        >span {
            max-width: 100% !important;
        }
    }
    .calendar .datepicker-box .datepicker {
        background: rgba(0, 0, 0, 0.02);
        border-radius: 3px;
        padding: 0;
        width: 270px;
        max-width: 100%;
        table {
            width: 100%;
            max-width: 100%;
        }
    }
}


/* Links */


/* Calendar */


/* Datepicker */

.datepicker table tr td {
    &.active {
        &.disabled:hover,
        &:active,
        &:hover,
        &:hover:active {
            background: #037ac5;
        }
        &.disabled {
            &:active,
            &:hover:active {
                background: #037ac5;
            }
        }
        &.active,
        &:hover.active {
            background: #037ac5;
        }
        &.disabled {
            &.active,
            &:hover.active {
                background: #037ac5;
            }
            background: #037ac5;
        }
        &:hover.disabled {
            background: #037ac5;
        }
        &.disabled {
            &.disabled,
            &:hover.disabled {
                background: #037ac5;
            }
        }
        &:hover[disabled],
        &[disabled] {
            background: #037ac5;
        }
        &.disabled {
            &:hover[disabled],
            &[disabled] {
                background: #037ac5;
            }
        }
        background: #037ac5;
        &:hover {
            background: #037ac5;
        }
        &.disabled {
            background: #037ac5;
            &:hover {
                background: #037ac5;
            }
        }
    }
    &.today {
        &.disabled:hover,
        &:active,
        &:hover,
        &:hover:active {
            background: #f89406;
            color: white;
        }
        &.disabled {
            &:active,
            &:hover:active {
                background: #f89406;
                color: white;
            }
        }
        &.active,
        &:hover.active {
            background: #f89406;
            color: white;
        }
        &.disabled {
            &.active,
            &:hover.active {
                background: #f89406;
                color: white;
            }
            background: #f89406;
            color: white;
        }
        &:hover.disabled {
            background: #f89406;
            color: white;
        }
        &.disabled {
            &.disabled,
            &:hover.disabled {
                background: #f89406;
                color: white;
            }
        }
        &:hover[disabled],
        &[disabled] {
            background: #f89406;
            color: white;
        }
        &.disabled {
            &:hover[disabled],
            &[disabled] {
                background: #f89406;
                color: white;
            }
        }
        background: #f89406;
        color: white;
        &:hover {
            background: #f89406;
            color: white;
        }
        &.disabled {
            background: #f89406;
            color: white;
            &:hover {
                background: #f89406;
                color: white;
            }
        }
    }
}


/******************************************************************************
Banner Set
*******************************************************************************/

.banner-set {
    border-top-width: 1px;
    padding-bottom: 16px;
    overflow: hidden;
    &:before {
        border-top: 1px solid #e1e1e1;
        content: "";
        display: block;
        left: 0;
        margin-top: -1px;
        position: absolute;
        right: 0;
    }
    &.bottom-padding {
        padding-bottom: 0;
    }
}

.load.banner-set {
    .container {
        background-image: url("../img/svg/loader.svg"), none;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 40px;
        height: 314px;
        overflow: hidden;
    }
    &.banner-set-mini .nav-box .container,
    .nav-box .container {
        background: none;
        height: auto;
    }
    .container>* {
        opacity: 0;
        filter: alpha(opacity=0);
        transition: opacity .2s linear;
    }
}

.container .banner-set .container {
    padding: 0;
    width: auto;
}

.banner-set {
    .container>* {
        transition: opacity .2s linear;
    }
    .banner {
        border-right-width: 1px;
        color: #7f7f7f;
        display: block;
        float: left;
        font-size: 12px;
        line-height: 18px;
        margin: 0;
        width: 292px;
        padding: 19px;
        text-decoration: none;
        transition: color .2s linear, box-shadow .2s linear;
        &:first-child {
            border-left-width: 1px;
        }
        img {
            margin: 0 0 28px;
            max-height: 158px;
            width: 100%;
            transition: opacity .2s linear;
        }
        .title {
            color: #7f7f7f;
            font-size: 20px;
            font-weight: normal;
            line-height: 1.2;
            margin: 0;
            padding: 0 0 12px;
            text-decoration: none;
            transition: all .2s linear;
        }
        .description {
            height: 54px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover {
            color: #1e1e1e;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
            opacity: 1;
            filter: alpha(opacity=100);
            transition: color .2s linear, box-shadow .2s linear;
            img {
                opacity: .9;
                filter: alpha(opacity=90);
                transition: opacity .2s linear;
            }
            .title {
                color: #1e1e1e;
                transition: all .2s linear;
            }
        }
    }
    .nav-box {
        padding: 0;
        text-align: center;
        vertical-align: top;
    }
    >.container:after {
        border-top: 1px solid #e1e1e1;
        content: "";
        display: block;
        left: 0;
        margin-top: -1px;
        position: absolute;
        right: 0;
    }
    .next,
    .pagination,
    .prev {
        display: inline-block;
        margin-top: 15px;
        vertical-align: top;
    }
    .next,
    .prev {
        color: #ccc;
        font-size: 13px;
        height: 14px;
        line-height: 14px;
        vertical-align: top;
        transition: color .2s linear;
    }
    .next:hover,
    .prev:hover {
        color: #1e1e1e;
        transition: color .2s linear;
    }
    .next.disabled,
    .prev.disabled {
        cursor: default;
        opacity: .12;
        filter: alpha(opacity=12);
    }
    .next .glyphicon,
    .prev .glyphicon {
        margin: 0;
        top: 0;
        vertical-align: top;
    }
    .pagination {
        height: 14px;
        margin: 15px 7px 0;
        a {
            background: #ececec;
            &:hover {
                background: #000;
            }
            &.selected {
                background: #ccc;
            }
        }
    }
    &.banner-set-mini {
        overflow: visible;
        padding-bottom: 0;
    }
}

.load.banner-set.banner-set-mini .container {
    height: 127px;
    overflow: hidden;
    .banners {
        height: 127px;
        overflow: hidden;
    }
}

.banner-set.banner-set-mini {
    .banner {
        padding: 9px;
        width: 146px;
        img {
            margin-bottom: 14px;
        }
        .title {
            font-size: 14px;
            padding: 0;
        }
    }
    .pagination {
        margin-bottom: 15px;
    }
    &.banner-set-no-pagination {
        .nav-box {
            position: relative;
            .container {
                position: relative;
            }
        }
        .pagination {
            display: none !important;
        }
        .prev {
            left: 0;
            margin: -70.5px 0 0 -15px;
            position: absolute;
            top: 0;
        }
        .next {
            left: 0;
            margin: -70.5px 0 0 -15px;
            position: absolute;
            top: 0;
            left: auto;
            margin-left: 0;
            margin-right: -15px;
            right: 0;
        }
    }
}

.container .banner-set.banner-set-mini.banner-set-no-pagination {
    .prev {
        margin-left: -30px;
    }
    .next {
        margin-right: -30px;
    }
}


/******************************************************************************
Main
*******************************************************************************/

#main {
    padding: 80px 0;
    &.no-padding {
        padding: 0;
    }
}

.page-header {
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 80px;
    padding: 0;
    .title {
        font-weight: 600;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .container {
        position: relative;
        &:before {
            background: #1e1e1e;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            left: 15px;
            margin: 0 0 -1px;
            position: absolute;
            width: 40px;
        }
    }
    .page-description {
        padding: 0 0 18px;
    }
}

.no-margin {
    margin-bottom: 0 !important;
}

.title-box {
    border-bottom-width: 1px;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    &.no-margin {
        margin-bottom: 0 !important;
    }
    &:before {
        background: #1e1e1e;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin: 0 0 -1px;
        position: absolute;
        width: 40px;
    }
    &.text-right:before {
        left: auto;
        right: 0;
    }
    &.text-center:before {
        left: 50%;
        margin-left: -20px;
    }
    .title {
        color: #7f7f7f;
        font-size: 18px;
        font-weight: normal;
        margin: 0 0 12px;
        padding: 14px 0 0;
    }
    .btn {
        font-size: 12px;
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
        right: 0;
        top: 0;
        .glyphicon {
            margin: -1px 0 0 3px;
        }
    }
    &.title-white {
        border-color: rgba(255, 255, 255, 0.3);
    }
}

.page-header.white {
    border-color: rgba(255, 255, 255, 0.3);
}

.page-header.white .container:before,
.title-box.title-white:before {
    background: white;
}

.title-box.title-white .title {
    color: white;
}


/******************************************************************************
Home Pages
*******************************************************************************/


/* Packages */

.package {
    background: #f7f7f7;
    margin: 0 auto 40px;
    max-width: 370px;
    .title {
        line-height: 32px;
        padding: 20px 20px 15px;
        a {
            color: #1e1e1e;
            font-size: 28px;
            font-weight: bold;
            line-height: 32px;
            text-decoration: none;
        }
    }
    .price-box {
        font-size: 12px;
        line-height: 18px;
        overflow: hidden;
        padding: 0 20px 20px;
        .icon {
            color: #505050;
            height: 96px;
            text-align: center;
            width: 96px;
            i {
                background: none;
                font-size: 50px;
                height: auto;
                line-height: 100px;
                margin: 0;
                width: auto;
            }
        }
        .description {
            margin: 0 120px 17px 0;
        }
        .price {
            font-size: 36px;
            font-weight: bold;
            line-height: 1;
            margin: 7px 0 0;
            span {
                font-size: 12px;
            }
        }
    }
    .bottom-box {
        border-top-width: 1px;
        overflow: hidden;
        padding: 19px 19px 20px;
        .more {
            color: #7f7f7f;
            display: block;
            float: left;
            font-size: 12px;
            line-height: 1;
            text-decoration: none;
            transition: opacity .2s linear;
            &:hover {
                opacity: .65;
                filter: alpha(opacity=65);
                transition: opacity .2s linear;
            }
            span {
                font-size: 17px;
                line-height: 12px;
                margin: 0 0 0 3px;
                vertical-align: top;
            }
        }
        .rating-box {
            float: right;
        }
    }
}


/* Features */

.features-block .header-box {
    display: block;
    min-height: 30px;
    margin: 0 0 13px;
    overflow: hidden;
    .icon-box {
        background: none;
        color: #1e1e1e;
        float: left;
        height: 30px;
        margin: 0 10px 0 0;
        text-align: center;
        width: 24px;
        transition: opacity .2s linear;
    }
    a.icon-box:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
    }
    .icon-box i:before {
        font-size: 20px;
        line-height: 30px;
    }
    h6 {
        margin: 6px 0 0;
    }
}


/* Welcome block */

.content-block {
    box-sizing: border-box;
    padding: 20px;
    .btn {
        +.btn {
            margin-left: 14px;
        }
        &.pull-left {
            margin: 0 10px 0 0;
        }
        &.pull-right {
            margin: 0 0 0 10px;
        }
    }
}


/* Services */

.service {
    .icon {
        border-radius: 35px;
        color: #1e1e1e;
        display: block;
        height: 70px;
        float: left;
        margin: 0 30px 0 0;
        text-align: center;
        text-decoration: none;
        width: 70px;
        transition: opacity .2s linear;
        &:hover {
            opacity: .8;
            filter: alpha(opacity=80);
            transition: opacity .2s linear;
        }
        i:before {
            font-size: 30px;
            line-height: 70px;
        }
    }
    .title {
        margin: 2px 0 17px;
    }
    a:not(.btn) {
        color: #1e1e1e;
        display: inline-block;
        transition: opacity .2s linear;
    }
}

.big-services-box a:not(.btn),
.features-block a:not(.btn) {
    color: #1e1e1e;
    display: inline-block;
    transition: opacity .2s linear;
}

.big-services-box a:hover,
.features-block a:hover,
.service a:hover {
    text-decoration: none;
    opacity: .8;
    filter: alpha(opacity=80);
    text-decoration: none;
    transition: opacity .2s linear;
}

.white {
    .big-services-box a,
    .service a {
        color: white;
    }
}

.service .text-small {
    overflow: hidden;
}

.big-services-box {
    margin-bottom: 40px;
    text-align: center;
}

.big-icon {
    border-radius: 50px;
    color: #1e1e1e;
    display: block;
    height: 100px;
    margin: 0 auto 20px;
    text-align: center;
    width: 100px;
    transition: opacity .2s linear;
    &:visited {
        border-radius: 50px;
        color: #1e1e1e;
        display: block;
        height: 100px;
        margin: 0 auto 20px;
        text-align: center;
        width: 100px;
        transition: opacity .2s linear;
    }
    i:before {
        font-size: 50px;
        line-height: 100px;
    }
}

a.big-icon:hover {
    opacity: .8;
    filter: alpha(opacity=80);
    transition: opacity .2s linear;
}

.big-icon.pull-left {
    margin: 0 20px 20px 0;
}


/* Why Choose Us */

.why-choose {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-size: 13px;
        line-height: 20px;
        padding: 11px 0 8px 23px;
        position: relative;
        &:before {
            content: "âœ“";
            display: block;
            font-size: 16px;
            left: 2px;
            position: absolute;
            top: 11px;
        }
        +li {
            border-top-width: 1px;
        }
    }
}


/* Promo Partners */

.promo-partners {
    font-size: 13px;
    line-height: 20px;
    .col-md-2 {
        margin-bottom: 37px;
    }
}


/* Latest Posts */

.latest-posts {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        overflow: hidden;
        padding: 22px 0;
        +li {
            border-top-width: 1px;
        }
    }
}

.latest-postst li:first-child {
    padding-top: 0;
}

.latest-posts li {
    &:last-child {
        padding-bottom: 0;
    }
    .image {
        float: left;
        height: 84px;
        margin: 0 25px 0 0;
        width: 84px;
    }
    .meta {
        color: #7f7f7f;
        font-size: 1.4rem;
        line-height: 18px;
        margin: 0 0 10px;
    }
    .description {
        overflow: hidden;
    }
    a {
        color: #1e1e1e;
        font-size: 13px;
        line-height: 20px;
        margin: 0;
    }
}

.latest-posts-white li {
    .meta {
        color: rgba(255, 255, 255, 0.6);
    }
    a {
        color: rgba(255, 255, 255, 0.8);
    }
    border-color: rgba(255,
    255,
    255,
    0.3);
}


/******************************************************************************
Carousel
*******************************************************************************/

.carousel {
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        display: block;
        float: left;
    }
    .col-md-1 {
        max-width: 80px;
    }
    .col-md-2 {
        max-width: 161px;
    }
    .col-md-3 {
        max-width: 242px;
    }
    .col-md-4 {
        max-width: 323px;
    }
    .col-md-5 {
        max-width: 404px;
    }
    .col-md-6 {
        max-width: 485px;
    }
    .col-md-7 {
        max-width: 565px;
    }
    .col-md-8 {
        max-width: 646px;
    }
    .col-md-9 {
        max-width: 727px;
    }
    .col-md-10 {
        max-width: 808px;
    }
    .col-md-11 {
        max-width: 889px;
    }
    .col-md-12 {
        max-width: 970px;
    }
}

.carousel-links a {
    transition: opacity .2s linear;
    &:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
    }
}

.carousel [class*="span"].pull-right {
    float: right;
}

.load.carousel-box {
    background-image: url("../img/svg/loader.svg"), none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40px;
    >* {
        opacity: 0;
        filter: alpha(opacity=0);
        transition: opacity .2s linear;
    }
}

.carousel-box>* {
    transition: opacity .2s linear;
}

.load.carousel-box .carousel> {
    a+a,
    div+div {
        visibility: hidden;
    }
}

.caroufredsel_wrapper {
    margin-bottom: 0 !important;
}

.carousel-box {
    .next,
    .prev {
        background-color: #ccc;
        border-radius: 3px;
        color: #fbfbfb;
        display: block;
        float: right;
        height: 40px;
        line-height: 40px;
        margin: 0 0 0 10px;
        text-align: center;
        text-decoration: none;
        width: 40px;
        transition: all .2s linear;
    }
    .next:hover,
    .prev:hover {
        background-color: #000;
        color: #efefef;
        text-decoration: none;
        transition: color .2s linear, background-color .2s linear;
    }
    .next:hover polygon,
    .prev:hover polygon {
        fill: #efefef;
    }
    .next svg {
        margin-left: 2px;
    }
    .prev svg {
        margin-right: 2px;
    }
    .next *,
    .prev * {
        vertical-align: middle;
    }
    &.no-nav {
        .next,
        .prev {
            display: none;
        }
    }
    .pagination {
        margin-bottom: 0;
        text-align: center;
        width: 100%;
    }
    &.no-pagination .pagination {
        display: none;
    }
}

.overflow {
    overflow: hidden;
}

.carousel-box .pagination a {
    background: #ececec;
    &:hover {
        background: #000;
    }
    &.selected {
        background: #ccc;
        background: rgba(0, 0, 0, 0.3);
    }
}


/******************************************************************************
About Us
*******************************************************************************/

.about-us {
    font-size: 13px;
    strong {
        font-size: 14px;
    }
}


/******************************************************************************
Clients
*******************************************************************************/

.client {
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    display: inline-block;
    margin: 0 auto 20px;
    max-width: 100%;
    text-align: center;
    transition: all .2s ease-in;
    width: 200px;
    &:hover {
        border-color: #e1e1e1;
        transition: border-color .2s ease-in;
    }
    +.tooltip {
        font-size: 14px;
        z-index: 800;
        .tooltip-inner {
            padding: 10px;
        }
    }
}

.carousel .client {
    margin-bottom: 0;
}


/******************************************************************************
News
*******************************************************************************/

.news .news-item {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 35px;
    .time {
        color: #7f7f7f;
        margin-bottom: 2px;
    }
    .title {
        font-size: 14px;
        margin-bottom: 7px;
        a {
            color: #1e1e1e;
            transition: all .2s linear;
            &:hover {
                opacity: .75;
                filter: alpha(opacity=75);
                transition: all .2s linear;
            }
        }
    }
    .more {
        color: #7f7f7f;
        margin: 5px 0 0;
        text-decoration: none;
        transition: all .2s linear;
        &:hover {
            opacity: .8;
            filter: alpha(opacity=80);
            transition: all .2s linear;
        }
        span {
            font-size: 16px;
            line-height: 20px;
            vertical-align: top;
        }
    }
}


/******************************************************************************
Category
*******************************************************************************/

#catalog .category-img {
    background: #f2f2f2;
    margin: 0 0 80px;
    position: relative;
    img {
        width: 100%;
    }
    .description {
        background: rgb(30, 30, 30);
        background: rgba(30, 30, 30, 0.7);
        bottom: 0;
        color: white;
        font-size: 20px;
        font-weight: bold;
        left: 0;
        padding: 35px 38px;
        position: absolute;
        right: 0;
    }
}

.toolbar {
    .grid-list {
        float: left;
        .grid,
        .list {
            background-color: #1e1e1e;
            background-color: rgba(30, 30, 30, 0.7);
            border-radius: 2px;
            display: block;
            float: left;
            height: 24px;
            margin: 0 7px 0 0;
            text-align: center;
            width: 24px;
        }
        a {
            &.grid,
            &.list {
                background: #ccc;
                transition: background .2s linear;
            }
            &.grid:hover,
            &.list:hover {
                background: #1e1e1e;
                transition: background .2s linear;
            }
        }
        .grid .glyphicon,
        .list .glyphicon {
            color: white;
            font-size: 12px;
            margin: 5px 0 0;
            top: 0;
            vertical-align: top;
        }
    }
    .sort-catalog {
        float: left;
        margin: 0 17px 0 13px;
        .dropdown-toggle {
            background-color: #1e1e1e;
            background-color: rgba(30, 30, 30, 0.7);
            color: #ccc;
            z-index: 26;
            span {
                color: #f7f7f7;
            }
        }
        .open .dropdown-toggle span {
            color: #7f7f7f;
        }
    }
}

.btn-group.btn-select {
    .dropdown-menu {
        z-index: 25;
    }
    &.open .btn.dropdown-toggle:after {
        z-index: 27;
    }
}

.toolbar {
    .up-down {
        background-color: #1e1e1e;
        background-color: rgba(30, 30, 30, 0.7);
        background-image: url("../img/up.png");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        box-shadow: none;
        height: 24px;
        margin: 0 0 0 3px;
        padding: 0;
        vertical-align: top;
        width: 22px;
        &.active {
            background-color: #1e1e1e;
            background-color: rgba(30, 30, 30, 0.7);
            background-image: url("../img/down.png");
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }
    .per-page {
        color: #7f7f7f;
        font-size: 12px;
        line-height: 24px;
        margin: 0 0 0 3px;
    }
    .price-regulator {
        color: #7f7f7f;
        font-size: 12px;
        width: 323px;
        b {
            color: #1e1e1e;
            display: inline-block;
            margin-top: 5px;
        }
        .layout-slider {
            float: right;
            margin-top: 5px;
            width: 270px;
        }
    }
}

.jslider .jslider-pointer {
    background: none;
    height: 12px;
    margin: 0 0 0 -4px;
    width: 8px;
    svg {
        vertical-align: top;
    }
}

.toolbar {
    .grid-list,
    .sort-catalog {
        margin-bottom: 20px;
    }
}

#catalog .products {
    clear: both;
    margin-top: 20px;
    &.grid {
        text-align: center;
    }
}

.pagination-box {
    margin: 40px 0 0;
    .pagination {
        margin: 0 10px 0 0;
    }
}


/******************************************************************************
Login Page
*******************************************************************************/

#main.login-register {
    overflow: hidden;
    padding-bottom: 40px;
}

.form-box.forgot-form {
    margin-bottom: 0;
}

.info-box {
    margin-bottom: 40px;
    padding: 27px 0 0;
    .title {
        font-weight: normal;
        margin: 0 0 17px;
    }
}

.form-box .title {
    font-weight: normal;
    margin: 0 0 17px;
}

.info-box .descriptions {
    margin: 0 0 26px;
}

.form-box {
    .descriptions {
        margin: 0 0 26px;
    }
    background: #f2f2f2;
    border-radius: 5px;
    margin: 0 0 40px;
    padding: 27px 30px 25px;
    .checkbox {
        font-size: 12px;
        margin: 0 0 28px;
        .new-checkbox {
            margin-top: 0;
        }
    }
    .buttons-box {
        font-size: 11px;
        line-height: 1.5;
        .btn {
            margin: 3px 8px 5px 0;
        }
        .required {
            font-size: 11px;
            margin: 3px 8px 5px 0;
        }
    }
}

.buttons-box .required {
    font-size: 11px;
}

.form-box {
    .buttons-box .forgot {
        display: inline-block;
        margin: 3px 8px 5px 0;
        transition: opacity .2s linear;
        &:hover {
            text-decoration: none;
            opacity: .8;
            filter: alpha(opacity=80);
            transition: opacity .2s linear;
        }
    }
    .form-group {
        position: relative;
    }
    .help-block {
        bottom: -20px;
        margin: 0;
        position: absolute;
    }
}


/******************************************************************************
Login Page 2
*******************************************************************************/

.page-login-promo {
    .page-box {
        display: table;
        height: 100% !important;
        width: 100%;
    }
    .header {
        min-height: 1px;
        position: absolute;
        width: 100%;
    }
    #main {
        display: table-cell;
        padding: 134px 0 30px;
        vertical-align: middle;
    }
    .form-box {
        margin: 0;
        .title {
            small {
                color: #c1c1c2;
                font-size: 24px;
            }
            a {
                color: #c1c1c2;
                transition: opacity .2s linear;
                &:hover {
                    text-decoration: none;
                    opacity: .8;
                    filter: alpha(opacity=80);
                    transition: opacity .2s linear;
                }
            }
        }
        form {
            margin: 0;
        }
    }
    .rotation {
        position: relative;
    }
    .back-end,
    .front-end {
        display: table;
        table-layout: fixed;
        width: 100%;
    }
    .form-content {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }
    .back-end {
        left: 0;
        position: absolute;
        top: 0;
    }
}


/******************************************************************************
My Account
*******************************************************************************/

.my-account {
    &.margin-top {
        margin-top: -16px;
    }
    .subtitle {
        color: #7f7f7f;
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    .info-box {
        margin-bottom: 40px;
    }
    .buttons-box .required {
        float: right;
        margin: 12px 0 0 10px;
    }
    .pagination-text {
        color: #999;
        font-size: 12px;
    }
    .btn.margin-top {
        margin-top: 10px;
    }
}


/******************************************************************************
Compare Product
*******************************************************************************/

#compare-table {
    border: 0 none;
    margin: 0;
    tr {
        td:last-child,
        th:last-child {
            border-right-width: 1px;
        }
    }
    .remove td {
        border-width: 0;
        height: 26px;
        padding: 0;
        &:last-child {
            border-right-width: 0;
        }
    }
    .remove-bottom td {
        border-top-width: 1px;
        vertical-align: bottom;
    }
    td,
    th {
        vertical-align: top;
    }
    th {
        background: #f7f7f7;
    }
    td {
        font-size: 12px;
        line-height: 20px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .first {
        font-size: 13px;
        min-width: inherit;
        padding: 19px 24px;
        text-align: left;
        width: 190px;
    }
    .product-name {
        color: #1e1e1e;
        text-decoration: none;
        transition: opacity .2s linear;
        &:hover {
            opacity: .8;
            filter: alpha(opacity=80);
            transition: opacity .2s linear;
        }
    }
    .product-image {
        background: #f2f2f2;
        border-radius: 3px;
        display: block;
        margin: 0 auto;
        overflow: hidden;
        width: 100px;
    }
    .price-old {
        font-size: 11px;
        margin: 0 0 3px;
        text-decoration: line-through;
        &:after {
            display: none;
        }
    }
    .price {
        font-size: 16px;
    }
    .rating-box {
        margin: 0 auto;
    }
    .description td {
        text-align: left;
    }
}


/******************************************************************************
About Us
*******************************************************************************/

.employee {
    border-radius: 3px;
    display: inline-block;
    float: none;
    margin: 0 0 40px;
    min-width: 260px;
    overflow: hidden;
    position: relative;
    text-align: left;
    vertical-align: top;
}

.carousel .employee {
    margin-top: 40px;
}

.employee {
    .image {
        background: #f2f2f2;
        display: block;
        line-height: 0;
        margin: 0;
        >img {
            width: 100%;
            transition: opacity .2s linear;
        }
    }
    .description {
        background: #f2f2f2;
        border-top-width: 1px;
        color: #505050;
        display: table;
        font-size: 13px;
        height: 72px;
        line-height: 18px;
        text-align: center;
        width: 100%;
        .vertical {
            display: table-cell;
            height: 72px;
            padding-left: 5px;
            padding-right: 5px;
            vertical-align: middle;
        }
    }
    .name {
        color: #1e1e1e;
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        margin: 0 0 4px;
    }
    .role {
        font-size: 11px;
    }
    .employee-hover {
        background: #1e1e1e;
        background: rgba(30, 30, 30, 0.9);
        border-radius: 3px;
        box-sizing: border-box;
        bottom: 0;
        color: #7f7f7f;
        display: none;
        font-size: 12px;
        line-height: 20px;
        left: 15px;
        padding: 16px 20px 90px;
        position: absolute;
        right: 15px;
        top: 0;
        .name {
            color: white;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.4;
            margin: 0 0 7px;
        }
        .role {
            line-height: 1;
            margin: 0 0 24px;
        }
        .contact {
            color: white;
        }
        .image {
            background: #f7f7f7;
            border-radius: 3px;
            display: block;
            height: 60px;
            float: right;
            line-height: 0;
            margin: 4px 0 0 5px;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 60px;
            z-index: 1;
        }
        .social {
            bottom: 20px;
            left: 20px;
            position: absolute;
            text-align: center;
        }
    }
}

.rotation {
    .employee-hover {
        display: block;
        height: inherit;
        transform: rotateY(-180deg);
        -o-transition: all .6s ease-in-out;
        -ms-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
        z-index: 13;
    }
    &.hover .employee-hover {
        -ms-transition-delay: .2s;
        -o-transition-delay: .2s;
        transform: rotateY(0deg);
        transition-delay: .2s;
        z-index: 46;
    }
}

.employee.employee-single {
    margin: 0;
    max-width: inherit;
    width: auto;
}

.employee-single {
    .images-box {
        .carousel-box {
            overflow: hidden;
        }
        .image {
            box-sizing: border-box;
            float: left;
            margin: 0 0 20px;
            text-align: center;
        }
        img {
            border-radius: 5px;
        }
        .pagination {
            margin-top: 0;
        }
        .carousel-box.load {
            max-height: 390px;
            overflow: hidden;
        }
    }
    .employee-description {
        .name {
            font-size: 22px;
            line-height: 1.3;
            margin: 0 0 5px;
        }
        .role {
            font-size: 14px;
            margin: 0 0 22px;
        }
        .social {
            margin: 22px 0 0;
        }
    }
}

.employee .employee-hover .social .item {
    background: white;
    border-radius: 3px;
    float: left;
    margin: 0 10px 0 0;
    .sbtnf {
        float: none;
        margin: 0;
        overflow: inherit;
    }
}

.employee-single-rotation .content-box {
    padding-left: 15px;
    padding-right: 15px;
}


/******************************************************************************
Contact Us
*******************************************************************************/

.contact-info {
    margin-top: 30px;
    .title {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.3;
        margin: 0 0 22px;
    }
    address {
        margin-top: 5px;
        margin-bottom: 35px;
    }
    hr {
        margin: 0 0 36px;
    }
}

.contact-form .buttons-box {
    margin: 8px 0 0;
}

.map-box {
    position: relative;
    .contact-info {
        background: white;
        margin: 0;
        padding: 5px 10px;
        position: absolute;
        top: 150px;
        z-index: 1;
        address {
            margin-bottom: 15px;
        }
    }
}


/******************************************************************************
Blog
*******************************************************************************/

.post {
    margin-bottom: 40px;
    padding: 0;
}

.blog .post {
    margin-top: -3px;
}

.post {
    +.post {
        border-top-width: 1px;
        padding-top: 37px;
    }
    .entry-title {
        font-size: 26px;
        font-weight: normal;
        margin: 0 0 17px;
        a {
            color: #1e1e1e;
            text-decoration: none;
            transition: opacity .2s linear;
            &:hover {
                text-decoration: none;
                opacity: .8;
                filter: alpha(opacity=80);
                transition: opacity .2s linear;
            }
        }
    }
    .entry-content {
        margin: 0 0 25px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .entry-meta {
        color: #7f7f7f;
        font-size: 12px;
        line-height: 1.3;
        .separator {
            margin: 0 10px;
        }
        .comments-link {
            background: url("../img/comment-link.png") 0 50% no-repeat;
            padding: 0 0 0 18px;
            transition: opacity .2s linear;
            &:hover {
                opacity: .8;
                filter: alpha(opacity=80);
                transition: opacity .2s linear;
            }
            a {
                color: #7f7f7f;
                text-decoration: none;
            }
        }
    }
}

.blog-post {
    .post {
        margin-bottom: 76px;
    }
    .commentlist {
        margin: 0 0 54px;
        padding: 0;
        li {
            padding: 22px 0;
            &:first-child {
                padding-top: 10px;
            }
        }
    }
}

.commentlist li .avatar {
    border-radius: 42px;
    float: left;
    height: 84px;
    margin: 0 25px 0 0;
    width: 84px;
}

.blog-post {
    .commentlist li {
        .meta {
            margin: 0 0 10px;
        }
        .description {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            overflow: hidden;
        }
    }
    .comments-form {
        margin: 6px 0 0;
        .comment-box {
            margin: 0;
            text-align: right;
            textarea {
                margin: 0 0 15px;
            }
            i {
                color: #7f7f7f;
                font-size: 11px;
                line-height: 12px;
                vertical-align: top;
            }
        }
        .button-set {
            margin: 19px 0 0;
            .required {
                font-size: 11px;
                line-height: 40px;
                b {
                    font-size: 14px;
                }
            }
        }
    }
}

.carousel .post {
    border: 0 none;
    float: left;
    line-height: 22px;
    margin: 0 15px;
    padding: 0;
}


/******************************************************************************
Search Result
*******************************************************************************/

.search-result {
    .search-form {
        margin-bottom: 40px;
        position: relative;
        .search-string {
            box-sizing: border-box;
            box-shadow: none;
            color: #1e1e1e;
            font-size: 24px;
            height: 60px;
            line-height: 30px;
            margin: 0;
            padding: 14px 50px 14px 18px;
            &:focus {
                border-color: #ccc;
            }
        }
        .search-submit {
            background: none;
            border-left: 1px solid #e1e1e1;
            height: 30px;
            line-height: 1;
            position: absolute;
            right: 0;
            top: 15px;
            text-align: center;
            width: 46px;
            transition: opacity .2s linear;
            svg {
                margin-top: 2px;
            }
            &:hover {
                opacity: .5;
                filter: alpha(opacity=50);
                transition: opacity .2s linear;
            }
        }
    }
    .filter-buttons {
        margin-bottom: 30px;
    }
    .text-results {
        margin-bottom: 30px;
        color: #999;
        font-size: 12px;
        padding-top: 9px;
        padding-bottom: 10px;
        text-align: right;
    }
    .item {
        background: none;
        border: 0 none;
        border-top: 1px solid #e1e1e1;
        margin-top: 0 -15px 40px;
        padding: 37px 0 0;
    }
    .pagination {
        margin-top: 0;
    }
}


/******************************************************************************
Posts Variants
*******************************************************************************/

.post.carousel {
    line-height: 22px;
    .images-box {
        overflow: hidden;
        iframe,
        img {
            border-radius: 5px;
        }
    }
    .carousel-box {
        &.load {
            max-height: 300px;
        }
        margin-bottom: 42px;
        position: relative;
        &.no-pagination {
            margin-bottom: 8px;
        }
        .next,
        .prev {
            left: 0;
            margin: -20px 5px 0;
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            top: 50%;
            transition: opacity .2s linear;
        }
        &:hover {
            .next,
            .prev {
                opacity: 1;
                filter: alpha(opacity=100);
                transition: opacity .2s linear, background-color .2s linear;
            }
            .next:hover,
            .prev:hover {
                transition: background-color .2s linear;
            }
        }
        .next {
            left: auto;
            right: 0;
        }
        .pagination {
            left: 0;
            position: absolute;
            right: 0;
        }
    }
}


/******************************************************************************
Sitemap
*******************************************************************************/

.sitemap {
    list-style: none;
    margin: 0;
    padding: 0;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin: 0 0 0 15px;
    }
    li {
        background: url("../img/point.png") right 13px repeat-x white;
    }
    a {
        background: white;
        color: #1e1e1e;
        font-size: 12px;
        line-height: 1;
        padding: 0 5px 0 12px;
        position: relative;
        text-decoration: none;
        transition: opacity .2s linear;
        &:hover {
            opacity: .8;
            filter: alpha(opacity=80);
            transition: opacity .2s linear;
        }
        &:before {
            content: "\f105";
            display: block;
            font-family: FontAwesome;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 12px;
            left: 0;
            position: absolute;
            top: 2px;
        }
    }
}


/******************************************************************************
404
*******************************************************************************/

.box-404 {
    border-radius: 177px;
    font-weight: normal;
    height: 354px;
    margin: 0 auto;
    padding: 92px 0 0;
    text-align: center;
    width: 354px;
    h1 {
        font-size: 150px;
        font-weight: normal;
        line-height: .9;
        margin: 0;
    }
    h2 {
        color: #7f7f7f;
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
    }
}


/******************************************************************************
FAQ
*******************************************************************************/

.filter-elements-wrapper {
    margin: 0 auto;
}

.filter-buttons {
    display: block;
    margin-bottom: 30px;
    .dropdown-toggle {
        background: transparent;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        color: #1e1e1e;
        display: block;
        float: left;
        font-weight: bold;
        height: 38px;
        line-height: 38px;
        margin-right: 20px;
        padding: 0 10px 0 20px;
        transition: opacity .2s ease-out;
        .caret {
            margin: -2px 0 0 5px;
        }
        &:hover {
            background: transparent;
            text-decoration: none;
            opacity: .7;
            filter: alpha(opacity=70);
            transition: opacity .2s ease-out;
        }
    }
    &.open .dropdown-toggle {
        background: transparent;
    }
    .dropdown-menu {
        border: 1px solid #e1e1e1;
        .active {
            background-color: #f5f5f5;
        }
    }
    &.white .dropdown-toggle {
        color: white;
        .caret {
            color: white;
        }
    }
}


/******************************************************************************
Portfolio
*******************************************************************************/

.isotope {
    -ms-transition-duration: .8s;
    -o-transition-duration: .8s;
    transition-duration: .8s;
    .isotope-item {
        -ms-transition-duration: .8s;
        -o-transition-duration: .8s;
        transition-duration: .8s;
        -ms-transition-property: -ms-transform, opacity;
        -o-transition-property: -o-transform, opacity;
        transition-property: transform, opacity;
    }
    -ms-transition-property: height,
    width;
    -o-transition-property: height,
    width;
    transition-property: height,
    width;
    &.no-transition {
        -ms-transition-duration: 0s;
        -o-transition-duration: 0s;
        transition-duration: 0s;
        .isotope-item {
            -ms-transition-duration: 0s;
            -o-transition-duration: 0s;
            transition-duration: 0s;
        }
    }
    .isotope-item.no-transition {
        -ms-transition-duration: 0s;
        -o-transition-duration: 0s;
        transition-duration: 0s;
    }
}

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.year-regulator {
    padding-top: 13px;
    .label {
        color: inherit;
        display: table-cell;
        font-size: 12px;
        line-height: 14px;
        padding: 0 20px 0 0;
        text-align: left;
        vertical-align: middle;
    }
    .layout-slider {
        display: table-cell;
        width: 100%;
        input[type="slider"] {
            visibility: hidden;
        }
    }
}

.portfolio {
    .filter-elements {
        overflow: visible !important;
    }
    .work {
        margin-bottom: 40px;
    }
}

.work {
    border-radius: 5px;
    color: white;
    display: block;
    position: relative;
    text-align: center;
    text-shadow: 0 1px #000;
    &:visited {
        border-radius: 5px;
        color: white;
        display: block;
        position: relative;
        text-align: center;
        text-shadow: 0 1px #000;
    }
    &:hover {
        display: block;
        text-decoration: none;
    }
}

.carousel .work {
    margin: 2px 1px;
}

.work {
    img {
        background-color: #555;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        text-align: center;
        width: 100%;
    }
    .shadow {
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
        border-radius: 5px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .work-title {
        bottom: 37px;
        left: 0;
        margin: 0 0 -17px;
        padding: 0 5px;
        position: absolute;
        right: 0;
        text-align: center;
        transition: bottom .2s ease-in;
    }
    .title {
        bottom: 46px;
        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
        margin: 0 0 6px;
        text-align: center;
    }
    .description {
        font-size: 11px;
        font-style: italic;
        font-family: Georgia;
        line-height: 16px;
        text-align: center;
    }
    .bg-hover {
        background: white;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        bottom: 0;
        left: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        right: 0;
        top: 0;
        transition: all .2s ease-out;
    }
    &:hover {
        text-shadow: none;
        .work-title {
            bottom: 46%;
            color: #1e1e1e;
            transition: bottom .2s ease-in;
        }
        .bg-hover {
            opacity: 1;
            filter: alpha(opacity=100);
            transition: all .2s ease-in;
        }
    }
}

.work-single {
    .carousel-box {
        overflow: hidden;
        .carousel img {
            border-radius: 5px;
        }
        &.load {
            max-height: 270px;
        }
    }
    .title {
        font-size: 22px;
        font-weight: normal;
        margin: 0 0 5px;
    }
    .type {
        color: #7f7f7f;
        font-size: 14px;
        margin: 0 0 22px;
    }
}


/* Portfolio One */

.work-one {
    color: #7f7f7f;
    max-width: 100%;
    overflow: hidden;
    +.work-one {
        margin-top: 40px;
    }
    .title {
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        margin: 0 0 7px;
        a {
            color: #1e1e1e;
            transition: opacity .2s linear;
            &:hover {
                opacity: .8;
                filter: alpha(opacity=80);
                text-decoration: none;
                transition: opacity .2s linear;
            }
        }
    }
    .description {
        font-size: 11px;
        line-height: 14px;
        margin: 0 0 27px;
    }
    .work-image {
        border-radius: 5px;
        display: block;
        line-height: 0;
        overflow: hidden;
        img {
            -ms-transition: all 1s ease;
            transition: all 1s ease;
            &:hover {}
        }
    }
}


/******************************************************************************
Galery
*******************************************************************************/

.gallery .images-box {
    margin-bottom: 30px;
}

.gallery-images {
    border-radius: 5px;
    display: block;
    line-height: 0;
    max-width: 100%;
    position: relative;
    img {
        border-radius: 5px;
    }
    .bg-images {
        border-radius: 4px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .2s ease-out;
    }
    &:hover>.bg-images {
        background: white;
        background: rgba(255, 255, 255, 0.7);
        transition: all .2s ease-out;
    }
    .bg-images i {
        margin: 5px 0 0 5px;
        &:before {
            color: white;
            font-size: 20px;
            transition: all .2s ease-out;
        }
    }
    &:hover .bg-images i:before {
        color: #000;
        font-size: 30px;
        transition: all .2s ease-out;
    }
}


/******************************************************************************
Respond
*******************************************************************************/

.respond {
    background: none;
    border: 0 none;
    .description {
        background: white;
        border-radius: 5px;
        color: #1e1e1e;
        font-style: italic;
        font-size: 90%;
        line-height: 1.4;
        margin-bottom: 10px;
        padding: 20px;
        position: relative;
    }
    &.bg .description {
        background-color: #a5e573;
    }
    &.border .description {
        border-color: #a5e573;
        border-width: 1px;
    }
    &.bg .description:before {
        border: 10px solid transparent;
        border-top-color: #a5e573;
        bottom: -20px;
        content: "";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
    }
    &.border .description {
        &:before {
            border: 10px solid transparent;
            border-top-color: #a5e573;
            bottom: -20px;
            content: "";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
        }
        &:after {
            border: 10px solid transparent;
            border-top-color: white;
            bottom: -18px;
            content: "";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
        }
    }
    .name {
        color: #7f7f7f;
        min-height: 22px;
        padding: 5px;
    }
    &.white .name {
        color: white;
    }
    .name .icon {
        color: #7f7f7f;
        font-size: 30px;
        margin: -5px 0 0;
    }
    &.white .name .icon {
        color: white;
    }
}

.respond-carousel {
    overflow: hidden;
    .pagination {
        margin: 0;
    }
}

.respond {
    &.respond-blockquote {
        blockquote {
            border: none;
            margin: 0;
            padding-left: 30px;
            &:before {
                content: open-quote;
                font-size: 50px;
                font-weight: bold;
                left: 16px;
                line-height: 1;
                position: absolute;
                top: 14px;
            }
            &:after {
                content: close-quote;
                display: inline-block;
                font-size: 50px;
                font-weight: bold;
                line-height: 0;
                margin: 0 0 -5px;
                vertical-align: bottom;
            }
        }
        &.border blockquote {
            &:after,
            &:before {
                color: #a5e573;
            }
        }
        .name {
            .icon {
                float: left;
                height: 50px;
                margin: 0 10px 0 0;
                width: 50px;
            }
            strong {
                font-size: 120%;
            }
        }
    }
    .description {
        &.bg-danger,
        &.bg-info,
        &.bg-primary,
        &.bg-success,
        &.bg-warning {
            color: white;
        }
        &.bg-info:before,
        &.border-info:before {
            border-top-color: #0098ca;
        }
        &.bg-primary:before,
        &.border-primary:before {
            border-top-color: #037ac5;
        }
        &.bg-success:before,
        &.border-success:before {
            border-top-color: #738d00;
        }
        &.bg-warning:before,
        &.border-warning:before {
            border-top-color: #f89406;
        }
        &.bg-danger:before,
        &.border-danger:before {
            border-top-color: #c10841;
        }
        &.bg-white:before,
        &.border-white:before {
            border-top-color: white;
        }
    }
    &.border .description.border-white {
        border-color: white;
    }
    &.respond-blockquote.border {
        .border-info blockquote {
            &:after,
            &:before {
                color: #0098ca;
            }
        }
        .border-primary blockquote {
            &:after,
            &:before {
                color: #037ac5;
            }
        }
        .border-success blockquote {
            &:after,
            &:before {
                color: #738d00;
            }
        }
        .border-warning blockquote {
            &:after,
            &:before {
                color: #f89406;
            }
        }
        .border-danger blockquote {
            &:after,
            &:before {
                color: #c10841;
            }
        }
        .border-white blockquote {
            &:after,
            &:before {
                color: #1e1e1e;
            }
        }
    }
}


/******************************************************************************
Chart
*******************************************************************************/

.chart {
    position: relative;
    text-align: center;
}

.jqstooltip {
    box-sizing: content-box;
}

.graph text {
    overflow: hidden !important;
}

.morris-hover {
    z-index: 700;
}


/******************************************************************************
Livicons
*******************************************************************************/

.livicon {
    display: inline-block;
    line-height: inherit;
    vertical-align: middle;
    svg {
        vertical-align: top;
    }
}

.big-icon .livicon svg,
.icon .livicon svg {
    top: 25% !important;
}

.livicon.metro-bg {
    box-sizing: content-box;
    margin-bottom: 4px;
    padding: 26px 25px 26px 27px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}


/******************************************************************************
Steps
*******************************************************************************/

.steps {
    display: table;
    table-layout: fixed;
    width: 100%;
    .step {
        background: white;
        border: 1px solid #e1e1e1;
        display: table-cell;
        padding: 15px;
        position: relative;
        vertical-align: middle;
        .step-wrapper {
            position: relative;
        }
        +.step {
            border-left: 0 none;
        }
        &:first-child {
            border-radius: 3px 0 0 3px;
        }
        &:last-child {
            border-radius: 0 3px 3px 0;
        }
        .step-wrapper {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: -29px;
                border: 7px solid transparent;
                border-left-color: #e1e1e1;
                z-index: 1;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: -29px;
                border: 7px solid transparent;
                border-left-color: #e1e1e1;
                z-index: 1;
                border-left-color: white;
                right: -27px;
                z-index: 2;
            }
        }
        &:last-child .step-wrapper {
            &:after,
            &:before {
                display: none;
            }
        }
        .bg-image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            bottom: 0;
            left: 0;
            opacity: .1;
            filter: alpha(opacity=10);
            position: absolute;
            right: 0;
            top: 0;
            ~* {
                position: relative;
            }
        }
        &.border-warning .step-wrapper:after {
            border-left-color: #f89406;
        }
        &.border-error .step-wrapper:after {
            border-left-color: #c10841;
        }
        &.border-info .step-wrapper:after {
            border-left-color: #0098ca;
        }
        &.border-success .step-wrapper:after {
            border-left-color: #738d00;
        }
        &.border-grey .step-wrapper:after {
            border-left-color: #777;
        }
        &.bg-warning .step-wrapper:before {
            border-left-color: #f89406;
        }
        &.bg-error .step-wrapper:before {
            border-left-color: #c10841;
        }
        &.bg-info .step-wrapper:before {
            border-left-color: #0098ca;
        }
        &.bg-success .step-wrapper:before {
            border-left-color: #738d00;
        }
        &.bg-grey {
            background: #777;
            .step-wrapper:before {
                border-left-color: #777;
            }
        }
    }
    &.steps-apart {
        display: block;
        width: auto;
        .step {
            border-radius: 3px;
            display: block;
            &:last-child .step-wrapper {
                &:after,
                &:before {
                    display: block;
                }
            }
        }
        >[class*="col-md"]:last-child>.step .step-wrapper {
            &:after,
            &:before {
                display: none;
            }
        }
    }
    &.progress-steps {
        display: block;
        .step {
            background: none !important;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            display: block;
            margin: 0 0 40px;
            min-height: 185px;
            padding: 0;
            .step-wrapper {
                padding: 20px 20px 20px 260px;
                position: static;
            }
            &.step-right .step-wrapper {
                padding: 20px 260px 20px 20px;
            }
            .number {
                background: #e1e1e1;
                bottom: 0;
                color: white;
                font-size: 150px;
                left: 0;
                line-height: 1.2;
                position: absolute;
                text-align: center;
                top: 0;
                width: 240px;
            }
            &.step-right .number {
                left: auto;
                right: 0;
            }
            .step-wrapper {
                &:after,
                &:before {
                    display: none;
                }
            }
            &:last-child .number {
                &:after,
                &:before {
                    display: none;
                }
            }
            .number {
                &:after {
                    border: 7px solid transparent;
                    border-top-color: #e1e1e1;
                    bottom: -15px;
                    content: "";
                    left: 50%;
                    margin-left: -7px;
                    position: absolute;
                    z-index: 1;
                }
                &:before {
                    border: 7px solid transparent;
                    border-top-color: #e1e1e1;
                    bottom: -15px;
                    content: "";
                    left: 50%;
                    margin-left: -7px;
                    position: absolute;
                    z-index: 1;
                    bottom: -13px;
                    z-index: 2;
                }
            }
            &.border-warning .number:after {
                border-top-color: #f89406;
            }
            &.border-error .number:after {
                border-top-color: #c10841;
            }
            &.border-info .number:after {
                border-top-color: #0098ca;
            }
            &.border-success .number:after {
                border-top-color: #738d00;
            }
            &.bg-warning .number {
                background: #f89406;
                &:before {
                    border-top-color: #f89406;
                }
            }
            &.bg-error .number {
                background: #c10841;
                &:before {
                    border-top-color: #c10841;
                }
            }
            &.bg-info .number {
                background: #0098ca;
                &:before {
                    border-top-color: #0098ca;
                }
            }
            &.bg-success .number {
                background: #738d00;
                &:before {
                    border-top-color: #738d00;
                }
            }
            &.border-warning .number {
                color: #f89406;
            }
            &.border-error .number {
                color: #c10841;
            }
            &.border-info .number {
                color: #0098ca;
            }
            &.border-success .number {
                color: #738d00;
            }
            &.bg-error .number,
            &.bg-info .number,
            &.bg-success .number,
            &.bg-warning .number {
                color: white;
            }
        }
    }
}


/******************************************************************************
Full Width Box
*******************************************************************************/

.full-width-box {
    overflow: hidden;
    padding: 76px 0;
    position: relative;
    transition: opacity .2s linear;
    &.auto-width {
        margin-left: -15px;
        margin-right: -15px;
        opacity: 0;
    }
}

.container .full-width-box {
    margin-left: -15px;
    margin-right: -15px;
}

.full-width-box {
    &.auto-width.loaded {
        margin-left: 0;
        margin-right: 0;
        opacity: 1;
        transition: opacity .2s linear;
    }
    .fwb-bg {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 0;
    }
    .container,
    .page-header {
        position: relative;
    }
    .fwb-bg .overlay {
        background: #000;
        height: 100%;
        opacity: .2;
        filter: alpha(opacity=20);
        width: 100%;
    }
    .fwb-fixed {
        background-attachment: fixed;
    }
}

.touch-device .full-width-box {
    .fwb-fixed,
    .fwb-paralax {
        background-attachment: scroll;
    }
}

.full-width-box {
    .fwb-blur {
        background-position: center top !important;
        .blur-box {
            bottom: 0;
            display: block !important;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -100;
            canvas {
                background-size: cover;
                background-attachment: fixed;
                max-width: inherit;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                vertical-align: top;
                width: 100%;
            }
        }
    }
    .fwb-video {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        iframe,
        img,
        video {
            background-attachment: fixed;
            vertical-align: top;
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: inherit;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
        }
    }
}


/******************************************************************************
Word Rotate
*******************************************************************************/

.word-rotate {
    background: #1e1e1e;
    display: inline-block;
    overflow: hidden;
    padding: 0 8px;
    text-align: center;
    vertical-align: top;
    &:not(.border) {
        color: white;
    }
    .words-box {
        display: inline-block;
        position: relative;
        span {
            display: block;
            white-space: nowrap;
            +span {
                display: none;
            }
        }
    }
    &.loaded .words-box span+span {
        display: block;
    }
    &.border {
        background: none;
        border: 1px solid #1e1e1e;
    }
}


/******************************************************************************
Timeline
*******************************************************************************/

.timeline {
    padding: 20px 0;
    position: relative;
    &:before {
        background-color: #d2d2d2;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        content: "";
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -2px;
        position: absolute;
        top: 0;
        width: 4px;
    }
    .post {
        border: 0 none;
        margin: 0;
        padding: 0 40px 5px 0;
        width: 50%;
        &:last-child,
        &:nth-child(even):last-child {
            padding-bottom: 0;
        }
        .timeline-time {
            color: #999;
            left: 50%;
            margin: 31px 0 0 40px;
            position: absolute;
        }
        .timeline-icon {
            background: #1e1e1e;
            border-radius: 20px;
            color: white;
            font-size: 18px;
            height: 40px;
            left: 50%;
            line-height: 40px;
            margin: 22px 0 0 -20px;
            position: absolute;
            text-align: center;
            width: 40px;
            .livicon svg {
                left: 0 !important;
            }
        }
        .timeline-content {
            background: white;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 46px;
            position: relative;
        }
        &.no-padding .timeline-content {
            padding: 0;
        }
        .timeline-content {
            .video-box:last-child,
            blockquote:last-child {
                margin-bottom: 0;
            }
            .map-canvas {
                height: 250px;
            }
            .img-rounded {
                border-radius: 2px;
            }
            &:before {
                border: 15px solid transparent;
                border-left-color: #ccc;
                content: "";
                display: block;
                position: absolute;
                right: -30px;
                top: 26px;
            }
            &:after {
                border: 15px solid transparent;
                border-left-color: #ccc;
                content: "";
                display: block;
                position: absolute;
                right: -30px;
                top: 26px;
                border: 14px solid transparent;
                border-left-color: white;
                right: -28px;
                top: 27px;
            }
            .entry-title {
                font-size: 18px;
                margin: 0 0 10px;
            }
            .entry-content {
                margin: 0;
            }
        }
        &:nth-child(even) {
            margin-left: 50%;
            padding: 0 0 5px 40px;
            .timeline-time {
                left: auto;
                margin: 31px 40px 0 0;
                right: 50%;
                text-align: right;
            }
            .timeline-content {
                &:before {
                    border: 15px solid transparent;
                    border-right-color: #ccc;
                    left: -30px;
                    right: auto;
                }
                &:after {
                    border: 14px solid transparent;
                    border-right-color: white;
                    left: -28px;
                    right: auto;
                }
            }
        }
        .timeline-content.bg {
            background: #ccc;
        }
        &:not(.no-padding) .timeline-content.bg:after {
            display: none;
        }
        .timeline-content {
            &.bg {
                color: white;
                .entry-title a {
                    color: white;
                }
                blockquote {
                    .small,
                    footer,
                    small {
                        color: white;
                    }
                }
            }
            &.bg-danger,
            &.border-danger {
                border-color: #c10841;
            }
            &.bg-danger:before,
            &.border-danger:before {
                border-left-color: #c10841;
            }
        }
        &:nth-child(even) .timeline-content {
            &.bg-danger:before,
            &.border-danger:before {
                border: 15px solid transparent;
                border-right-color: #c10841;
            }
        }
        .timeline-content {
            &.bg-warning,
            &.border-warning {
                border-color: #f89406;
            }
            &.bg-warning:before,
            &.border-warning:before {
                border-left-color: #f89406;
            }
        }
        &:nth-child(even) .timeline-content {
            &.bg-warning:before,
            &.border-warning:before {
                border: 15px solid transparent;
                border-right-color: #f89406;
            }
        }
        .timeline-content {
            &.bg-info,
            &.border-info {
                border-color: #0098ca;
            }
            &.bg-info:before,
            &.border-info:before {
                border-left-color: #0098ca;
            }
        }
        &:nth-child(even) .timeline-content {
            &.bg-info:before,
            &.border-info:before {
                border: 15px solid transparent;
                border-right-color: #0098ca;
            }
        }
        .timeline-content {
            &.bg-success,
            &.border-success {
                border-color: #738d00;
            }
            &.bg-success:before,
            &.border-success:before {
                border-left-color: #738d00;
            }
        }
        &:nth-child(even) .timeline-content {
            &.bg-success:before,
            &.border-success:before {
                border: 15px solid transparent;
                border-right-color: #738d00;
            }
        }
        .timeline-content {
            &.bg-primary,
            &.border-primary {
                border-color: #428bca;
            }
            &.bg-primary:before,
            &.border-primary:before {
                border-left-color: #428bca;
            }
        }
        &:nth-child(even) .timeline-content {
            &.bg-primary:before,
            &.border-primary:before {
                border: 15px solid transparent;
                border-right-color: #428bca;
            }
        }
    }
}


/******************************************************************************
Social Feed
*******************************************************************************/

.social-feed {
    background-image: url("../img/svg/loader.svg"), none;
    background-repeat: no-repeat;
    background-position: 50% 100px;
    background-size: 40px;
    >* {
        opacity: 0;
        transition: opacity .2s linear;
    }
    &.loaded {
        background: none;
        >* {
            opacity: 1;
            transition: opacity .2s linear;
        }
    }
    .isotope-item {
        margin-bottom: 20px;
    }
    .post {
        background: white;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin: 0;
        padding: 19px;
    }
    .add-post {
        background: none;
        border: 0 none;
        padding: 0;
        .nav-tabs {
            border: 0 none;
            >li {
                >a {
                    border-radius: 3px 3px 0 0;
                    padding: 6px 10px 7px;
                    .fa {
                        color: #7f7f7f;
                    }
                }
                &.active>a {
                    font-weight: normal;
                    &:focus,
                    &:hover {
                        font-weight: normal;
                    }
                    .fa {
                        color: #555;
                    }
                }
            }
        }
        .tab-content {
            background: white;
            border: 1px solid #ddd;
            border-radius: 3px;
            padding: 20px;
            .tab-pane {
                padding: 0;
                form {
                    margin: 0;
                }
            }
        }
        .tab-pane input {
            margin-bottom: 22px;
        }
    }
    .author-info {
        .avatar {
            border-radius: 50%;
            float: left;
            height: 50px;
            margin: 0 20px 0 0;
            overflow: hidden;
            width: 50px;
        }
        .user-name {
            font-size: 16px;
            a {
                color: #1e1e1e;
                text-decoration: none;
                transition: opacity .2s linear;
                &:hover {
                    opacity: .8;
                    filter: alpha(opacity=80);
                    transition: opacity .2s linear;
                }
            }
        }
        time {
            color: #7f7f7f;
            font-style: italic;
        }
    }
    .preview {
        margin: 0 -20px 20px;
        text-align: center;
        img {
            display: block;
            margin: 0 auto;
        }
    }
    .buttons {
        margin-bottom: 20px;
        text-align: right;
        .btn {
            background: none;
            border: 1px solid rgba(0, 0, 0, 0.5);
            color: rgba(0, 0, 0, 0.5);
            transition: opacity .2s linear;
            &:hover {
                opacity: .8;
                filter: alpha(opacity=80);
                transition: opacity .2s linear;
            }
        }
    }
    .comments {
        background: rgba(0, 0, 0, 0.1);
        margin: 0 -20px -20px;
        padding: 20px;
        .comment {
            margin: 0 0 25px;
            .avatar {
                border-radius: 50%;
                float: left;
                height: 40px;
                margin: 0 10px 5px 0;
                overflow: hidden;
                width: 40px;
            }
            .author-details {
                margin-bottom: 5px;
            }
            .user-name a {
                color: #1e1e1e;
                text-decoration: none;
                transition: opacity .2s linear;
                &:hover {
                    opacity: .8;
                    filter: alpha(opacity=80);
                    transition: opacity .2s linear;
                }
            }
            time {
                color: #7f7f7f;
                font-size: 12px;
                font-style: italic;
            }
        }
        .form {
            margin: 0;
            .form-control {
                height: 62px;
                margin: 0;
            }
            textarea {
                resize: none;
            }
        }
    }
    .post {
        &.bg {
            background: #ccc;
            color: white;
        }
        &.bg-danger {
            border-color: #c10841;
        }
        &.bg-warning {
            border-color: #f89406;
        }
        &.bg-info {
            border-color: #0098ca;
        }
        &.bg-success {
            border-color: #738d00;
        }
        &.bg-primary {
            border-color: #428bca;
        }
    }
    .author-info {
        margin-bottom: 15px;
    }
    .post.number {
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        text-align: center;
        .post-icon {
            font-size: 24px;
            left: 10px;
            opacity: .8;
            filter: alpha(opacity=80);
            position: absolute;
            top: 10px;
        }
        .social-data {
            display: inline-block;
            font-style: italic;
            line-height: 1;
            vertical-align: middle;
            .count {
                font-size: 60px;
                font-style: normal;
            }
        }
    }
    .bg {
        .author-info .user-name a,
        .comments .comment .user-name a {
            color: white;
        }
        .author-info time,
        .comments .comment time {
            color: rgba(255, 255, 255, 0.8);
        }
        .buttons .btn {
            background: white;
            border-color: white;
            color: #1e1e1e;
        }
    }
    .bg-danger .buttons .btn {
        color: #c10841;
    }
    .bg-warning .buttons .btn {
        color: #f89406;
    }
    .bg-info .buttons .btn {
        color: #0098ca;
    }
    .bg-success .buttons .btn {
        color: #738d00;
    }
    .bg-primary .buttons .btn {
        color: #428bca;
    }
    .post.number {
        &.twitter {
            background: #00aced;
            border-color: #00aced;
        }
        &.facebook {
            background: #3B5998;
            border-color: #3B5998;
        }
        &.google {
            background: #dd4b39;
            border-color: #dd4b39;
        }
        &.pinterest {
            background: #cb2027;
            border-color: #cb2027;
        }
        &.linkedin {
            background: #007bb6;
            border-color: #007bb6;
        }
        &.youtube {
            background: #bb0000;
            border-color: #bb0000;
        }
    }
}


/******************************************************************************
Blur Pages
*******************************************************************************/

.blur-page {
    background-image: url("../img/svg/loader.svg"), none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40px;
    .page-box {
        opacity: 0;
    }
    .blur-box {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        bottom: 0;
        display: block !important;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        text-align: center;
        z-index: -1;
    }
    &.blur-load {
        background-image: inherit;
        transition: background-image .2s linear;
        .page-box {
            opacity: 1;
            transition: opacity .2s linear;
        }
        .blur-box {
            opacity: 1;
            transition: opacity .2s linear;
            transition: opacity .4s linear;
        }
    }
    .blur-box {
        canvas,
        img {
            background-size: cover;
            left: 0;
            max-width: inherit;
            min-width: 100%;
            min-height: 100%;
            position: fixed;
            top: 0;
            vertical-align: top;
            width: auto;
            z-index: 0;
        }
        img {
            visibility: hidden;
        }
    }
}


/******************************************************************************
Pages 404
*******************************************************************************/

.page-404-promo {
    .page-box {
        background: #2b8cd6;
        background: rgba(43, 140, 214, 0.9);
        display: table;
        height: 100% !important;
        width: 100%;
    }
    #main {
        display: table-cell;
        padding: 20px 0;
        vertical-align: middle;
        .title {
            border-bottom: 1px solid white;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            color: white;
            padding: 0 10px;
            text-align: center;
            h1 {
                display: inline-block;
                font-size: 200px;
                font-weight: normal;
                line-height: 1;
                margin: 0;
                position: relative;
                &:before {
                    background: white;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }
        .content {
            color: white;
            padding: 35px 10px 0;
            text-align: center;
            h2 {
                font-size: 24px;
                font-weight: normal;
                margin-bottom: 38px;
            }
            .back-home {
                color: #2b8cd6;
                font-size: 18px;
                padding-right: 30px;
                padding-left: 20px;
                .fa {
                    font-size: 22px;
                    margin-right: 8px;
                }
            }
        }
    }
}


/******************************************************************************
One Page
*******************************************************************************/

.one-page {
    .header .primary .navbar .nav .active {
        color: #c10841;
    }
    #main {
        padding-top: 0;
        padding-bottom: 0;
    }
    .full-width-box:last-child {
        padding-bottom: 0;
    }
    .page-header {
        margin-bottom: 40px;
    }
}


/******************************************************************************
Other Pages
*******************************************************************************/

.body-bg-img {
    background: url("../img/content/coming-soon.jpg") 50% 0 no-repeat;
    &.under-construction {
        background: url("../img/content/under-construction.jpg") 50% 0 no-repeat;
    }
    .page-box {
        display: table;
        height: 100% !important;
        width: 100%;
    }
    .header-three {
        min-height: 1px;
        position: absolute;
        width: 100%;
    }
    #main {
        display: table-cell;
        padding: 119px 0 192px;
        vertical-align: middle;
    }
}

.services {
    .features-block,
    .service {
        margin-bottom: 40px;
    }
}

.services-box-three-left {
    margin-bottom: 40px;
    padding: 20px;
}

.count-down-box {
    float: right;
}

#count-down {
    text-align: right;
    width: auto !important;
    .county-days-wrapper,
    .county-hours-wrapper,
    .county-minutes-wrapper,
    .county-seconds-wrapper {
        background: #c10841;
        background: rgba(193, 8, 65, 0.5);
        display: inline-block;
        height: 135px;
        margin-left: 2px;
        margin-right: 2px;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 23% !important;
    }
    .county-hours-wrapper {
        background: #f89406;
        background: rgba(248, 148, 6, 0.5);
    }
    .county-minutes-wrapper {
        background: #0098ca;
        background: rgba(0, 152, 202, 0.5);
    }
    .county-seconds-wrapper {
        background: #738d00;
        background: rgba(115, 141, 0, 0.5);
    }
    .county-days,
    .county-hours,
    .county-minutes,
    .county-seconds {
        font-size: 60px;
        color: white;
        line-height: 135px;
        display: inline-block !important;
        opacity: 1 !important;
        width: auto !important;
    }
    .county-label-days,
    .county-label-hours,
    .county-label-minutes,
    .county-label-seconds {
        margin-left: 2px;
        margin-right: 2px;
        padding-top: 10px;
        font-size: 18px;
        display: inline-block;
        text-align: center;
        width: 23% !important;
    }
}

.coming-text {
    margin-top: -4px;
    form {
        position: relative;
    }
    .success {
        bottom: 100%;
        margin-bottom: 5px;
        position: absolute;
    }
    .form-control {
        padding-right: 100px;
    }
    .btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 90px;
    }
}

.under-contact {
    .address,
    .phone {
        box-sizing: border-box;
        padding: 0 0 0 34px;
        position: relative;
    }
    .footer-icon {
        color: #000;
        left: 16px;
        position: absolute;
        top: 0;
        &:before {
            font-size: 20px;
            line-height: 18px;
            width: auto;
        }
    }
}

.list-images>.col-md-4 {
    margin-bottom: 30px;
}


/******************************************************************************
Footer
*******************************************************************************/

#footer {
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    &.footer-two {
        margin: -176px 0 0;
    }
    .footer-top {
        font-size: 12px;
        line-height: 21px;
        padding: 50px 0 13px;
    }
    .sidebar .widget {
        margin-bottom: 30px;
        &:nth-child(4n+1) {
            clear: left;
        }
        .title-block,
        header {
            margin: 0 0 15px;
            padding: 0 0 12px;
        }
        .title-block:before,
        header:before {
            display: none;
        }
        .title-block .title,
        header .title {
            color: #1e1e1e;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.3;
            text-transform: uppercase;
        }
        .next,
        .prev {
            height: 26px;
            line-height: 26px;
            width: 26px;
        }
        .next svg,
        .prev svg {
            height: 14px;
        }
    }
    .widget p {
        margin: 0 0 16px;
    }
    .sidebar {
        .compare-products li {
            background-color: #f2f2f2;
            .close,
            .title {
                background-color: #f2f2f2;
            }
        }
        .wishlist .add-cart {
            background-color: #f2f2f2;
        }
    }
}

.footer-two .social {
    text-align: center;
    .sbtnf {
        display: inline-block;
        float: none;
        margin: 0 3px;
    }
}

#footer {
    .footer-bottom {
        background: #f7f7f7;
        border-top-width: 1px;
        font-size: 11px;
        line-height: 17px;
        padding: 21px 0 20px;
    }
    .address,
    .phone {
        box-sizing: border-box;
        padding-left: 34px;
        position: relative;
    }
    .footer-icon {
        color: #c6c6c6;
        left: 16px;
        position: absolute;
        top: 0;
        &:before {
            font-size: 20px;
            line-height: 18px;
            width: auto;
        }
    }
    .up {
        background: #c6c6c6;
        border-radius: 3px;
        display: block;
        float: right;
        height: 40px;
        text-align: center;
        width: 40px;
        transition: all .2s linear;
        &:hover {
            background: #000;
            transition: all .2s linear;
        }
        .glyphicon {
            color: white;
            margin: 14px 0 0 -.5px;
            vertical-align: top;
        }
    }
}


/******************************************************************************
Boxed
*******************************************************************************/

.boxed {
    background: white;
    margin: 0 auto;
    max-width: 1300px;
    padding-top: 20px;
    .page-box {
        background: white;
        box-shadow: rgba(0, 0, 0, 0.6) 0 0 18px;
        overflow: hidden;
        position: relative;
    }
    &.loaded .page-box {
        overflow: visible;
    }
    &.fixed-header {
        .header {
            margin-top: 0;
            position: absolute;
        }
        &.fixed .header {
            position: fixed;
            top: 0;
        }
        &.hidden-top {
            .page-box {
                padding-top: 0;
                transition: none;
            }
            #top-box {
                left: 0;
                margin-top: 0;
                margin-bottom: 104px;
                position: static;
                transition: none;
            }
            .header {
                top: 40px;
            }
            &.fixed .header {
                top: 0;
            }
        }
    }
    .header-wrapper {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        margin: 0 auto;
        max-width: 1300px;
    }
    .banner-set.banner-set-mini.banner-set-no-pagination {
        .pagination {
            display: inline-block !important;
        }
        .next,
        .prev {
            margin: 15px 0 0 !important;
            position: static;
        }
    }
    #footer {
        min-width: 100%;
        padding-bottom: 20px;
        position: relative;
        &.footer-two {
            margin-bottom: 0;
        }
    }
}


/******************************************************************************
Fancybox
*******************************************************************************/

.fancybox-margin .fixed-header {
    #top-box,
    .header {
        left: -7.5px;
    }
}

.fancybox-close {
    background: white;
    border: 1px solid #7f7f7f;
    border-radius: 50%;
    color: #1e1e1e;
    font-size: 25px;
    font-weight: normal;
    height: 30px;
    line-height: 29px;
    right: -15px;
    text-align: center;
    text-decoration: none;
    top: -15px;
    width: 30px;
    transition: color .2s linear;
    &:hover {
        color: #7f7f7f;
        text-decoration: none;
        transition: color .2s linear;
    }
}

.fancybox-nav span {
    background: #ccc;
    border: 0 none;
    border-radius: 3px;
    color: #fbfbfb;
    display: block;
    float: right;
    height: 40px;
    line-height: 40px;
    margin: -20px 0 0;
    text-align: center;
    text-decoration: none;
    width: 40px;
    transition: all .2s linear;
    &:hover {
        background-color: #000;
        color: #efefef;
        text-decoration: none;
        transition: all .2s linear;
    }
    svg {
        vertical-align: middle;
    }
}

.fontawesome-icon-list {
    color: #7f7f7f;
    .fa {
        color: #1e1e1e;
        width: 22px;
        font-size: 14px;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
    }
}

.full-width-box {
    .fwb-fixed,
    .fwb-paralax {
        background-attachment: fixed;
    }
}

.fixed-header.fixed-top {
    #top-box {
        left: 0;
        margin: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 871;
    }
    .header {
        margin-top: 30px;
    }
}
