@media only screen and (max-width: 767px) {
    .fixed-header.logged-in.admin-menu {
        #top-box {
            margin-top: 0;
            top: 60px;
        }
        .page-box {
            padding-top: 22px;
        }
        #diaporama {
            margin-top: 0;
        }
    }
    .field-name-body .row {
        margin-right: 0;
        margin-left: 0;
    }
    /* Correction bug affichage quand on est connecté */
    .logged-in.admin-menu {
        &.front #header {
            margin-top: 44px;
        }
        #header {
            margin-top: -85px;
        }
    }
    .view-display-id-page_derniers-articles-parus h3 {
        line-height: 10px;
    }
    header {
        .logo {
            img {
                margin-left: 20px;
                width: auto;
                max-height: 65px;
                max-width: 200px;
            }
        }
        .slogan {
            .bulle {
                width: 140px;
                position: absolute;
                top: 66px;
                line-height: 12px;
                right: 20px;
            }
            h1,
            span {
                font-size: 1rem;
                color: grey;
            }
        }
    }
    #progressive_cms-breadcrumbs {
        color: white;
        width: 100%;
        margin-top: 0;
    }
    .logged-in.admin-menu #progressive_cms-breadcrumbs {
        margin-top: 0;
    }
    .breadcrumb-box {
        background: #f2f2f2;
        color: #666;
    }
    .breadcrumb>li {
        color: #666;
        &+li:before {
            color: #666;
        }
        &>a {
            color: #111;
        }
    }
    .not-front .acces-directs {
        margin: 0 -15px -30px;
    }
    .acces-directs {
        margin: 0 -15px;
        li,
        li.active {
            margin: 0 -2px -6px;
            width: 50%;
            height: 160px;
        }
        li {
            a {
                line-height: 1em;
            }
            & a::before,
            &.active a::before {
                line-height: 2em;
            }
        }
    }
    footer {
        .title-box {
            margin-top: 30px;
        }
    }
    .view-diaporama-accueil.container {
        padding-left: 0;
        padding-right: 0;
    }
    /* ANNONCES EMPLOI */
    .page-annonces-emploi {
        .acces-directs {
            margin: 0 -30px -30px;
        }
    }
    .sidebar {
        margin: 20px auto 20px;
    }
}
