.container-fluid>.row>.col-md-12 {
    padding-left: 0;
    padding-right: 0;
}


/* Spacers */

.spacer {
    width: 100%;
    overflow: hidden;
    &.sm {
        height: 10px;
        overflow: hidden;
    }
    &.lg {
        height: 40px;
        overflow: hidden;
    }
    &.xl {
        height: 60px;
        overflow: hidden;
    }
    &.xlg {
        height: 80px;
        overflow: hidden;
    }
}

#overlay-container,
.overlay-element,
.overlay-modal-background {
    z-index: 900;
}

.container .container {
    padding: 0;
    width: auto;
}


/* Main */

body {
    overflow-x: hidden !important;
}

.page-box-content {
    padding-bottom: 0;
}

.nd-region {
    max-width: 100%;
}

body.admin-menu {
    margin-top: 0 !important;
}

div.krumo-element {
    color: #000;
}

.page-header {
    margin: 0;
}

.img-rounded img {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.form-item.error {
    background-image: none;
}

body.boxed {
    padding-bottom: 20px;
}

.boxed.fixed-header {
    .header {
        top: -104px;
    }
    &.hidden-top .header {
        margin-top: 0 !important;
    }
}

div.contextual-links-wrapper {
    z-index: 500;
}


/* Primary Tabs */

.tabs.primary {
    padding-left: 31px;
    margin: 0 0 -1px;
    border-bottom: 1px solid #ddd;
    position: static;
    li {
        >a {
            background: #f7f7f7;
            border-left: 1px solid #f7f7f7;
            border-right: 1px solid #f7f7f7;
            border-bottom: none !important;
            border-radius: 0;
            padding: 12px 13px;
            color: #6d6d6d;
            position: relative;
            line-height: 1.42857143;
            border: 1px solid transparent;
            display: block;
        }
        position: relative;
        display: inline-block;
        bottom: -1px;
        z-index: 50;
        border: 1px solid #ddd;
        margin-right: 20px;
        &.active {
            border-bottom: 0;
            bottom: -2px;
        }
    }
    a:hover {
        text-decoration: none;
    }
}


/* Block Title */

aside>.widget:first-child .title-box .title {
    padding-top: 0;
}

.title-box h4.title {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 25px;
}

.light>* {
    font-weight: normal;
}

.text-right .title-box:before {
    left: auto;
    right: 0;
}

.text-center .title-box:before {
    left: 50%;
    margin-left: -20px;
}

.white {
    .title-box {
        border-color: rgba(255, 255, 255, 0.3);
        .title {
            color: #fff;
        }
    }
    strong {
        color: #fff;
    }
    .title-box:before {
        background: #fff;
    }
}

aside {
    .title-box {
        h3.title {
            padding-top: 80px;
        }
        h4.title {
            padding-top: 20px;
            color: #000;
            margin-bottom: 15px;
            font-weight: bold;
            border-top: 1px solid #e1e1e1;
            margin-top: 20px;
        }
        &.title-h4 {
            margin-bottom: 0;
            border: none;
            &:before {
                content: none;
            }
        }
    }
    .title-block .title,
    header .title {
        color: #7f7f7f;
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
    }
}

.product-bottom .related-products header h3 {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
}


/* Overlay */

.full-width-box {
    .overlay {
        background: #000;
        height: 100%;
        opacity: .2;
        filter: alpha(opacity=20);
        width: 100%;
        top: 0;
        position: absolute;
    }
    padding: 0;
}


/* Employee */

.employee {
    margin-right: -3px;
}

.carousel {
    .employee,
    .product.rotation {
        margin-top: 0;
    }
}


/* Service Box */

.big-services-box a {
    color: #fff;
}

.features-block,
.service {
    margin-bottom: 40px;
}

.text-small.features-block a:not(.btn) {
    display: block;
}


/* Partners */

.manufactures-list {
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    img {
        width: 100%;
    }
}


/* Footer */

footer {
    aside p {
        margin: 0 0 16px !important;
    }
    &.footer-top {
        background: #f2f2f2;
        font-size: 12px;
        line-height: 21px;
        padding: 50px 0 13px;
    }
}

.sidebar .widget ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.widget ul.row {
    margin-left: -15px;
    margin-right: -15px;
}

footer aside {
    li {
        line-height: 21px;
        a {
            color: #7f7f7f;
            text-decoration: none;
            -webkit-transition: color .2s linear;
            transition: color .2s linear;
            &:visited {
                color: #7f7f7f;
                text-decoration: none;
                -webkit-transition: color .2s linear;
                transition: color .2s linear;
            }
            &:hover {
                color: #000;
                -webkit-transition: color .2s linear;
                transition: color .2s linear;
            }
        }
    }
    header {
        margin: 0 0 15px;
        padding: 0 0 12px;
    }
    .title-box {
        margin: 0 0 15px;
        padding: 0 0 12px;
        &:before {
            display: none;
        }
    }
    .title-box .title,
    header .title {
        color: #1e1e1e;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.3;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
    }
}

.footer-bottom {
    background: #f7f7f7;
    border-top-width: 1px;
    font-size: 11px;
    line-height: 17px;
    padding: 21px 0 20px;
    .address,
    .phone {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 34px;
        position: relative;
    }
    .footer-icon {
        color: #c6c6c6;
        left: 16px;
        position: absolute;
        top: 0;
        &:before {
            font-size: 20px;
            line-height: 18px;
            width: auto;
        }
    }
    .up {
        background: #c6c6c6;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        display: block;
        float: right;
        height: 40px;
        text-align: center;
        width: 40px;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        &:hover {
            background: #000;
            -webkit-transition: all .2s linear;
            transition: all .2s linear;
        }
        .glyphicon {
            color: #fff;
            margin: 14px 0 0 -.5px;
            vertical-align: top;
        }
    }
}

.content>ul,
ul.list {
    list-style: inherit;
    padding-left: 60px;
}


/* Newsletter */

.newsletter-submit {
    background: none;
    color: #ccc;
    height: 38px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 1px;
    top: -60px;
    width: 38px;
}

.webform-component--sign-up {
    padding-bottom: 3px;
}

.hidden-submit .form-actions {
    display: none;
}

.webform-component--submit-icon {
    position: relative;
}

@media (max-width: 767px) {
    footer {
        .col-md-3 {
            margin-bottom: 36px;
            overflow: hidden;
            .form-email {
                margin-bottom: 0;
            }
        }
        form {
            margin-bottom: 0;
        }
    }
    .newsletter-submit {
        top: -38px;
    }
}


/* Mega Slider */

.md-slide-wrap p {
    line-height: 27px;
}

.md-objects {
    .md-object {
        line-height: 27px;
        a.btn {
            line-height: 19px;
            padding: 10px 20px;
            font-weight: normal;
            letter-spacing: 0;
            width: auto !important;
            height: auto !important;
        }
    }
    .btn.cherry {
        background: #752033;
    }
}

.md-arrow>div {
    background: #1e1e1e;
    background: rgba(30, 30, 30, 0.5);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 40px !important;
    width: 40px !important;
    -webkit-transition: background .3s linear;
    transition: background .3s linear;
    z-index: 50 !important;
    &:before {
        font-family: "revicons";
        color: #fff;
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        margin-right: 0;
        margin-top: 12px;
        text-align: center;
        width: 40px;
        font-size: 19px;
    }
}

.md-arrow-left:before {
    content: "\e824";
}

.md-arrow-right:before {
    content: "\e825";
}

.md-arrow>div {
    &:hover {
        background: #1e1e1e;
        -webkit-transition: background .3s linear;
        transition: background .3s linear;
        background-color: #c10841;
    }
    background-color: #c10841;
    background-color: rgba(193, 8, 65, 0.5);
}

.md-objects {
    h2 {
        font-size: 60px;
        line-height: 54px;
        letter-spacing: 0;
        font-weight: normal;
    }
    a {
        font-size: 14px !important;
        font-weight: bold;
        line-height: 31px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        &:hover {
            text-decoration: none;
            color: #fff;
        }
        .fa {
            border: 2px solid #fff;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            display: inline-block;
            font-size: 180%;
            height: 36px;
            line-height: 32px;
            margin-left: 10px;
            padding: 0 0 0 3px;
            text-align: center;
            vertical-align: middle;
            width: 36px;
        }
    }
}


/* Register Form */

.login-register .form-box .form-item-copy {
    display: none;
}

.form-required {
    color: #c3293a;
    display: inline-block;
    font-size: 14px;
}

.login-register .form-group {
    margin-bottom: 0;
}

.grippie {
    display: none;
}

input.required,
select.required,
textarea.required {
    color: #000;
}


/* Tags */

.item-list {
    ul {
        margin: 0;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }
    li {
        a {
            color: #1e1e1e;
        }
        padding: 4px 0;
    }
}

.sidebar .title-box {
    margin-bottom: 34px;
}


/* Comments */

.comment-reply {
    float: right;
    margin-top: -12px;
}

.comment-form .form-actions {
    padding-top: 31px;
}

.commentlist {
    li img {
        -webkit-border-radius: 42px;
        -moz-border-radius: 42px;
        border-radius: 42px;
        float: left;
        height: 84px;
        margin: 0 25px 0 0;
        width: 84px;
    }
    padding: 10px 0 33px;
}


/* Pager */

.pagination>li> {
    a {
        color: #fff;
    }
    span {
        font-size: 12px !important;
    }
}


/* Blog */

.view-blog .view-footer-pager,
.view-modern-gallery .view-footer-pager {
    display: block !important;
    color: #999;
    font-size: 12px;
    float: right;
    line-height: 24px;
    vertical-align: top;
    visibility: visible !important;
    margin-top: 32px;
}

.view-blog .view-footer,
.view-modern-gallery .view-footer {
    display: none;
}

.meta .field-name-field-category {
    display: inline-block;
}


/* 404 Page */

.fixed-header.page-404-promo {
    padding-top: 0;
}

.page-page-404 {
    .page-header {
        display: none;
    }
    .breadcrumb-box {
        height: 10px;
        li {
            display: none;
        }
    }
}


/* Search block */

#search-block-form .form-actions {
    display: none;
}


/* Search Results */

.search-form {
    .form-group,
    .search-string {
        width: 100%;
    }
    .form-submit {
        display: none;
    }
}

.search-results {
    margin: 0;
    padding: 0;
    list-style: none;
}

.search-result {
    padding-bottom: 30px;
}


/* Login Block */

.help-block {
    // margin-top: -17px !important;
    margin-bottom: 18px !important;
}

.login-register .form-box .form-submit {
    background: #1c60a1;
    &:hover {
        background: #565656;
    }
}

.form-box .help-block {
    bottom: 0;
    margin: 0;
    position: static;
}


/* Maintenance Page */

.maintenance-page {
    #main {
        display: table-cell;
        padding: 119px 0 192px;
        vertical-align: middle;
    }
    .page-box {
        display: table;
        height: 100% !important;
        width: 100%;
    }
    .header-three {
        min-height: 1px;
        position: absolute;
        width: 100%;
    }
}

.coming-text .btn {
    background-color: #0098ca;
}


/* Portfolio Page */

.view-portfolio-filter #edit-field-date-value-wrapper {
    display: none;
}

.work-one {
    width: 100%;
}

.filter-button-black {
    .filter-list.filter-buttons.white .dropdown-menu>li>a {
        color: #000;
    }
    .filter-buttons.white .dropdown-toggle {
        color: #000;
        .caret {
            color: #000;
        }
    }
}


/* Projects */

.inner-img-rounded img {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.black {
    .filter-list.filter-buttons.white .dropdown-menu>li>a {
        color: #000;
    }
    .filter-buttons.white .dropdown-toggle {
        color: #000;
        .caret {
            color: #000;
        }
    }
}


/* Modern Gallery */

.gallery .images-box {
    margin-bottom: 23px;
    max-width: 100%;
}


/* Gallery */

.images-box,
.margin-bottom-30 {
    margin-bottom: 30px;
}


/* Product Slider */

.product .product-image {
    float: none;
    padding: 0;
}

.view-products-grid {
    .product {
        margin-right: -3px;
        min-width: 1px;
    }
    .content .row {
        text-align: center;
    }
}

.toolbar .price-regulator {
    margin-bottom: 20px;
    b {
        float: left;
    }
}


/* Shortcode Image Overlay */

.image_overlay {
    background: #f2f2f2;
    margin: 0 0 80px;
    position: relative;
    display: inline-block;
    max-width: 100%;
    .description {
        background: rgb(64, 73, 155);
        background: rgba(64, 73, 155, 0.7);
        bottom: 0;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        left: 0;
        padding: 35px 38px;
        position: absolute;
        right: 0;
    }
}


/* Products Page */

.toolbar {
    form {
        float: left;
    }
    .selectBox,
    .selectBox-label {
        width: auto !important;
    }
    .views-exposed-form label {
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: #7f7f7f;
    }
}

.views-exposed-form .views-exposed-widget {
    padding: 0;
}

.toolbar {
    .selectBox {
        height: 24px;
        line-height: 10px;
        min-height: 24px;
        background-color: rgba(64, 73, 155, 0.7);
        border: none;
        margin: 0 15px 0 3px;
        border: 1px solid transparent;
        transition: background .2s linear, color .2s linear;
        -webkit-transition: background .2s linear, color .2s linear;
        &:hover {
            border: solid 1px #839bb7;
            color: #7f7f7f;
            background-color: #fff;
            .selectBox-arrow,
            .selectBox-label {
                color: #7f7f7f;
            }
        }
    }
    .selectBox-label {
        color: #fff;
        padding: 0 9px 0 0;
        position: relative;
        top: -1px;
    }
    .selectBox-arrow {
        background: none;
        color: rgba(255, 255, 255, 0.5);
        border: 0 none;
        height: 6px;
        width: 9px;
        display: inline-block;
        vertical-align: middle;
        right: 7px;
        top: 9px;
    }
    .grid-list {
        margin-right: 10px;
    }
    .selectBox-arrow:before {
        content: "\f107";
        display: block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 6px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 6px;
        position: absolute;
        width: 9px;
    }
}

.taxonomy-product span {
    color: #7f7f7f;
}

.product .actions span+span a {
    border-left-width: 1px;
}

.sale:before {
    content: "";
}

ul {
    &.products,
    &.specials {
        padding-left: 0;
        list-style: none;
    }
}

.sale {
    span {
        color: #fff;
        display: block;
        font-size: 11px;
        font-weight: bold;
        margin: -46px 0 0 5px;
        position: absolute;
        text-transform: uppercase;
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }
    &.corner-top {
        border-top: 56px solid #738d00;
    }
}


/* Flag */

.actions .unflag-action {
    background-color: #0098ca !important;
}

.rotation .actions .unflag-action {
    border: 1px solid #fff;
}

.actions .unflag-action svg path {
    fill: #fff !important;
}

.flag-throbber {
    display: none;
}

.add-cart-form .form-actions,
.flag-waiting .flag-throbber,
.form-type-fivestar .form-type-select,
.form-type-uc-quantity,
.product-info.sell-price {
    display: inline-block;
}


/* Product node page */

.fivestar-static-item .help-block,
.form-type-fivestar .selectBox,
.form-type-uc-quantity .form-required {
    display: none !important;
}

.product-page .add-cart-form {
    margin: 0;
}

.fivestar-average-stars,
.fivestar-widget,
form.fivestar-widget {
    float: left;
}

.fivestar-average-stars {
    &:after,
    &:before {
        content: "";
        display: none;
    }
}

.fivestar-widget {
    &:after,
    &:before {
        content: "";
        display: none;
    }
}

.fivestar-static-item,
.fivestar-summary {
    display: inline-block;
}

.evaluation label {
    font-weight: normal;
}

.form-actions .btn {
    margin-left: 15px !important;
    &:first-child {
        margin-left: 0 !important;
    }
}

.attributes .attribute {
    padding-bottom: 15px;
    &:last-child {
        padding-bottom: 35px;
    }
    .selectBox {
        margin-bottom: 0;
    }
}

@media (min-width: 690px) {
    .attributes .attribute a.selectBox {
        width: 50% !important;
        display: block !important;
    }
}

.product-page .col-md-7 .actions {
    margin: 0;
}


/* Top Right Icons */

.header-icons {
    .flag-counter {
        top: -3px;
        position: relative;
        font-size: 12px;
    }
    a:hover {
        text-decoration: none;
    }
}

.compare-header,
.wishlist-header {
    border-right: 1px solid #ccc;
    border-right: 1px solid rgba(204, 204, 204, 0.8);
    color: #1e1e1e;
    display: inline-block;
    height: 30px;
    margin: 8px 0;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 41px;
}

.compare-header a,
.wishlist-header a {
    display: block;
    height: 23px;
    padding-top: 7px;
    width: 100%;
}

.compare-header a svg path,
.wishlist-header a svg path {
    fill: #0098ca;
}

.compare-header a,
.wishlist-header a {
    color: #0098ca;
}

.compare-header a:hover svg,
.wishlist-header a:hover svg {
    opacity: .5;
    filter: alpha(opacity=50);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}


/* Compare Products */

#compare-table {
    .views-field-title a {
        color: #1e1e1e;
        text-decoration: none;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
    }
    .add-cart-form {
        .number {
            display: none;
        }
        margin-bottom: 0;
        .btn {
            padding: 10px 20px;
        }
    }
    .uc-price {
        display: block;
        font-size: 16px;
    }
}

.cell-align-center {
    vertical-align: middle !important;
}


/* Mega Menu */

.header .primary {
    .sub {
        li {
            list-style-image: none;
        }
    }
    .parent .parent .sub.tb-megamenu-submenu {
        margin-left: 0;
    }
}

.tb-megamenu-item {
    &.menu-title a,
    &.title a {
        font-size: 13px;
        line-height: 23px;
        margin: 25px 0 3px;
        text-transform: uppercase;
        font-weight: bold;
    }
    &.menu-title:first-child a,
    &.title:first-child a {
        margin-top: 15px;
    }
}

.header .primary .sub a {
    min-width: 175px;
    width: auto;
}

.tb-megamenu-column {
    padding: 20px 27px 15px;
}

.megamenu-column-single {
    padding: 15px 20px;
}

.tb-megamenu-column .tb-megamenu-item {
    line-height: 26px;
}

.header .primary .navbar .nav a .item-new {
    float: none;
    font-weight: normal;
    background: #ff0068;
}

.header .primary .sub,
.no-padding {
    padding: 0;
}

body.one-page .header .primary .sub {
    padding: 15px 20px;
}

.items-image-man {
    text-align: center;
    padding: 10px 0 20px;
}

.padding-top-megamenu {
    padding-top: 30px;
}

.tb-megamenu-item .border-left {
    border-left: 1px solid #ccc;
}

@media (max-width: 991px) {
    .header {
        .dropdown.active {
            &:hover>ul.sub,
            >ul.sub {
                display: block;
            }
        }
        .primary .sub.tb-megamenu-submenu {
            float: none;
            margin: 0;
            >li {
                padding: 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .mega-align-center>.dropdown-menu {
        left: 50%;
        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);
    }
    .mega-align-right>.dropdown-menu {
        left: auto;
        right: 0;
    }
    .mega-align-justify>.dropdown-menu {
        right: -200px;
        left: auto;
    }
}

.header .primary {
    .mega-group>ul.sub {
        position: relative;
        display: block;
        left: 0;
        margin: 5px 0;
    }
    .navbar .nav .parent .mega-group>a:after {
        content: "";
    }
}


/* Social Buttons */

.sbtnf {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    &:hover {
        background-color: #000000;
    }
}


/* LivIcons */

.livicon.block {
    width: auto !important;
    height: auto !important;
}


/* Testimonials */

.description {
    &.bg-error:before {
        border-top-color: #c10841 !important;
    }
    &.bg-primary:before {
        border-top-color: #037ac5 !important;
    }
    &.bg-warning:before {
        border-top-color: #f89406 !important;
    }
    &.bg-success:before {
        border-top-color: #738d00 !important;
    }
}

.respond .name .icon {
    margin-right: 5px;
}


/* Slider Content Carousel */

.carousel-wrapper {
    position: relative;
}

.title-box+div {
    .carousel-pagination {
        position: relative;
        top: -54px;
    }
    .margin-pagination-fix {
        margin-top: -35px;
    }
}

.allow-overflow {
    overflow: visible;
}

.margin-pagination-fix .carousel-pagination {
    height: 50px;
}


/* Text Rotation */

.text-black,
.word-rotate.text-black {
    color: #000;
}


/* Latest Posts */

.no-all-posts .title-box a {
    display: none;
}

.latest-posts-white .title {
    color: #fff;
    font-size: 24px;
}


/* Project Slider */

.content .slider.progressive-slider {
    overflow: hidden;
}


/* Sitemap */

.sitemap {
    list-style: none !important;
    padding: 0 !important;
    ul {
        list-style: none !important;
        padding: 0 !important;
    }
}


/* Contact Form */

.contact-form .form-actions {
    padding-top: 30px;
}


/* Blog TimeLine Contextual */

.contextual-form {
    width: 400px;
    padding: 20px 20px 0 !important;
    label {
        color: #000;
        margin-top: 5px;
    }
    .form-submit {
        margin-top: 15px;
    }
}


/* Color Radios */

.form-item-color .form-type-radio {
    padding: 0;
}

.color-radios {
    input[type="radio"] {
        display: none;
    }
    .form-item {
        display: inline-block;
    }
    label {
        display: inline-block !important;
        width: 32px;
        height: 32px;
        border: 2px solid transparent !important;
        text-indent: -9999px;
    }
    input[type="radio"]:checked+label {
        border: 2px solid #3d3d3d !important;
    }
}

.form-radio {
    &[value="black"]+label {
        background-color: #000;
    }
    &[value="danger"]+label {
        background: #d70a49;
    }
    &[value="info"]+label {
        background: #0098ca;
    }
    &[value="primary"]+label {
        background: #037ac5;
    }
    &[value="warning"]+label {
        background: #f89406;
    }
    &[value="success"]+label {
        background: #738d00;
    }
}


/* One Page */

body {
    &.page-user-login .header-three,
    &.page-user-password .header-three,
    &.page-user-register .header-three {
        min-height: 1px;
        position: absolute !important;
        width: 100%;
    }
}


/* Sequence */

@media (max-width: 767px) {
    .steps .step+.step {
        padding-bottom: 15px !important;
    }
}


/* Secondary MegaMenu */

@media (max-width: 991px) {
    .header .primary.secondary-megamenu {
        margin: 0 0 0 -4px;
        text-align: left;
        z-index: inherit;
        float: right;
        .navbar .navbar-collapse {
            width: 100%;
            height: 0;
            overflow: hidden;
            display: none !important;
            height: auto !important;
            clear: both;
            &.in {
                display: block !important;
                height: auto !important;
            }
        }
    }
}


/* Toolbar */

#toolbar {
    z-index: 1600;
    .icon {
        width: auto;
        height: auto;
    }
}

.toolbar.fixed-header.hidden-top .header {
    margin-top: 1em;
}

.toolbar-drawer.fixed-header.hidden-top .header {
    margin-top: 3em;
}


/* SelectBox tab fix */

.tab-pane .selectBox-label {
    width: 100% !important;
}


/* Views Exposed Filters */

.bef-select-as-checkboxes {
    height: auto;
    border: none;
    box-shadow: none;
}

.view-filter .views-exposed-widgets .views-exposed-widget {
    float: none;
}

.price-regulator {
    .layout-slider {
        float: right;
        margin-top: 5px;
        width: 270px;
    }
    padding-bottom: 15px;
}
