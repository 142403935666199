@media only screen and (min-width: 1200px) {
    /* Correction bug affichage quand on est connecté */
    .logged-in {
        &.front {
            #header {
                margin-top: -29px;
            }
        }
    }
    .header {
        .right-box {
            background-position: 100% 0;
            height: 150px;
        }
        .slogan {
            &:before {
                right: 191px;
                background-position: 0 -56px;
                height: 194px;
            }
            &:after {
                height: 150px;
                top: 0;
            }
            .bulle {
                bottom: 50px;
                left: -150px;
            }
            span {
                font-size: 1.6rem;
            }
        }
        .logo {
            margin: 20px 0 -20px 10px;
        }
        .header-icons {
            margin-right: 0;
            height: 150px;
            .search-header {
                right: 35px;
            }
        }
    }
    #diaporama {
        background: linear-gradient(0deg, white 85px, $bleu-fonce 85px);
    }
    .carousel-control {
        .icon-prev {
            margin-left: -180px;
        }
        .icon-next {
            margin-right: 130px;
        }
    }
    .view-diaporama-accueil:before {
        background: url("../images/silhouette.png") no-repeat;
        position: absolute;
        width: 498px;
        height: 270px;
        top: 33px;
        left: -134%;
    }
    .ecran {
        margin-left: -20%;
    }
    .acces-directs {
        background: linear-gradient(to bottom,#949591 0,#939591 287px,#e0e2df 287px,#c4cac6 100%);
        li,
        li.active {
            height: 67px;
            &:focus,
            &:hover {}
            &:after {}
            div {}
            a {
                &:before {}
            }
        }
    }
    .front #views-acces_directs-acces_direct {
        float: right;
        margin-right: 7%;
    }
    /* ARCHIVES HOPIPHARM */
    .page-node-2344 .archives-hopipharm {
        padding: 15px 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1599px) {}
