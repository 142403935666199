@media only screen and (min-width: 768px) {
    /* Correction bug affichage quand on est connecté */
    .logged-in.admin-menu {
        &.front {
            #header {}
        }
    }
    .fixed-header.logged-in.admin-menu {
        #top-box {
            margin-top: 0;
            top: 54px;
        }
        .page-box {
            padding-top: 22px;
        }
        #diaporama {
            //                    margin-top: 160px;
        }
    }
    .header {
        .logo img {
            max-width: 285px;
        }
        .slogan {
            height: 120px;
            padding-top: 50px;
            &:before {
                background: url("../images/photo.jpg") no-repeat;
                height: 157px;
                position: absolute;
                z-index: 0;
                content: "";
                width: 177px;
                top: -25px;
                right: 150px;
                transform: rotate(12deg);
                background-position: -20px -80px;
            }
            &:after {
                height: 173px;
                position: absolute;
                content: "";
                width: 177px;
                top: -54px;
                right: 274px;
                background: linear-gradient(to right, #ffffff 60%, transparent 100%);
                transform: rotate(12deg);
            }
            .bulle {
                padding: 15px;
                background: #ddd;
                border-radius: 10px;
                width: 150px;
                left: -72px;
                bottom: 10px;
                position: relative;
                top: -30px;
                &:after {
                    content: "";
                    position: absolute;
                    border-style: solid;
                    display: block;
                    width: 0;
                    top: 40px;
                    right: -30px;
                    bottom: auto;
                    left: auto;
                    border-width: 20px 0 0 35px;
                    border-color: transparent #ddd;
                    transform: rotate(15deg);
                }
            }
            h1,
            span {
                font-size: 1.20rem;
                color: #111;
                line-height: 1.3;
            }
        }
    }
    .right-box {
        padding: 0;
    }
    .header .header-icons {
        background-size: auto;
        background-position: 0 0;
        .search-header {
            top: 40px;
            right: 40px;
            a:before {
                font-size: 29px;
            }
        }
    }
    .search-active {
        .search-string,
        .search-string:hover {}
        .close {
            margin: 12px 200px 0 5px;
            &:hover {}
        }
        .search-submit {}
    }
    .primary {
        position: absolute;
        top: 37px;
    }
    #progressive_cms-breadcrumbs {
        padding: 0;
        .breadcrumb>li {
            color: white;
        }
    }
    .breadcrumb-box {
        padding-left: 0;
        margin-left: -20px;
        height: 136px;
        background: #1d60a1 linear-gradient(to top, rgba(0, 0, 0, 0.5) 12%, rgba(0, 0, 0, 0.5) 15%, rgba(1, 1, 1, 0) 100%);
    }
    .breadcrumb {
        background: url("../images/entete-page.png") no-repeat;
        height: 136px;
        border-radius: 0;
        padding: 115px 0 0 20px;
        margin-left: -15px;
        overflow: hidden;
    }
    #diaporama {
        background: #1d60a1;
    }
    .carousel-inner .carousel-inner {
        width: 100%;
        .item {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .carousel .col-sm-9 {
        padding: 0;
    }
    .item {
        .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 50px 50px 50px 0;
            position: absolute;
            right: 0;
            top: 18px;
            &:before {
                color: white;
                font-family: FontAwesome;
                font-size: 30px;
                display: block;
                line-height: 50px;
                position: absolute;
                right: -46px;
                top: -26px;
            }
            &:after {
                content: "";
                position: absolute;
                width: 100px;
                height: 95px;
                transform: rotate(45deg);
                left: 20px;
                top: -45px;
                box-shadow: -1px 0 3px 2px rgba(0, 0, 0, 0.3);
            }
        }
        &:nth-of-type(1) .triangle {
            border-color: transparent #2dabe1 transparent transparent;
            &:before {
                content: "\f130";
                right: -44px;
            }
        }
        &:nth-of-type(2) .triangle {
            border-color: transparent #d0b70f transparent transparent;
            &:before {
                content: "\f005";
                font-size: 30px;
            }
        }
        &:nth-of-type(3) .triangle {
            border-color: transparent #76B82A transparent transparent;
            &:before {
                content: "\f0fa";
                font-size: 25px;
                right: -43px;
            }
        }
        &:nth-of-type(4) .triangle {
            border-color: transparent #ed7d23 transparent transparent;
            &:before {
                content: "\f19d";
                font-size: 25px;
                right: -49px;
            }
        }
        &:nth-of-type(5) .triangle {
            border-color: transparent #58881f transparent transparent;
            &:before {
                content: "\f007";
            }
        }
        &:nth-of-type(6) .triangle {
            border-color: transparent #B13770 transparent transparent;
            &:before {
                content: "\f21e";
                font-size: 26px;
            }
        }
        &:nth-of-type(7) .triangle {
            border-color: transparent $bleu-sombre transparent transparent;
            &:before {
                content: "\f01d";
                font-size: 26px;
            }
        }
        .carousel-caption {
            left: 0;
            right: 0;
            padding: 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            box-shadow: -1px 0 3px 2px rgba(0, 0, 0, 0.3);
            margin-bottom: -20px;
            text-shadow: none;
            a,
            a:visited {
                color: white;
                font-size: 2.5rem;
                &:hover {
                    text-decoration: none;
                }
                &::after {
                    position: absolute;
                    font-family: "FontAwesome";
                    content: "\f0da";
                    margin-left: 20px;
                }
            }
        }
        &:nth-of-type(1) .carousel-caption {
            background: #2dabe1;
        }
        &:nth-of-type(2) .carousel-caption {
            background: #d0b70f;
        }
        &:nth-of-type(3) .carousel-caption {
            background: #76B82A;
        }
        &:nth-of-type(4) .carousel-caption {
            background: #ed7d23;
        }
        &:nth-of-type(5) .carousel-caption {
            background: #58881f;
        }
        &:nth-of-type(6) .carousel-caption {
            background: #B13770;
        }
        &:nth-of-type(7) .carousel-caption {
            background: $bleu-sombre;
        }
    }
    .acces-directs {
        li {
            width: 50%;
            height: 114px;
            margin: 0 -4px 0 0;
            font-size: 1.4rem;
            &.active {
                width: 50%;
                height: 114px;
                margin: 0 -4px 0 0;
                font-size: 1.4rem;
            }
            &.active div,
            div {
                position: relative;
            }
            &.active a:before,
            a:before {
                font-size: 26px;
                line-height: 3;
            }
        }
    }
    /* MENU FOOTER */
    #menu-footer {
        ul {
            li {
                display: inline;
                line-height: 40px;
                &:before {
                    content: " | ";
                    Color: #666;
                }
                &:first-child:before {
                    content: "";
                }
                a:visited {
                    color: $rose;
                }
            }
        }
    }
    /* ACCES PERMANENTS */
    .outils {
        position: fixed;
        right: 0;
        top: 140px;
        z-index: 1000;
        ul li {
            background: #76B82A;
            margin: 0;
            padding: 10px 0;
            list-style: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            width: 96px;
            height: 94px;
            line-height: 30px;
            border-radius: 5px 0 0 5px;
            background-color: #76b729;
            box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
            margin-bottom: 10px;
            &:hover {
                background: #2daae1;
            }
            a,
            a:visited {
                display: block;
                color: white;
                text-decoration: none;
                text-align: center;
                font-size: 1.2em;
                padding: 12px 16px;
                &:hover {
                    color: white;
                }
            }
            .fa {
                font-size: 30px;
                padding-left: 6px;
            }
            span {
                display: block;
            }
        }
    }
    .not-front .acces-directs {
        margin: 60px auto 0;
        padding: 0;
        box-shadow: 0 2px 10px rgba(0, 0, 1, .5);
        border-radius: 0 0 15px 15px;
        border-bottom: 4px solid #939591;
        background: #e0e2df;
        width: 158px;
        height: 330px;
        &:before {
            background: url("../images/ondes.png") 30px no-repeat;
            margin-top: 30px;
            position: absolute;
            content: "";
            margin-top: -60px;
            width: 158px;
            height: 49px;
        }

        li,
        li.active {
            font-size: 1.4rem;
            height: 61px;
            margin: 3px -1px 1px 2px;
            padding-top: 7px;
            &:hover {}
            &:focus {}
            &:after {}
            div {}
            a {
                &:before {
                    font-size: 26px;
                    line-height: 33px;
                }
            }
        }
        li:nth-child(1),
        li:nth-child(1).active,
        li:nth-child(2),
        li:nth-child(2).active {
            margin-top: 3px;
        }
        .outils {
            top: 180px;
        }
    }
    /* ARCHIVES HOPIPHARM */
    .page-node-2344 {
        .archives-hopipharm {
            padding: 10px 6px;
            font-size: 1.15rem;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #diaporama {
        #views-acces_directs-acces_direct,
        #views-diaporama_accueil-block {
            margin-left: -15px;
            margin-right: -15px;
            li {
                height: 118px;
            }
        }
        #views-diaporama_accueil-block {
            width: 70%;
            .view-diaporama-accueil {
                max-width: 100%;
            }
        }
        #views-acces_directs-acces_direct {
            width: 30%;
            margin-left: 42px;
            float: left;
        }
    }
    .carousel {
        width: 563px;
    }
    .outils {
        top: auto;
        bottom: 0;
    }
    .front {
        .view-display-id-page_derniers-articles-parus {
            max-width: 80%;
        }
    }
    .header .slogan::before {
        height: 157px;
        position: absolute;
        z-index: 0;
        content: "";
        width: 177px;
        top: -25px;
        right: 150px;
        transform: rotate(12deg);
        background-position: -20px -80px;
    }
    #views-acces_directs-acces_direct {
        position: relative;
        float: none;
    }
}
