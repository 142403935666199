@import '_colors';
#footer .up:hover,
#top-box,
.accordion-tab>li>a .open-sub::after,
.banner-set .pagination a:hover,
.carousel-box .next:hover,
.carousel-box .pagination a:hover,
.carousel-box .prev:hover,
.employee .employee-hover,
.new-radio.checked span,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>li>a:focus,
.pagination>li>a:hover,
.panel-group .active.panel .panel-heading>.panel-title>a,
.panel-group .active.panel .panel-heading>.panel-title>a:hover,
.panel-group .panel-default .panel-heading>.panel-title>a::after,
.product .product-hover,
.sidebar .banners .banner-text,
.sidebar .menu li.parent>a .open-sub::after,
.sidebar .menu li.parent>a .open-sub::before,
.sidebar .tags a,
.sidebar .tags a:hover,
.slider .slider-nav {
    background-color: $bleu-fonce !important;
}


/* HEADER */

.top-navbar {
    .zone-info-membre {
        line-height: 22px;
        color: white;
        font-size: 12px;
        padding: 9px 15px;
        i {
            padding-right: 9px;
            color: $orange;
        }
        span {
            color: $vert;
        }
    }
}

.fixed-header.fixed-top .header,
.fixed-header.hidden-top.fixed .header,
.fixed-header.hidden-top.visible-top.fixed .header {
    margin-top: 28px;
}

.fixed-header.hidden-top.visible-top .header {
    margin-top: 28px;
    transition: none;
}

// .fixed-header.logged-in.hidden-top.admin-menu .header,
// .fixed-header.logged-in.hidden-top.visible-top.admin-menu .header,
// .fixed-header.logged-in.visible-top.admin-menu.admin-menu .header {
//     margin-top: 58px;
// }
.fixed-header.hidden-top #top-box,
.fixed-header.hidden-top.fixed #top-box,
.fixed-header.hidden-top.visible-top #top-box,
.fixed-header.hidden-top.visible-top.fixed #top-box {
    margin-top: 0;
    transition: none;
}

.fixed-header.hidden-top .header,
.fixed-header.hidden-top.visible-top .header,
.fixed-header.hidden-top.visible-top.fixed #top-box .fixed-header.hidden-top .header {
    margin-top: 28px;
    transition: none;
}

ul ul .sub.row.tb-megamenu-submenu.dropdown-menu.mega-dropdown-menu.nav-child {
    display: none;
}

.header {
    .logo {
        margin: 10px 0 -20px -10px;
        .logo-img {
            width: auto;
        }
    }
    .slogan {
        .bulle {
            text-align: left;
            z-index: 3;
        }
        h1,
        span {
            margin: 0;
            padding: 0;
            font-family: "PT Sans", sans-serif;
            font-weight: normal;
        }
    }
    .header-icons {
        position: absolute;
        height: 120px;
        width: 240px;
        right: 0;
        top: 0;
        margin-top: 0;
        padding: 0;
        background: url("../images/bg-droite-entete.png") 120px 0 no-repeat;
        background-size: 130px;
        .search-header {
            z-index: 2;
            right: 19px;
            top: 8px;
            a {
                color: white;
                &:hover {
                    color: $rose;
                }
                &:before {
                    font-size: 16px;
                }
            }
        }
    }
    .primary {
        position: relative;
        top: 0;
        background: $vert-fonce;
        height: 36px;
        width: 100%;
        .navbar {
            .nav>li>a,
            .nav>li>a:visited {
                background: none;
                border: none;
                border-radius: none;
                color: $noir-pale;
                position: static;
                text-shadow: none;
                transition: all .2s linear;
                z-index: 901;
            }
            .nav>li.parent.active-trail,
            .nav>li.parent:hover>a {
                background: $bleu-fonce;
                color: white;
            }
        }
        .btn-navbar {
            &:before {
                border: none !important;
            }
            .icon-bar {
                background: white !important;
            }
            .text {
                color: white !important;
            }
            &.collapsed {
                border: none !important;
            }
        }
        .nav .open-sub span {
            background: $vert !important;
        }
    }
}

.search-header a {
    color: $vert;
    &:hover {
        color: $rose;
    }
}

.search-active {
    width: 100%;
    .search-string,
    .search-string:hover {
        font-size: 1.6rem;
        line-height: 14px;
        padding: 0 0 0 -6px;
        margin-top: -4px;
    }
    .close {
        float: right;
        font-size: 26px;
        margin: 12px 0 0 5px;
        &:hover {
            color: $bleu-fonce;
        }
    }
    .search-submit {
        top: 4px;
        & svg path {
            fill: $bleu-fonce !important;
        }
    }
}

#top-box {
    padding: 0;
}

#BODY {
    background: white;
    padding: 20px;
}

.title-box .title {
    font-size: 22px;
}

legend {
    border-bottom: 0;
}

.content {
    ul {
        padding: 0 0 0 20px;
        list-style: none;
        li {
            &:before {
                content: "■ ";
                color: $vert;
                font-size: 14px;
                position: static;
                left: 1.5rem;
                margin-top: -0.6rem;
            }
            p {
                display: inline;
            }
        }
    }
    .panel-group .panel .panel-heading>.panel-title>a {
        display: inline-block;
        width: 100%;
        &:hover {
            display: inline-block;
        }
    }
}

.page-header {
    border-bottom: none;
    margin: 0;
    padding: 5px 0;
    line-height: 49px;
}

.page-header .container {
    border: none;
    &:before {
        width: 0;
        border: none;
    }
}

.sidebar {
    margin: 30px auto 60px;
    &.menu li a:hover {
        background-color: $vert;
    }
}


/* MENU FOOTER */

#block-menu-menu-menu-footer {
    .clearfix:after {
        display: none;
    }
    #menu-footer {
        ul {
            li {
                &:before {}
                &:first-child:before {
                    content: "";
                }
                a:visited {
                    color: $rose;
                }
            }
        }
    }
}


/* ACCUEIL */

.front {
    #BODY .title-box {
        margin-bottom: 0;
    }
    h2:before {
        font-family: FontAwesome;
        font-size: 22px;
        margin-right: 23px;
    }
}

.latest-posts {
    ul {
        padding: 0;
    }
    li {
        font-size: 13px;
        list-style: none;
        padding: 15px 10px;
        &:hover {
            background: $bleu-pale;
        }
        h3 a {
            font-weight: 100;
            font-size: 17px;
        }
    }
}

.meta {
    color: $rose !important;
}

.date-display-range {
    display: inline;
}

.view-display-id-page_derniers-articles-parus {
    &.latest-posts li::before {
        margin-bottom: 10px;
    }
    h2:before {
        content: "\f1ea";
    }
    h3 {
        margin: 0;
    }
}

#views-lastest_posts-accueil_agenda {
    h2:before {
        content: "\f073";
    }
}

.view-display-id-page_derniers-articles-parus.latest-posts li:before {
    font-family: FontAwesome;
    display: inline-block;
    text-align: center;
    content: "\f1ea";
    font-size: 30px;
    float: left;
    margin-right: 23px;
    padding: 0 10px;
    background: $vert;
    padding: 8px;
    border-radius: 7px;
    border: none;
    width: 60px;
    height: 60px;
    color: white;
}


/* PAGE */

.submitted {
    font-style: italic;
    font-size: 12px;
}


/* DIAPORAMA ACCUEIL */

.view-diaporama-accueil {
    padding: 0;
}

.carousel {
    padding: 0;
    width: 579px;
}

.carousel-control {
    z-index: 4;
    width: 17%;
    .icon-next,
    .icon-prev {
        font-family: 'FontAwesome';
        font-size: 35px;
    }
    .icon-prev {
        &::before {
            content: "\f0d9";
        }
    }
    .icon-next {
        &::before {
            content: "\f0da";
        }
    }
}

.not-front .acces-directs {
    margin-top: 60px;
}

.acces-directs {
    z-index: 15;
    text-align: center;
    margin: 0 -4px;
    padding: 0;
    li,
    li.active {
        position: relative;
        height: 33.3%;
        text-indent: 0;
        font-size: 2.4rem;
        border-radius: 0;
        padding: 0;
        border: none;
        display: inline-block;
        &:focus,
        &:hover {
            opacity: .8;
        }
        &:after {
            content: "";
            display: block;
            padding-bottom: 95%;
        }
        div {
            position: absolute;
            width: 100%;
            //            top: 50%;
            //            transform: translateY(-50%);
        }
        a {
            color: white;
            display: block;
            &:focus,
            &:hover {
                text-decoration: none;
            }
            &:before {
                font-family: FontAwesome;
                font-size: 50px;
                display: block;
                line-height: 80px;
            }
        }
        &:nth-child(1) {
            background-color: $bleu-clair;
            a:before {
                content: "\f130";
            }
        }
        &:nth-child(2) {
            background-color: $dore;
            a:before {
                content: "\f005";
            }
        }
        &:nth-child(3) {
            background-color: $vert;
            a:before {
                content: "\f0fa";
            }
        }
        &:nth-child(4) {
            background-color: $orange;
            a:before {
                content: "\f19d";
            }
        }

        &:nth-child(5) {
            background-color: $vert-fonce-acces-rapide;
            a:before {
                content: "\f007";
            }
        }
        &:nth-child(6) {
            background-color: $rose;
            a:before {
                content: "\f21e";
            }
        }
        &:nth-child(7) {
            background-color: $bleu-sombre;
            width: 100%;
            a:before {
                content: "\f01d";
            }
        }
    }
}


/* MENU DROITE */

#block-block-7 li {
    &:nth-child(1) a:before {
        content: "\f073";
    }
    &:nth-child(2) a:before {
        content: "\f1ea";
    }
    &:nth-child(3) a:before {
        content: "\f003 ";
    }
    a:before {
        font-family: FontAwesome;
        font-size: 14px;
        margin-top: 8px;
        padding-right: 20px;
        display: block;
        margin-left: -25px;
        background: transparent;
        color: $vert;
    }
    #block-block-7 li a:hover {
        color: white;
        &:hover:before {
            color: white;
        }
    }
}

#sidebar_right .acces-directs {
    margin: 60px auto 0;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 1, .5);
    border-radius: 0 0 15px 15px;
    border-bottom: 4px solid $gris;
    background: $gris-clair-acces-rapide;
    background: linear-gradient(to bottom, $gris-fonce-acces-rapide 0%, $gris 199px, $gris-clair-acces-rapide 199px, $gris-acces-rapide 100%);
    width: 158px;
    height: 330px;
    &:before {
        background: url("../images/ondes.png") 30px no-repeat;
        margin-top: 30px;
        position: absolute;
        content: "";
        margin-top: -60px;
        width: 158px;
        height: 49px;
    }
    li,
    li.active {
        font-size: 1.4rem;
        height: 61px;
        width: 74px;
        margin: 3px -1px 1px 2px;
        padding-top: 7px;
        a {
            &:before {
                font-size: 26px;
                line-height: 33px;
            }
        }
    }
    li:nth-child(1),
    li:nth-child(1).active,
    li:nth-child(2),
    li:nth-child(2).active {
        margin-top: 3px;
    }
}

.toc-filter {
    width: 100%;
    margin-top: -22px;
    h3 {
        color: $vert;
    }
    .item-list li {
        &:before {
            position: static;
        }
        a {
            color: $rose;
        }
    }
}

div.toc-filter-content {
    background-color: #fafafa;
}


/* AGENDA SOMMAIRE */

:target:before {
    content: "";
    display: block;
    height: 200px;
    margin: -200px 0 0;
}

.panel-default>.panel-heading {
    padding: 0;
    border: none;
    background-color: transparent;
}

.view-id-agenda .panel-default {
    background-color: $gris-clair;
    max-width: 100%;
    width: 100%;
    a:hover {
        background-color: $gris-clair;
        background-color: rgba(0, 0, 0, .1);
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
    }
    ul {
        border-top: 4px solid white;
        padding-left: 15px;
        padding-top: 10px;
        li {
            list-style: none;
            margin-left: 0;
            font-size: 12px;
            &:before {
                font-family: FontAwesome;
                font-size: 12px;
                margin-right: 5px;
                content: "\f073";
            }
            .meta {
                display: block;
            }
        }
    }
    .fa-link {
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        font-size: 20px;
        padding: 10px 12px;
        color: $bleu-fonce;
    }
    .panel-default .panel-default ul {
        position: sticky;
        left: 0;
    }
}

.sidebar .menu li.active>a {
    background-color: $noir;
    color: white;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.panel-title>a {
    background-color: transparent;
    color: $noir;
    display: block;
    padding: 9px 9px 9px 60px;
    position: relative;
    text-decoration: none;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}


/* AGENDA */

.date-agenda {
    float: left;
    color: white;
    padding: 8px;
    border-radius: 7px;
    border: none;
    width: 60px;
    height: 60px;
    text-align: center;
    background: $rose;
    font-size: 20px;
    line-height: 20px;
}

.view-agenda li,
.view-display-id-accueil_agenda li {
    &:hover h2,
    &:hover h3 {
        padding-left: 80px;
        font-weight: 100;
    }
    h2,
    h3 {
        font-size: 17px;
        background-color: $bleu-pale;
        padding-left: 80px;
        font-weight: 100;
        border-radius: 7px;
        min-height: 60px;
        color: $gris-fonce;
        line-height: 18px;
        padding-top: 18px;
    }
    a:focus,
    a:hover {
        text-decoration: none;
        color: $gris-fonce;
    }
}

.view-agenda.latest-posts {
    li {
        &:hover {
            background: transparent;
        }
    }
}


/* ANNONCES EMPLOI */

.page-annonces-emploi {
    .title-box {
        margin-bottom: 30px;
    }
    .icon.icon-100 {
        font-size: 100px;
        width: 100%;
    }
    .view-acces-directs {
        margin-top: 60px;
    }
}

.view-annonces-emploi {
    li {
        padding: 0 10px;
        &:before {
            position: static;
        }
    }
    h3 {
        margin: 0;
        a:after {
            font-family: FontAwesome;
            font-size: 30px;
            content: "\f090";
            text-decoration: none;
            position: absolute;
            right: 0;
            padding: 15px 10px;
            background: $bleu;
            height: auto;
        }
    }
}


/* Boutons dépot d'annonce */

#block-block-1,
#block-block-8 {
    background: $bleu-fonce;
    color: white;
    padding: 15px 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    &:hover {
        background: $vert;
        .livicon {}
    }
    .block-content {
        font-size: 2rem;
        text-align: center;
    }
    p {
        text-align: center;
    }
    a {
        color: white;
    }
}

.vert {
    color: $vert;
    display: block;
    font-size: 11px;
    line-height: 18px;
    margin: 0 0 10px;
}


/* PAGES ANNONCE EMPLOI */

.node-annonce-emploi {
    .field-label-above {
        margin: 10px 0;
        b {
            margin: 10px 0;
            display: block;
            color: $vert;
            &:before {
                font-family: FontAwesome;
                font-size: 20px;
                margin-right: 10px;
                color: $rose;
            }
        }
    }
    .field-name-field-numero-offre b:before {
        content: "\f005";
    }
    .field-name-field-adresse b:before {
        content: "\f015";
    }
    .field-name-field-annonce-nom-contact b:before {
        content: "\f007";
    }
    .field-name-body b:before {
        content: "\f129";
    }
    .field-name-field-type-de-contrat b:before {
        content: "\f044";
    }
    .field-name-field-annonce-localisation b:before {
        content: "\f041";
    }
    .field-name-field-caracteristiques b:before {
        content: "\f013";
    }
    .field-name-field-annonce-disponibilite b:before {
        content: "\f073";
    }
    .field-name-field-annonce-remuneration b:before {
        content: "\f0d6";
    }
    .field-name-field-annonce-expiration b:before {
        content: "\f017";
    }
}

/****Page ajout d'une annonce****/
#annonce-emploi-node-form{
	.location .form-item label{
		width:165px;
		padding-top:10px;
	}
	#edit-field-cv- .help-block {
		margin-top: 0;
	}
	#edit-field-cv- input[type="file"] {
		display:inline-block;
		width:400px;
	}
	legend {
		margin-bottom:0;
	}
	#field-annonce-expiration-add-more-wrapper * {
		display: inline;
		margin-bottom:0px;
	}
}


/* ANNUAIRE */

.views-exposed-form {
    .form-item {
        display: inline-block;
    }
    .radio {
        padding-left: 0;
    }
    .radio label {
        padding: 5px 20px;
        width: auto;
        background: $bleu-fonce;
        color: white;
        margin: 5px;
        &:hover {
            background: $bleu-clair;
        }
        .form-item-items-per-page {
            display: block;
        }
    }
    .new-radio {
        display: none;
    }
}

.views-widget-per-page {
    float: right !important;
    a,
    label {
        display: block;
    }
    .selectBox {
        width: auto !important;
        border-radius: 0;
        font-size: 12px;
        line-height: 12px;
        height: 22px;
        min-height: 22px;
        margin-bottom: 0;
        padding: 3px 10px;
    }
}

.selectBox-dropdown-menu {
    overflow-x: hidden;
    width: 200px;
}

.form-control,
.selectBox {
    box-shadow: 0 0 white, inset 0 0 0 rgba(0, 0, 0, .15);
}


/*****************************************/


/******** annuaire card list ************/


/***************************************/

.enveloppe {
    height: 190px;
    overflow: hidden;
    width: 100%;
    z-index: 2;
}

.view-Annuaire .btn.btn-primary.active {
    background-color: #ffffff !important;
    border: 2px solid #037ac5;
    color: #037ac5;
    box-shadow: none;
}


/* Cards */

.view-Annuaire ul.cardlist li.card {
    display: block;
    float: left;
    width: 45%;
    margin-bottom: 1em;
    border: 2px solid #ddd;
    min-height: 180px;
    margin-top: 2em;
    padding-top: 1%;
    border-radius: 10px;
    position: relative;
    margin-right: 2%;
}

ul.cardlist li.card h2 {
    background: #b13770;
    color: #fff;
    font-family: verdana;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    padding: 4px 4px 4px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5%;
}


/* Photos */

ul.cardlist li.card .img_card {
    margin-left: 12px;
    margin-top: 11px;
    float: left;
    position: absolute;
    right: 8px;
    top: 0;
}

ul.cardlist li.card .img_card img {
    width: auto !important;
    height: 75px !important;
}

ul.cardlist li.card .infoBase {
    min-height: 174px;
    margin-top: 0;
    overflow: auto;
    padding: 4px 4px 4px 8px;
}

ul.cardlist li.card .infoBase #email,
ul.cardlist li.card .infoBase .second-part {
    padding-right: 80px;
}

ul.cardlist li.card .infoBase #email {
    margin-bottom: 15px;
    word-break: break-all;
    font-weight: bold;
}

ul.cardlist li.card #fonction {
    font-weight: bold;
    color: #1d60a1;
    font-size: 1.1em;
}

.delegation {
    font-weight: bold;
    color: #1d60a1;
    font-size: 1.1em;
    margin-bottom: 15px;
}

#fonction_orga {
    font-weight: bold;
    color: #1d60a1;
    font-size: 1.1em;
    margin-bottom: 15px;
}

ul.cardlist li.card .etablissement {
    font-weight: bold;
    color: #b13770;
}


/* ESPACE CONGRES */

.page-node-2186 .content {
    .col-sm-4 {
        padding: 0 15px;
        &:nth-child(1),
        &:nth-child(2) {
            border-right: 1px dotted $gris-pale;
        }
        h2 {
            background: $rose;
            padding: 5px;
            color: white;
            font-size: 1.9rem;
            text-align: center;
        }
    }
}


/* ARCHIVES HOPIPHARM */

.view-archives-hopipharm {
    .views-row {
        h2 {
            &:before {
                background: $gris-pale;
                content: "";
                width: 100%;
                height: 1px;
                margin-top: 20px;
                margin-bottom: 20px;
                display: block;
            }
            a {
                margin-top: 20px;
            }
        }
        &:first-child {
            h2:before {
                background: transparent;
            }
        }
    }
}

.page-node-2344 {
    #content {
        img {
            height: 270px;
            width: 100%;
        }
        p {
            margin-bottom: 0;
        }
    }
    .archives-hopipharm {
        background: $bleu-fonce;
        line-height: 2;
        padding: 15px 10px;
        margin-bottom: 20px;
        a,
        a:visited {
            color: white;
            &:hover {
                color: $rose;
            }
            &:after {
                font-family: "FontAwesome";
                content: "\f0da ";
                font-size: 1.5rem;
                margin-left: 10px;
            }
        }
    }
    .articles-archives {
        display: block;
        font-size: 3rem;
        border: 1px solid $rose;
        padding: 20px 25px;
        margin-left: 15px;
        margin-top: 30px;
        text-align: right;
        font-weight: 100;
        &:after {
            font-family: "FontAwesome";
            content: "\f0da ";
            font-size: 3rem;
            margin-left: 20px;
        }
        &:hover {
            background: $rose;
            color: white;
        }
    }
}

.bandeau {
    margin: 10px 0 15px;
    float: left;
}

.programme {
    clear: left;
    h2 {
        clear: left;
        font-size: 17px;
        background: $vert-programme;
        padding: 0 5px;
        color: $blanc;
        text-transform: uppercase;
        margin: 30px auto;
    }
    h3 {
        font-size: 1.7rem;
        margin-top: 20px;
    }
    h4 {
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    h3 span.heure {
        color: $bleu-fonce;
        font-size: 1.2rem;
        border-right: 1px solid $rose-clair;
        padding-right: 5px;
    }
    .btn {
        &::after {
            font-family: "FontAwesome";
            content: "\f03d ";
            font-size: 1.5rem;
            margin-left: 10px;
        }
    }
    .moderateur {
        color: $bleu-fonce;
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 10px;
        padding-left: 5px;
    }
    a {
        font-weight: normal;
    }
    ul {
        margin-left: 10;
        li {
            background: $blanc url("../images/pictoFC.gif") no-repeat left 5px;
            padding-left: 25px;
            margin: 5px 0;
            &::before {
                content: " ";
            }
            &.sans-picto {
                background: none;
                padding-left: 25px;
                margin: 5px 0;
            }
            .btn {
                &::after {
                    font-family: "FontAwesome";
                    content: "\f03d ";
                    font-size: 1.5rem;
                    margin-left: 10px;
                }
            }
            &.video {
                background: none;
                padding-left: 0;
                &::before {
                    font-family: "FontAwesome";
                    content: "\f03d ";
                    font-size: 1.5rem;
                    margin-right: 10px;
                    color: $gris;
                }
            }
        }
    }
    .orateur ul {
        margin-top: 8px;
    }
}

span.remarqueIntervention {
    border: 1px solid $vert-programme;
    display: block;
    margin-top: 5px;
    padding: 2px;
}

#sb-nav-next,
#sb-nav-previous {
    display: none !important;
}

a.link-video {
    float: right;
    background: $rose-clair;
    padding: 5px;
    color: $blanc;
    border-radius: 3px;
    text-decoration: none;
    font-size: 11px;
}


/* LETTRES SYNDICALES */

.page-node-2181 {
    dl.doc {
        dd.download {
            position: static;
            float: right;
            margin-top: 10px;
        }
    }
}


/*CONTACT */

.page-node-136 {
    .big-icon {
        color: white;
    }
    .bg {
        background-color: $vert;
    }
}

#contact-site-form {
    .form-control {
        width: 100%;
    }
}


/* FOOTER */

footer {
    color: white;
    overflow-x: hidden;
    overflow-y: hidden;
    &.footer-top {
        background: $bleu-fonce;
        padding: 50px 0 13px;
        margin-top: 30px;
    }
    ul {
        margin-left: -45px;
        text-align: center;
    }
    li a:hover {
        color: $rose;
    }
    .title-box {
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:before {
            background: #ffffff;
        }
        .title {
            color: white;
            font-size: 18px;
            font-weight: normal;
            margin: 0 0 12px;
        }
    }
    p {
        color: white;
    }
    address {
        line-height: 26px;
        margin-bottom: 0;
        margin-top: 0;
        color: white;
        .fa {
            color: #ffffff;
        }
    }
    .form-box {
        background: transparent;
        padding: 0;
    }
    .btn {
        background: $vert !important;
        border-radius: 0;
        box-shadow: 0 0 white, inset 0 0 0 rgba(0, 0, 0, .15);
    }
    .form-control {
        border: none;
        box-shadow: 0 0 white, inset 0 0 0 rgba(0, 0, 0, .15);
        border-radius: 0;
        height: 30px;
        margin-bottom: 10px;
        min-height: 30px;
        padding: 8px 12px;
        width: 100%;
    }
    textarea.form-control {
        height: 100px;
    }
    .webform-component-textarea .grippie {
        display: none;
    }
    .contact-form .form-actions {
        padding-top: 0;
    }
    .block-webform .title-box {
        padding-bottom: 0px;
        margin-bottom: 15px;
        .title {
            padding: 2px 0 1px;
        }
    }
}

#footer {
    .clearfix:after {
        display: none;
    }
    .captcha {
        color: #ffffff !important;
        .help-block {
            color: #ffffff;
        }
    }
}

.captcha {
    text-align: left;
    label {
        font-weight: normal;
    }
    .form-control {
        margin-bottom: 15px;
    }
}


/* ADHESION WEBFORM */

#webform-client-form-2317,
#webform-client-form-2350 {
    .webform-component-markup p label {
        background: $bleu-fonce;
        color: white;
        display: block;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-family: Arial;
        font-size: 1.2em;
    }
    .webform-component {
        &.required,
        &.webform-component--civicrm-1-case-1-cg9-custom-32 {
            color: $rouge;
            display: inline;
            &::before {
                content: "(* requis)";
                display: inline-block;
                padding-top: 8px;
                position: absolute;
                right: 30px;
                z-index: 1;
            }
        }
    }
    .webform-component .selectBox.required,
    .webform-component.webform-component-checkboxes.required {
        &::before {
            padding-top: 0;
        }
    }
    .webform-component.webform-component-checkboxes.required {
        &::before {
            margin-top: -5px;
        }
    }
    .selectBox-label {
        display: inline;
    }
    .webform-component--civicrm-1-contact-1-contact-birth-date {
        label {
            display: block;
        }
        .form-control {
            width: 30% !important;
        }
    }
}

.btn-adhesion h4 {
    color: #ffffff;
}

// CIVICRM
.crm-contribution-page-id-2 {
    fieldset legend {
        background: $bleu-fonce;
        color: $blanc;
        margin-bottom: 20px;
        padding: 10px 20px;
        width: 100%;
    }
    label {
        color: $noir;
    }
}

div.crm-container label {
    display: block;
}

.crm-container .select2-container .select2-choice>.select2-chosen {
    line-height: 1.1;
}

// User profile;
.page-user {
    .membership-type-label {
        display: inline-block;
        font-weight: bold;
        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.top-navbar .nav > li a {
    color: #fff;
}