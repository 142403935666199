/******************************************************************************
  Max Width "1300x"
*******************************************************************************/

@media (max-width: 1300px) {
    .boxed {
        padding-top: 0;
        #footer {
            padding-bottom: 0;
        }
    }
}


/******************************************************************************
  Min Width "1200x"
*******************************************************************************/

@media (min-width: 1200px) {
    .carousel {
        .col-md-1 {
            max-width: 100px;
        }
        .col-md-2 {
            max-width: 200px;
        }
        .col-md-3 {
            width: 300px;
            max-width: 300px;
        }
        .col-md-4 {
            max-width: 400px;
        }
        .col-md-5 {
            max-width: 500px;
        }
        .col-md-6 {
            max-width: 600px;
        }
        .col-md-7 {
            max-width: 700px;
        }
        .col-md-8 {
            max-width: 800px;
        }
        .col-md-9 {
            max-width: 900px;
        }
        .col-md-10 {
            max-width: 1000px;
        }
        .col-md-11 {
            max-width: 1100px;
        }
        .col-md-12 {
            max-width: 1200px;
        }
    }
    .boxed.padding-top {
        padding-top: 420px;
    }
    #progressive_cms-breadcrumbs {
        // margin-top: 213px;
        .breadcrumb>li {
            color: white;
        }
    }
}


/******************************************************************************
  Min Width "992px"
*******************************************************************************/

@media (min-width: 992px) {
    .navbar-collapse {
        display: block !important;
        height: auto !important;
        &.collapse {
            padding-bottom: 0;
            overflow: visible !important;
        }
    }
}


/******************************************************************************
  Max Width "979px"
*******************************************************************************/

@media (max-width: 979px) {
    .header .primary .navbar .nav>.parent.active>a,
    .header .primary .navbar .nav>.parent.active:hover>a {
        border-color: $bleu-fonce !important;
        background: $bleu-fonce !important;
    }
    .header .primary .sub li.active li {
        background-color: rgba(177, 55, 112, 0.1);
        a {
            background: none;
            color: #7f7f7f;
        }
        li.level-4 {
            padding-left: 40px;
            background-color: rgba(177, 55, 112, 0.05);
            a:before {
                content: "--";
                padding: 8px 0 0 8px;
                color: #7f7f7f;
            }
        }
    }
}


/******************************************************************************
  Max Width "1270px" - Min Width "1200px"
*******************************************************************************/

@media (min-width: 1200px) and (max-width: 1270px) {
    .banner-set.banner-set-mini.banner-set-no-pagination {
        .pagination {
            display: inline-block !important;
        }
        .next,
        .prev {
            display: inline-block !important;
            margin: 15px 0 0 !important;
            position: static;
        }
    }
}


/******************************************************************************
  Max Width "1199px"
*******************************************************************************/

@media (max-width: 1199px) {
    .phone-active,
    .search-active {
        left: 0;
        width: auto;
    }
    .banner-set {
        .banner {
            padding: 19px 9px;
            width: 235px;
        }
        &.banner-set-mini {
            .banner {
                width: 144px;
            }
            &.banner-set-no-pagination {
                .prev {
                    margin-left: 15px;
                }
                .next {
                    margin-right: 15px;
                }
                .container {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
        }
    }
    .container .banner-set.banner-set-mini.banner-set-no-pagination {
        .prev {
            margin-left: 0;
        }
        .next {
            margin-right: 0;
        }
    }
    .banner-set.banner-set-mini.banner-set-no-pagination .container {
        padding-left: 30px;
        padding-right: 30px;
    }
    .toolbar .per-page {
        display: none;
    }
    .content .slider {
        .slid-content {
            bottom: 43px;
        }
        .pagination {
            padding: 0;
        }
        .slider-nav {
            .nav-box {
                bottom: 10px;
            }
            .next,
            .prev {
                display: none !important;
            }
        }
    }
    #car-bottom .total .checkout {
        font-size: 12px;
    }
    .pricing .title a {
        font-size: 21px;
    }
    .employee .employee-hover .role {
        margin: 0 0 15px;
    }
    .gallery-images:hover .bg-images i:before {
        font-size: 40px;
    }
    .banner-set.banner-set-mini {
        .prev {
            margin-left: 0;
        }
        .next {
            margin-right: 0;
        }
    }
    .sidebar .whats-news {
        .product {
            max-width: 270px;
            min-width: inherit;
        }
        .rotation {
            &.hover .default {
                -webkit-transform: rotateY(0);
                -moz-transform: rotateY(0);
            }
            .product-hover {
                display: none;
            }
        }
    }
    .rs-slider.full-width {
        .tp-bullets {
            left: 27px !important;
            margin-left: 0 !important;
        }
        .right-box>* {
            left: auto !important;
            right: 15px;
        }
    }
}


/******************************************************************************
  Max Width "1199px" - Min Width "992px"
*******************************************************************************/

@media (min-width: 992px) and (max-width: 1199px) {
    .header .primary .megamenu>.sub {
        margin: -1px 0 0 -37px;
        width: 832px;
    }
    .slider {
        .slid-content,
        .slider-nav {
            width: 400px;
        }
    }
    .sidebar .banners .slide {
        width: 220px;
    }
    .col-md-3 {
        >.product,
        &.product {
            max-width: 242px;
        }
    }
    #progressive_cms-breadcrumbs {
        margin-top: 183px;
        .breadcrumb>li {
            color: white;
        }
    }
}


/******************************************************************************
  Max Width "991px"
*******************************************************************************/

@media (max-width: 991px) {
    .fixed-header .page-box {
        margin-top: 0 !important;
        padding-top: 0 !important;
        -webkit-transition: none !important;
        transition: none !important;
    }
    .fixed-top-box {
        padding-top: 0;
        #top-box {
            background: #1e1e1e;
            position: static;
            top: inherit;
            z-index: inherit;
        }
    }
    .fixed-header .header {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        position: static;
        top: inherit;
        z-index: inherit;
        -webkit-backface-visibility: inherit;
        -webkit-transform: inherit;
    }
    .boxed.fixed-header {
        &.fixed .header,
        .header {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            position: static;
            top: inherit;
            z-index: inherit;
            -webkit-backface-visibility: inherit;
            -webkit-transform: inherit;
        }
    }
    .fixed-header {
        &.hidden-top.visible-top .header {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            top: inherit;
            z-index: inherit;
            -webkit-backface-visibility: inherit;
            -webkit-transform: inherit;
        }
        .header-two+* {
            margin-top: 0;
        }
    }
    .hidden-top {
        margin-top: 0;
    }
    .fixed-header {
        margin-top: 0 !important;
        padding-top: 0 !important;
        &.hidden-top {
            -webkit-transition: all 0 linear;
            transition: all 0 linear;
            #top-box {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .boxed.fixed-header.hidden-top #top-box {
        margin-top: 0;
        margin-bottom: 0;
        position: static;
    }
    .fixed-header.hidden-top {
        .header {
            margin-top: 28px;
            position: static;
        }
        &.fixed #top-box,
        &.visible-top .header,
        &.visible-top.fixed #top-box {}
    }
    .header {
        width: auto;
        .right-box-wrapper {
            text-align: right;
        }
        .header-icons {
            margin: 0;
        }
        .cart-header {
            margin-right: 0;
        }
        .header-icons,
        .primary {
            display: inline-block;
            float: none;
            vertical-align: top;
        }
        .primary {
            margin: 0;
            text-align: left;
            z-index: inherit;
            .navbar {
                margin: 0;
                min-height: inherit;
                position: static;
                .btn-navbar {
                    background: $bleu-fonce;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    display: block;
                    float: left;
                    height: 36px;
                    line-height: 15px;
                    margin: 0;
                    padding: 10px 15px;
                    position: relative;
                    text-shadow: none;
                    z-index: 920;
                    &:before {
                        border-left: 1px solid #ccc;
                        border-left: 1px solid rgba(204, 204, 204, 0.8);
                        bottom: 8px;
                        content: "";
                        display: none;
                        left: -1px;
                        position: absolute;
                        top: 7px;
                    }
                    &.collapsed {
                        &:before {
                            display: block;
                        }
                        background: none;
                        border: 1px solid transparent;
                        border-bottom: 0 none;
                    }
                    .text {
                        color: #1e1e1e;
                        float: right;
                        line-height: 16px;
                        padding: 0 0 0 30px;
                    }
                    .icon-bar {
                        background: #1e1e1e;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        border-radius: 0;
                        height: 3px;
                        margin: 0;
                        width: 22px;
                        +.icon-bar {
                            margin-top: 3px;
                        }
                    }
                }
                .navbar-collapse {
                    border: 0 none;
                    box-shadow: none;
                    left: auto;
                    margin: 20px 0 0;
                    max-height: inherit;
                    overflow: hidden !important;
                    padding: 0 15px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 200%;
                    z-index: 919;
                }
                .nav {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    float: none;
                    padding: 6px 6px 3px;
                    > {
                        li {
                            float: none;
                            margin: 0 0 3px;
                            padding: 0;
                            >a {
                                background: #f2f2f2;
                                border: 1px solid #f2f2f2;
                                -webkit-border-radius: 0;
                                -moz-border-radius: 0;
                                border-radius: 0;
                                font-weight: normal;
                                margin: 0;
                                padding: 7px 9px 7px 59px;
                                text-decoration: none;
                                &:visited {
                                    background: #f2f2f2;
                                    border: 1px solid #f2f2f2;
                                    -webkit-border-radius: 0;
                                    -moz-border-radius: 0;
                                    border-radius: 0;
                                    font-weight: normal;
                                    margin: 0;
                                    padding: 7px 9px 7px 59px;
                                    text-decoration: none;
                                }
                                &:hover {
                                    background: #e9e9e9;
                                    border: 1px solid #e9e9e9;
                                    -webkit-border-radius: 0;
                                    -moz-border-radius: 0;
                                    border-radius: 0;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    box-shadow: none;
                                }
                            }
                            &:hover>a {
                                background: #e9e9e9;
                                border: 1px solid #e9e9e9;
                                -webkit-border-radius: 0;
                                -moz-border-radius: 0;
                                border-radius: 0;
                                -webkit-box-shadow: none;
                                -moz-box-shadow: none;
                                box-shadow: none;
                            }
                        }
                        .parent>a,
                        li.parent:hover>a {
                            background: #f2f2f2;
                            position: relative;
                        }
                    }
                    .parent .parent>a:after {
                        display: none;
                    }
                    .open-sub {
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 55px;
                        span {
                            background: #1e1e1e;
                            display: block;
                            height: 2px;
                            margin: 17px 0 0 15px;
                            position: absolute;
                            width: 16px;
                            +span {
                                height: 16px;
                                margin: 10px 0 0 22px;
                                width: 2px;
                            }
                        }
                    }
                    .megamenu .title .open-sub span,
                    .parent .parent>a .open-sub span {
                        background: #7f7f7f;
                        height: 2px;
                        margin: 14px 0 0 38px;
                        position: absolute;
                        width: 12px;
                    }
                    .megamenu .title .open-sub span+span,
                    .parent .parent>a .open-sub span+span {
                        height: 12px;
                        margin: 9px 0 0 43px;
                        width: 2px;
                    }
                    >li.parent:hover>a:after {
                        display: none;
                    }
                }
                .nav>li.parent.active-trail,
                .nav>li.parent:hover>a {
                    color: grey;
                }
            }
            .sub {
                background: #f7f7f7;
                border: 0 none;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                display: none;
                font-size: 13px;
                left: 0;
                line-height: 24px;
                margin-top: 0;
                margin-bottom: 0;
                padding: 6px 0 5px;
                position: relative;
            }
            .parent {
                &:hover>.sub,
                .sub {
                    background: #f7f7f7;
                    border: 0 none;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    display: none;
                    font-size: 13px;
                    left: 0;
                    line-height: 24px;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding: 6px 0 5px;
                    position: relative;
                }
            }
            .navbar .nav {
                > {
                    li.parent:hover>a {
                        background-color: #e9e9e9;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        border-radius: 0;
                    }
                    .parent.active {
                        &:hover>a,
                        >a {
                            background: #1e1e1e;
                            border-color: #1e1e1e;
                            color: #fff;
                            -webkit-transition: all .2s linear;
                            transition: all .2s linear;
                        }
                        >a .open-sub span {
                            background: #fff;
                        }
                    }
                }
                .parent.active>a .open-sub span+span {
                    display: none;
                }
            }
            .megamenu .box.active .title .open-sub span+span {
                display: none;
            }
            .parent:hover>.sub {
                -webkit-animation: fadeIn 0;
                animation: fadeIn 0;
            }
            .sub {
                li {
                    line-height: 22px;
                    &.active a {
                        background: $rose;
                        color: white;
                    }
                }
                a {
                    background: none;
                    color: #7f7f7f;
                    padding: 4px 9px 4px 60px;
                    text-decoration: none;
                    width: auto !important;
                    &:visited {
                        background: none;
                        color: #7f7f7f;
                        padding: 4px 9px 4px 60px;
                        text-decoration: none;
                        width: auto !important;
                    }
                }
            }
            .navbar .nav a>i {
                color: #1e1e1e;
            }
            li:hover>.sub a {
                //                    background: none;
            }
            .sub .sub {
                left: 0;
                margin-left: 10px;
            }
            .megamenu {
                >.sub {
                    padding: 6px 0 5px;
                    width: 100%;
                    .sub-wrapper {
                        display: block;
                    }
                    .promo-block {
                        display: none;
                    }
                    .sub-list {
                        display: block;
                        padding: 0;
                        width: 100% !important;
                    }
                    .box {
                        float: none;
                        margin: 0;
                        padding: 0;
                        width: 100% !important;
                    }
                }
                .title {
                    color: #7f7f7f;
                    cursor: pointer;
                    font-weight: normal;
                    line-height: 22px;
                    margin: 0;
                    padding: 4px 9px 4px 60px;
                    position: relative;
                    text-transform: none;
                }
                >.sub .box.closed ul {
                    display: none;
                    margin-left: 10px;
                }
            }
            .navbar {
                .nav {
                    a .item-new {
                        left: 0;
                        float: right;
                        position: static;
                        top: 0;
                    }
                    .megamenu {
                        .sub a .item-new,
                        a .item-new {
                            left: 0;
                            float: right;
                            position: static;
                            top: 0;
                        }
                    }
                    a .item-new {
                        &:before,
                        .before {
                            display: none;
                        }
                    }
                    >li {
                        &.item-bg>a .open-sub span,
                        &.item-inverse>a .open-sub span {
                            background: #fff;
                        }
                        &.item-bg>a {
                            color: #fff;
                        }
                        &.item-inverse {
                            &:hover>a,
                            >a {
                                color: #fff;
                            }
                        }
                        &.item-primary>a {
                            border-color: #428bca;
                        }
                        &.item-success>a {
                            border-color: #738d00;
                        }
                        &.item-info>a {
                            border-color: #0098ca;
                        }
                        &.item-warning>a {
                            border-color: #f89406;
                        }
                        &.item-danger>a {
                            border-color: #c10841;
                        }
                        &.item-primary {
                            &.item-bg {
                                &:hover>a,
                                >a {
                                    background-color: #428bca;
                                    border-color: #428bca;
                                }
                            }
                            &.item-inverse {
                                &:hover>a,
                                >a {
                                    background-color: #428bca;
                                    border-color: #428bca;
                                }
                            }
                        }
                        &.item-success {
                            &.item-bg {
                                &:hover>a,
                                >a {
                                    background-color: #738d00;
                                    border-color: #738d00;
                                }
                            }
                            &.item-inverse {
                                &:hover>a,
                                >a {
                                    background-color: #738d00;
                                    border-color: #738d00;
                                }
                            }
                        }
                        &.item-info {
                            &.item-bg {
                                &:hover>a,
                                >a {
                                    background-color: #0098ca;
                                    border-color: #0098ca;
                                }
                            }
                            &.item-inverse {
                                &:hover>a,
                                >a {
                                    background-color: #0098ca;
                                    border-color: #0098ca;
                                }
                            }
                        }
                        &.item-warning {
                            &.item-bg {
                                &:hover>a,
                                >a {
                                    background-color: #f89406;
                                    border-color: #f89406;
                                }
                            }
                            &.item-inverse {
                                &:hover>a,
                                >a {
                                    background-color: #f89406;
                                    border-color: #f89406;
                                }
                            }
                        }
                        &.item-danger {
                            &.item-bg {
                                &:hover>a,
                                >a {
                                    background-color: #c10841;
                                    border-color: #c10841;
                                }
                            }
                            &.item-inverse {
                                &:hover>a,
                                >a {
                                    background-color: #c10841;
                                    border-color: #c10841;
                                }
                            }
                        }
                    }
                }
                .navbar-collapse .nav>li.item-bg .sub {
                    background: #f7f7f7;
                    a {
                        color: #7f7f7f;
                    }
                }
            }
        }
        .cart-header {
            .dropdown-menu {
                margin-top: -1px;
                margin-right: -98px;
            }
            &.open .dropdown-toggle:after {
                left: 0;
            }
        }
        &.header-two {
            .phone-header {
                margin-right: 0;
            }
            .phone-active,
            .search-active {
                z-index: 1003;
            }
        }
        &.header-three .logo-box {
            width: 724px;
        }
    }
    .top-fixed-box {
        display: none;
    }
    .slider {
        padding: 10px 0;
        .slid-content,
        .slider-nav {
            width: 370px;
        }
        .slid-content {
            .title {
                margin: 0 0 14px;
            }
            .descriptions {
                margin: 0 0 17px;
            }
        }
        .slider-nav {
            right: 15px;
            .nav-box {
                bottom: 0;
            }
            .next,
            .prev {
                display: none !important;
            }
        }
        .slid-content {
            bottom: 60px;
        }
    }
    .progressive-slider-two {
        .slider-wrapper {
            height: auto !important;
        }
        .sliders-container {
            margin: 0;
            padding-left: 15px;
            padding-right: 15px;
        }
        .slider-banners {
            height: auto;
            width: 100%;
            .banner {
                display: table-cell;
                height: auto;
                width: 33.33333333%;
            }
        }
    }
    .page-slider {
        padding: 0;
    }
    .metro-banners {
        padding: 30px 0 11px;
        .banner {
            margin-bottom: 19px;
        }
    }
    .banner-set .banner {
        width: 180px;
        .title {
            font-size: 18px;
        }
    }
    .sidebar.col-sm-12 {
        margin-top: 40px;
    }
    .toolbar {
        .grid-list {
            .grid,
            .list {
                margin-right: 6px;
            }
        }
        .sort-catalog {
            margin: 0 6px 20px 1px;
        }
    }
    .products.grid {
        margin-left: -10px;
        margin-right: -10px;
        text-align: center;
    }
    .table-box {
        overflow-x: auto;
        padding: 0 1px;
    }
    .portfolio {
        .filter-buttons {
            float: none;
            margin-bottom: 20px;
        }
        .year-regulator {
            float: none;
            margin-bottom: 60px;
        }
    }
    .product-page .product-options {
        .buy-for,
        .col-sm-12 {
            float: none;
            margin-bottom: 20px;
            width: 100%;
        }
    }
    .product-options-samples {
        .col-sm-12 {
            margin-bottom: 20px;
        }
        .required-box {
            margin-bottom: 0;
        }
    }
    .product-bottom .col-sm-12+.col-sm-12 {
        margin-top: 40px;
    }
    .shopping-cart-table {
        .td-qty,
        tbody td {
            padding: 20px;
        }
    }
    #car-bottom .car-bottom-box {
        padding: 17px 20px 20px;
    }
    .employee-single {
        margin-left: 0;
        .images-box {
            float: none;
            margin: 0 auto 20px;
        }
        .employee-description {
            margin-left: 0;
            width: 100%;
        }
    }
    .employee-single-rotation {
        .content-box {
            float: none;
            width: auto;
        }
        .employee {
            margin: 0 20px 10px;
            &.pull-right {
                margin-right: 0;
            }
        }
    }
    .royalSlider.heroSlider {
        min-height: 220px !important;
        .rsOverflow {
            min-height: 220px !important;
        }
    }
    img.rsImg {
        min-height: 220px !important;
    }
    .infoBlock {
        padding: 10px;
        height: auto;
        max-height: 100%;
        min-width: 40%;
        left: 5px;
        top: 5px;
        right: auto;
        font-size: 12px;
        h3,
        h4 {
            font-size: 14px;
            line-height: 17px;
        }
    }
    .layer-slider .container {
        padding-left: 0;
        padding-right: 0;
    }
    .banner-set.banner-set-mini.banner-set-no-pagination {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .next {
            margin-right: -15px;
        }
        .prev {
            margin-left: -15px;
        }
    }
    .container .banner-set.banner-set-mini.banner-set-no-pagination {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        .next {
            margin-right: -30px;
        }
        .prev {
            margin-left: -30px;
        }
    }
    .count-down-box {
        float: none;
        margin-bottom: 30px;
    }
    #footer .newsletter form {
        width: 270px;
    }
    .post.carousel .images-box+[class*="span"] {
        float: none;
        margin-left: 0;
        width: auto;
    }
    .full-width-box .fwb-paralax {
        background-position: 50% 50% !important;
    }
    #footer .sidebar {
        .widget:nth-child(2n+1) {
            clear: left;
        }
        .carousel-box header {
            .title {
                margin-bottom: 0;
            }
            .next,
            .prev {
                bottom: inherit;
                left: 0;
                margin: 0 0 0 10px;
                position: static;
            }
        }
    }
}


/******************************************************************************
  Max Width "800px"
*******************************************************************************/

@media (max-width: 800px) {
    .banner-set.banner-set-mini.banner-set-no-pagination {
        .pagination {
            display: inline-block !important;
        }
        .next,
        .prev {
            display: inline-block !important;
            margin: 15px 0 0 !important;
            position: static;
        }
    }
}


/******************************************************************************
  Max Width "991px" - Min Width "768px"
*******************************************************************************/

@media (min-width: 768px) and (max-width: 991px) {
    .carousel {
        .col-sm-1 {
            max-width: 62px;
        }
        .col-sm-2 {
            max-width: 125px;
        }
        .col-sm-3 {
            max-width: 100%;
        }
        .col-sm-4 {
            max-width: 250px;
        }
        .col-sm-5 {
            max-width: 31px;
        }
        .col-sm-6 {
            max-width: 375px;
        }
        .col-sm-7 {
            max-width: 437px;
        }
        .col-sm-8 {
            max-width: 500px;
        }
        .col-sm-9 {
            max-width: 562px;
        }
        .col-sm-10 {
            max-width: 625px;
        }
        .col-sm-11 {
            max-width: 687px;
        }
        .col-sm-12 {
            max-width: 750px;
        }
    }
    .top-navbar .nav>li a {
        padding-left: 10px;
        padding-right: 10px;
    }
    .primary .navbar-collapse.collapse {
        //display: none !important;
        &.in {
            display: block !important;
        }
    }
    .load.banner-set .container {
        height: 258px;
    }
    .toolbar .price-regulator {
        width: 245px;
        .layout-slider {
            width: 200px;
        }
    }
    .product-page {
        .add-cart-form .add-cart {
            min-width: 200px;
        }
        .image-box {
            .thumblist-box {
                padding: 0 45px;
            }
            #thumblist a {
                height: 70px;
                width: 70px;
            }
        }
    }
    #car-bottom .total .checkout {
        padding: 9px 10px;
    }
    .contact-info address {
        float: none;
        width: 100%;
    }
    .package {
        .title a {
            font-size: 20px;
        }
        .price-box {
            .icon {
                display: none;
            }
            .description {
                margin: 0 0 17px;
            }
        }
    }
    .sidebar .banners {
        .slide {
            width: 165px;
        }
        .banner-text {
            padding: 10px;
            p {
                display: none;
            }
        }
    }
    .products-tab .tab-content {
        overflow: visible;
    }
    .col-sm-5.respond-carousel .carousel .respond {
        max-width: 312px;
    }
    #footer .footer-top .col-md-3 {
        margin-bottom: 40px;
    }
}


/******************************************************************************
  Min Width "768px"
*******************************************************************************/

@media (min-width: 768px) {
    .filter-list.filter-buttons {
        .dropdown-toggle {
            display: none;
        }
        .dropdown-menu {
            background: none;
            border: 0 none;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            display: block !important;
            float: none;
            left: inherit;
            margin: 0;
            min-width: inherit;
            padding: 0;
            position: relative;
            top: inherit;
            z-index: inherit;
            >li {
                float: left;
                margin: 0 20px 10px 0;
                >a {
                    background: transparent;
                    border: 1px solid transparent;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                    clear: none;
                    color: #1e1e1e;
                    height: 38px;
                    line-height: 38px;
                    margin: 0;
                    padding: 0 20px;
                    -webkit-transition: opacity .2s ease-out;
                    transition: opacity .2s ease-out;
                    &:hover {
                        background: transparent;
                        text-decoration: none;
                        opacity: .7;
                        filter: alpha(opacity=70);
                        -webkit-transition: opacity .2s ease-out;
                        transition: opacity .2s ease-out;
                    }
                    &.active {
                        border: 1px solid #e1e1e1;
                        font-weight: bold;
                        opacity: 1;
                        filter: alpha(opacity=100);
                        text-decoration: none;
                    }
                }
            }
        }
        &.white .dropdown-menu>li>a {
            color: #fff;
        }
    }
}


/******************************************************************************
  Max Width "767px"
*******************************************************************************/

@media (max-width: 767px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .carousel {
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4 {
            min-width: 160px;
        }
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9 {
            min-width: 300px;
        }
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-xs-1,
        .col-xs-10,
        .col-xs-11,
        .col-xs-12,
        .col-xs-2,
        .col-xs-3,
        .col-xs-4,
        .col-xs-5,
        .col-xs-6,
        .col-xs-7,
        .col-xs-8,
        .col-xs-9 {
            max-width: 320px;
        }
    }
    .col2-set {
        .col-1,
        .col-2 {
            float: none;
            width: 100%;
        }
        .col-1 {
            margin-bottom: 40px;
        }
    }
    body {
        min-width: 320px;
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
        &.loaded {
            min-width: 320px;
            padding-left: 0;
            padding-right: 0;
            overflow-x: hidden;
        }
    }
    .boxed.loaded .page-box,
    .page-box {
        height: auto !important;
        min-height: inherit;
        overflow-x: hidden;
    }
    .page-box-content {
        padding-bottom: 0;
    }
    #top-box .btn-group.btn-select {
        .visible-xs {
            display: inline-block !important;
        }
        .dropdown-menu {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-border-radius: 0 3px 3px 3px;
            -moz-border-radius: 0 0 3px 3px;
            border-radius: 0 0 3px 3px;
            min-width: inherit;
            width: 100%;
        }
    }
    .top-navbar {
        margin: 0;
        min-height: 30px;
        .btn-navbar {
            background: none !important;
            border-color: rgba(255, 255, 255, 0.2);
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            color: #fff;
            color: rgba(255, 255, 255, 0.7);
            float: left;
            font-size: 16px;
            margin: 0;
            padding: 7px;
            &.collapsed {
                border-color: transparent;
            }
        }
    }
    .navbar-inverse .navbar-toggle .icon-bar {
        background-color: rgba(255, 255, 255, 0.7);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    .top-navbar {
        .collapse,
        .collapsing {
            background: #fff;
            border: 0 none;
            left: 0;
            margin: 5px -10px 0;
            padding: 0;
            position: absolute !important;
            top: 100%;
            z-index: 1002;
        }
        .navbar-nav {
            margin: 0;
        }
    }
    #top-box .top-navbar .nav>li a {
        color: #ffffff;
    }
    .top-navbar {
        .nav>li {
            &:hover a,
            a:hover {
                background: none;
            }
        }
        &.top-navbar-right {
            .btn-navbar {
                float: right;
            }
            .collapse,
            .collapsing {
                left: auto;
                right: 0;
            }
        }
        .nav>li a .count {
            background: rgba(0, 0, 0, 0.1);
        }
    }
    .header-three {
        overflow: hidden;
    }
    .header .right-box {
        padding-left: 0;
    }
    .phone-active,
    .search-active {
        left: 10px;
        padding: 0;
        right: 10px;
    }
    .phone-active {
        font-size: 22px;
        line-height: 58px;
        .title {
            display: none;
        }
    }
    .search-active .search-string {
        padding-left: 8px;
        &:hover {
            padding-left: 8px;
        }
    }
    .header {
        .cart-header .dropdown-toggle,
        .primary .navbar .btn-navbar {
            padding-left: 10px;
            padding-right: 10px;
        }
        .cart-header .dropdown-menu {
            width: 325px;
        }
        &.header-three .logo-box {
            width: 100%;
        }
    }
    .breadcrumb {
        padding-left: 10px;
        padding-right: 10px;
    }
    .progressive-slider .container {
        padding-left: 0;
        padding-right: 0;
    }
    .slider {
        .slid {
            margin-left: 0;
            margin-right: 0;
        }
        .slider-nav {
            right: 0;
            width: 50%;
        }
        .slid .col-sm-12.col-md-12 {
            padding-left: 0;
            padding-right: 0;
        }
        .slid-content {
            bottom: 30px;
            padding: 13px 10px 0;
            right: 0;
            width: 50%;
            .title {
                font-size: 12px;
                line-height: 14px;
            }
            .descriptions {
                display: none;
            }
            .btn {
                font-size: 12px;
                padding-top: 8px;
                padding-bottom: 7px;
            }
        }
        .slider-nav {
            .nav-box {
                bottom: 10px;
                left: 10px;
                right: 10px;
            }
            .pagination {
                margin: 0;
                padding: 0;
            }
        }
    }
    .page-slider {
        margin-left: 0;
        margin-right: 0;
    }
    .progressive-slider-two .slider-banners .banner {
        display: block;
        width: 100%;
    }
    .rs-slider.full-width .tp-bullets {
        left: 7px !important;
        margin-left: 0 !important;
        top: 10px !important;
    }
    .slider.rs-slider.full-width .btn-link {
        font-size: 12px !important;
    }
    .progressive-slider-four {
        .prev {
            font-size: 30px;
            left: 10px;
            margin-top: -15px;
        }
        .next {
            font-size: 30px;
            left: 10px;
            margin-top: -15px;
            left: auto;
            right: 10px;
        }
    }
    .page-header .container:before {
        left: 10px;
    }
    #main {
        padding: 40px 0;
        &.no-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .work-element {
        &.col-md-3 .work {
            margin-left: auto;
            margin-right: auto;
            max-width: 270px;
        }
        &.col-md-4 .work {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px;
        }
    }
    .work-one .work-title {
        margin-bottom: 20px;
    }
    .work-single .carousel-box .carousel {
        .col-md-3 {
            float: left;
            margin-left: 10px;
            margin-right: 10px;
        }
        .col-md-4 {
            float: left;
            margin-left: 10px;
            margin-right: 10px;
            width: 280px;
        }
    }
    .content.pull-right {
        float: none !important;
    }
    .sidebar {
        .widget {
            margin: 0 0 40px;
        }
        .carousel>.col-md-3 {
            float: left;
            margin-left: 10px;
            margin-right: 10px;
        }
        .whats-news .carousel-pagination {
            margin: 0 auto;
            max-width: 300px;
        }
    }
    #catalog .category-img {
        margin-bottom: 40px;
        .description {
            font-size: 18px;
            padding: 20px;
        }
    }
    .toolbar .price-regulator {
        float: left !important;
        margin-top: 3px;
        width: 300px;
        .layout-slider {
            width: 240px;
        }
    }
    .pagination-box {
        margin-top: 0;
        text-align: center;
        .pagination {
            margin: 0 -5px 0 0;
        }
        .pagination-text {
            display: block;
            float: none;
        }
    }
    .products {
        text-align: center;
    }
    .list .product {
        .col-md-3,
        .col-md-4 {
            margin-bottom: 20px;
        }
        .product-image {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px;
        }
    }
    .caroufredsel_wrapper {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .recommended-product .products>.col-md-3 .product {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
    .products-tab .tab-content>.tab-pane {
        height: 263px;
        text-align: center;
    }
    .bottom-padding,
    .bottom-padding-mobile,
    .bottom-padding.row.carousel-box {
        margin-bottom: 40px;
    }
    .bottom-padding-mini {
        margin-bottom: 0;
    }
    .bottom-padding.bottom-padding-mini {
        margin-bottom: 40px;
    }
    .text-center-mobile {
        text-align: center;
    }
    .accordion-tab,
    .tabs-left .accordion-tab,
    .tabs-right .accordion-tab {
        border: 0 none;
        float: none;
        width: 100%;
    }
    .accordion-tab>li,
    .tabs-left>.accordion-tab>li,
    .tabs-right>.accordion-tab>li {
        float: none;
        margin: 0 0 3px;
    }
    .accordion-tab>li>a,
    .products-tab .accordion-tab>li>a,
    .tabs-left>.accordion-tab>li>a,
    .tabs-right>.accordion-tab>li>a {
        background-position: 14px 50%;
        background-repeat: no-repeat;
        background-color: #f2f2f2;
        border: 0 none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: #1e1e1e;
        display: block;
        font-size: 14px;
        line-height: 22px;
        margin: 0;
        padding: 9px 9px 9px 60px;
        position: relative;
        text-decoration: none;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
    }
    .accordion-tab>li>a:hover,
    .products-tab .accordion-tab>li>a:hover {
        background-color: #e9e9e9;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
    }
    .accordion-tab>li.active {
        >a {
            background-color: #1e1e1e;
            border: 0 none;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            color: #fff !important;
            &:focus,
            &:hover {
                background-color: #1e1e1e;
                border: 0 none;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                color: #fff !important;
            }
        }
        &:hover>a {
            background-color: #1e1e1e;
            border: 0 none;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            color: #fff !important;
        }
    }
    .products-tab .accordion-tab>li.active {
        &:hover>a,
        >a {
            background-color: #1e1e1e;
            border: 0 none;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            color: #fff !important;
        }
    }
    .accordion-tab>li>a:before,
    .products-tab .accordion-tab>li>a:before {
        background: #e1e1e1;
        background: rgba(255, 255, 255, 0.4);
        bottom: 0;
        content: "";
        display: block;
        left: 45px;
        position: absolute;
        top: 0;
        width: 1px;
    }
    .accordion-tab>li.active>a:before,
    .products-tab .accordion-tab>li.active>a:before {
        background: #444;
        background: rgba(255, 255, 255, 0.2);
    }
    .accordion-tab>li>a .open-sub,
    .products-tab .accordion-tab>li>a .open-sub {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 45px;
    }
    .accordion-tab>li>a .open-sub {
        &:after,
        &:before {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
        }
    }
    .products-tab .accordion-tab>li>a .open-sub {
        &:after,
        &:before {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
        }
    }
    .accordion-tab>li>a .open-sub:after,
    .products-tab .accordion-tab>li>a .open-sub:after {
        height: 16px;
        margin: 12px 0 0 22px;
        width: 2px;
    }
    .accordion-tab>.active>a .open-sub {
        &:after,
        &:before {
            background: #fff;
        }
    }
    .products-tab .accordion-tab>.active>a .open-sub {
        &:after,
        &:before {
            background: #fff;
        }
    }
    .accordion-tab>.active>a .open-sub:after,
    .products-tab .accordion-tab>.active>a .open-sub:after {
        display: none;
    }
    .accordion-tab .tab-pane {
        border: 0 none;
        display: none;
        font-size: 13px;
        padding: 20px 0 30px;
    }
    .products-tab .accordion-tab .tab-pane {
        background: none;
    }
    .promo-partners .manufactures {
        text-align: center;
    }
    .page-header {
        margin-bottom: 5px;
    }
    .image-box {
        margin: 0 auto 40px;
        max-width: 470px;
    }
    .product {
        width: 300px;
    }
    .double-product {
        width: 300px;
        .product {
            max-width: 100%;
        }
    }
    .product.product-mini {
        width: 250px;
    }
    .product-page {
        .image-box {
            .thumblist-box {
                max-height: 80px;
                padding: 0 45px;
            }
            #thumblist a {
                height: 70px;
                width: 70px;
            }
        }
        .col-md-7 .price-box {
            font-size: 40px;
        }
        .add-cart-form {
            margin-bottom: 0;
            .add-cart,
            .number {
                margin-bottom: 40px;
            }
        }
        .product-tab {
            margin: 40px 0;
            .tab-pane {
                padding: 20px 0;
            }
        }
        .product-options-table table {
            th {
                padding-left: 15px;
                padding-right: 15px;
            }
            td {
                padding-left: 15px;
                padding-right: 15px;
                &.qty {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    .product-options-detail .add-cart {
        display: block;
        float: none;
        margin: 20px 0 0;
        min-width: inherit;
    }
    #car-bottom {
        margin-bottom: 0;
    }
    #checkoutsteps {
        margin-bottom: 40px;
    }
    .login .new-costumers {
        padding: 0 0 40px;
    }
    .map-box .contact-info {
        margin-bottom: 20px;
        padding: 0;
        position: relative;
        top: 0;
    }
    .box-404 {
        -webkit-border-radius: 150px;
        -moz-border-radius: 150px;
        border-radius: 150px;
        height: 300px;
        margin: 0 auto 40px;
        padding: 75px 0 0;
        width: 300px;
        h1 {
            font-size: 120px;
        }
    }
    .team-box {
        text-align: center;
    }
    .list-images {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 20px;
        text-align: center;
        >.col-md-4 {
            display: inline-block;
            margin: 0 0 20px;
            text-align: left;
            width: 170px;
        }
    }
    .gallery .images-box {
        text-align: center;
    }
    .gallery-images {
        display: inline-block;
        .bg-images {
            text-align: left;
        }
    }
    .year-regulator .layout-slider {
        width: 240px;
    }
    .work-one .description {
        margin-bottom: 15px;
    }
    .work-box+.work-box {
        margin-top: 40px;
    }
    .employee {
        max-width: 300px;
    }
    .employee-single {
        width: 100%;
        .images-box {
            display: block;
            max-width: 430px;
            &.pull-right {
                float: right;
                margin: 0 0 10px 20px;
            }
        }
    }
    .respond-carousel .carousel [class*="span"] {
        margin-left: 2px;
        margin-right: 2px;
    }
    .steps {
        display: block;
        .step {
            border: 1px solid #e1e1e1;
            display: block;
            +.step {
                border: 1px solid #e1e1e1;
                border-top: 0 none;
            }
            &:first-child {
                -webkit-border-radius: 3px 3px 0 0;
                -moz-border-radius: 3px 3px 0 0;
                border-radius: 3px 3px 0 0;
            }
            &:last-child {
                -webkit-border-radius: 0 0 3px 3px;
                -moz-border-radius: 0 0 3px 3px;
                border-radius: 0 0 3px 3px;
            }
            .step-wrapper {
                &:after {
                    border: 7px solid transparent;
                    border-top-color: #e1e1e1;
                    bottom: -30px;
                    left: 50%;
                    margin: 0 0 0 -7px;
                    right: auto;
                    top: auto;
                }
                &:before {
                    border: 7px solid transparent;
                    border-top-color: #e1e1e1;
                    bottom: -30px;
                    left: 50%;
                    margin: 0 0 0 -7px;
                    right: auto;
                    top: auto;
                    border-top-color: #fff;
                    bottom: -28px;
                }
            }
            &.bg-grey .step-wrapper:before,
            &.border-grey .step-wrapper:after {
                border-left-color: transparent;
                border-top-color: #777;
            }
            &.border-warning .step-wrapper:after {
                border-top-color: #f89406;
                border-left-color: transparent;
            }
            &.border-error .step-wrapper:after {
                border-top-color: #c10841;
                border-left-color: transparent;
            }
            &.border-info .step-wrapper:after {
                border-top-color: #0098ca;
                border-left-color: transparent;
            }
            &.border-success .step-wrapper:after {
                border-top-color: #738d00;
                border-left-color: transparent;
            }
            &.bg-warning .step-wrapper:before {
                border-top-color: #f89406;
                border-left-color: transparent;
            }
            &.bg-error .step-wrapper:before {
                border-top-color: #c10841;
                border-left-color: transparent;
            }
            &.bg-info .step-wrapper:before {
                border-top-color: #0098ca;
                border-left-color: transparent;
            }
            &.bg-success .step-wrapper:before {
                border-top-color: #738d00;
                border-left-color: transparent;
            }
        }
        &.steps-apart .step {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            margin-bottom: 22px;
        }
        &.progress-steps .step {
            border: 1px solid #e1e1e1;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            margin-bottom: 22px;
            .step-wrapper {
                padding-left: 100px;
            }
            &.step-right .step-wrapper {
                padding-right: 100px;
            }
            .number {
                font-size: 40px;
                line-height: 2;
                width: 80px;
            }
        }
    }
    #count-down {
        .county-days-wrapper,
        .county-hours-wrapper,
        .county-minutes-wrapper,
        .county-seconds-wrapper {
            height: 80px;
        }
        .county-days,
        .county-hours,
        .county-minutes,
        .county-seconds {
            line-height: 80px;
        }
    }
    .banner-set .banner {
        width: 290px;
    }
    .full-width-box {
        padding-top: 40px;
        padding-bottom: 40px;
        .container {
            margin-left: 0;
            margin-right: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .page-404-promo #main {
        .title h1 {
            font-size: 120px;
        }
        .content .back-home {
            padding: 10px 20px 10px 10px;
        }
    }
    .timeline {
        &:before {
            left: 20px;
        }
        .post {
            margin: 0;
            padding: 0 0 30px 60px;
            width: 100%;
            &:nth-child(even) {
                margin: 0;
                padding: 0 0 30px 60px;
                width: 100%;
            }
            &:nth-child(even) .timeline-time,
            .timeline-time {
                left: 0;
                margin: 0 0 5px;
                position: relative;
                right: 0;
                text-align: left;
            }
            .timeline-icon {
                left: 0;
                margin: 22px 0 0;
            }
            &:nth-child(even) .timeline-content:before,
            .timeline-content:before {
                border: 15px solid transparent;
                border-right-color: #ccc;
                left: -30px;
                right: auto;
            }
            &:nth-child(even) .timeline-content:after,
            .timeline-content:after {
                border: 14px solid transparent;
                border-right-color: #fff;
                left: -28px;
                right: auto;
            }
            .timeline-content {
                &.bg:before,
                &.border:before {
                    border: 15px solid transparent;
                    border-right-color: #ccc;
                }
                &.bg-danger:before {
                    border-right-color: #c10841;
                }
            }
            &:nth-child(even) .timeline-content.bg-danger:before,
            &:nth-child(even) .timeline-content.border-danger:before,
            .timeline-content.border-danger:before {
                border-right-color: #c10841;
            }
            &:nth-child(even) .timeline-content.bg-warning:before,
            &:nth-child(even) .timeline-content.border-warning:before,
            .timeline-content.bg-warning:before,
            .timeline-content.border-warning:before {
                border-right-color: #f89406;
            }
            &:nth-child(even) .timeline-content.bg-info:before,
            &:nth-child(even) .timeline-content.border-info:before,
            .timeline-content.bg-info:before,
            .timeline-content.border-info:before {
                border-right-color: #0098ca;
            }
            &:nth-child(even) .timeline-content.bg-success:before,
            &:nth-child(even) .timeline-content.border-success:before,
            .timeline-content.bg-success:before,
            .timeline-content.border-success:before {
                border-right-color: #738d00;
            }
            &:nth-child(even) .timeline-content.bg-primary:before,
            &:nth-child(even) .timeline-content.border-primary:before,
            .timeline-content.bg-primary:before,
            .timeline-content.border-primary:before {
                border-right-color: #428bca;
            }
            .timeline-content .entry-content img {
                width: 100%;
            }
        }
    }
    footer.footer-top {
        padding-bottom: 0;
        padding-top: 20px;
        margin-top: 0;
    }
    #footer {
        margin-top: 0;
        .sidebar {
            margin-top: 0;
        }
        .footer-top {
            padding-bottom: 0;
            .col-md-3 {
                margin-bottom: 36px;
                overflow: hidden;
            }
        }
        .footer-bottom {
            .col-md-3 {
                overflow: hidden;
            }
            .copyright {
                padding: 17px 0;
                text-align: center;
            }
        }
        .address,
        .phone {
            float: left;
            margin-bottom: 29px;
            width: 48%;
        }
        .phone .title {
            display: block;
        }
        .address {
            float: right;
        }
        .up {
            float: none;
            margin: 0 auto;
        }
    }
    #count-down {
        text-align: center;
        .county-days,
        .county-hours,
        .county-minutes,
        .county-seconds {
            font-size: 30px;
        }
    }
}


/******************************************************************************
  Max Width "600px"
*******************************************************************************/

@media (max-width: 600px) {
    .post.carousel .images-box {
        display: block;
        float: none !important;
        margin: 0 auto 20px;
        &.pull-right {
            display: block;
            float: none !important;
            margin: 0 auto 20px;
        }
    }
    .employee-single .images-box {
        display: block;
        float: none !important;
        margin: 0 auto 20px;
    }
    .employee-single-rotation .employee {
        display: block;
        float: none !important;
        margin: 0 auto 20px;
        &.pull-right {
            display: block;
            float: none !important;
            margin: 0 auto 20px;
        }
    }
    .hidden-600 {
        display: none !important;
    }
    .header {
        .cart-header .dropdown-toggle {
            .count {
                display: none;
            }
            padding-left: 5px;
            padding-right: 5px;
        }
        .primary .navbar .btn-navbar {
            padding-left: 10px;
            padding-right: 5px;
        }
        &.header-two .primary .navbar .btn-navbar.collapsed:before {
            display: none;
        }
    }
}


/******************************************************************************
  Max Width "480px"
*******************************************************************************/

@media (max-width: 480px) {
    .slider .slid-content .title {
        max-height: 42px;
        overflow: hidden;
    }
}


/******************************************************************************
  Touch device
*******************************************************************************/

.touch-device {
    .carousel-box {
        .next:hover,
        .prev:hover {
            background-color: #ccc;
            color: #fbfbfb;
        }
    }
    #footer .up:hover {
        background: #c6c6c6;
    }
    .work:hover {
        text-shadow: 0 1px #000;
        .work-title {
            bottom: 37px;
            color: #fff;
        }
        .bg-hover {
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }
    &.fixed-header {
        .page-box {
            margin-top: 0 !important;
            padding-top: 0 !important;
            -webkit-transition: none !important;
            transition: none !important;
        }
        .header {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            position: static;
            top: inherit;
            -webkit-backface-visibility: inherit;
            -webkit-transform: inherit;
        }
    }
    &.boxed.fixed-header {
        &.fixed .header,
        .header {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            position: static;
            top: inherit;
            -webkit-backface-visibility: inherit;
            -webkit-transform: inherit;
        }
    }
    &.fixed-header.hidden-top {
        &.visible-top .header {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            top: inherit;
            -webkit-backface-visibility: inherit;
            -webkit-transform: inherit;
        }
        -webkit-transition: all 0 linear;
        transition: all 0 linear;
        #top-box {
            margin-top: 0;
            margin-bottom: 0;
            position: static;
        }
    }
    &.boxed.fixed-header.hidden-top #top-box {
        margin-top: 0;
        margin-bottom: 0;
        position: static;
    }
    &.boxed.fixed-header.hidden-top .header,
    &.fixed-header.hidden-top .header {
        margin-top: 0;
        position: inherit;
        top: 0;
    }
    .carousel-box {
        .next:hover,
        .prev:hover {
            background-color: #ccc;
            color: #fbfbfb;
        }
        .next:hover polygon,
        .prev:hover polygon {
            fill: #fbfbfb;
        }
    }
    .slider .slider-nav {
        .next,
        .prev {
            opacity: 1;
            filter: alpha(opacity=100);
            visibility: visible;
        }
        .next:hover,
        .prev:hover {
            background-color: #fff;
            background-color: rgba(255, 255, 255, 0.1);
            opacity: 1;
            filter: alpha(opacity=100);
            visibility: visible;
        }
        .next:hover polygon,
        .prev:hover polygon {
            fill: #838383;
        }
    }
    .switches a {
        &:hover {
            background: #fff;
            background: rgba(255, 255, 255, 0.1);
        }
        &.selected {
            background: #fff;
            background: rgba(255, 255, 255, 0.5);
        }
    }
    .banner-set .pagination a:hover,
    .carousel-box .pagination a:hover {
        background: #ececec;
    }
    .carousel-box .pagination a.selected {
        background: #ccc;
        background: rgba(0, 0, 0, 0.3);
    }
    .banner-set {
        .pagination a.selected {
            background: #ccc;
            background: rgba(0, 0, 0, 0.3);
        }
        .next:hover,
        .prev:hover {
            color: #ccc;
        }
    }
    .panel-group {
        .panel .panel-heading>.panel-title>a:hover,
        .panel-default .panel-heading>.panel-title>a:hover {
            background-color: #f2f2f2;
        }
        .panel-primary .panel-heading>.panel-title>a:hover {
            background-color: #037ac5;
        }
        .panel-success .panel-heading>.panel-title>a:hover {
            background-color: #738d00;
        }
        .panel-info .panel-heading>.panel-title>a:hover {
            background-color: #0098ca;
        }
        .panel-warning .panel-heading>.panel-title>a:hover {
            background-color: #f89406;
        }
        .panel-danger .panel-heading>.panel-title>a:hover {
            background-color: #c10841;
        }
    }
    .filter-buttons a:hover {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .gallery-images:hover>.bg-images {
        background: none;
    }
    .post.carousel .carousel-box {
        .next,
        .prev {
            display: none !important;
        }
    }
}


/******************************************************************************
  Not hover
*******************************************************************************/

.fancybox-margin .touch-device.fixed-header {
    #top-box,
    .header {
        left: 0;
    }
}

.touch-device .fancybox-wrap {
    position: fixed !important;
    -webkit-overflow-scrolling: touch !important;
    top: 20px !important;
}
